<div class="modal-header">
  <h5 *ngIf="inEditMode" class="modal-title"> Edit Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <h5 *ngIf="!inEditMode" class="modal-title"> Create Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <ng-container *ngIf="!['User', 'TpoUser', 'Branch', 'ExternalCompanyBranch'].includes(scope)" [ngTemplateOutlet]="systemLevelConfig"></ng-container>
  <ng-container *ngIf="['User', 'TpoUser', 'Branch', 'ExternalCompanyBranch'].includes(scope)" [ngTemplateOutlet]="otherConfig"></ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save </span>
  </button>
</div>

<ng-template #systemLevelConfig>
  <div class="d-flex align-items-center gap-2">
    <div class="form-check form-check-inline" style="vertical-align: sub; margin-left: 1.6rem;">
      <input id="channelModeEnabled" name="channelModeEnabled" class="form-check-input" type="checkbox"
        [(ngModel)]="isChannelModeEnabled">
      <label class="form-check-label" for="channelModeEnabled"> Channel Mode Enabled </label>
    </div>

    <div *ngIf="isChannelModeEnabled" class="alert custom-alert custom-alert-warning icon-custom-alert shadow-sm mb-0" role="alert">
      <div class="media">
        <i class="la la-exclamation-triangle alert-icon text-warning font-30 me-3 align-self-center"></i>
        <div class="media-body my-auto">
          <span>This change will break any existing OB configurations</span>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isChannelModeEnabled">
    <ng-container [ngTemplateOutlet]="newModeConfig"></ng-container>
    <div class="row mt-3" *ngIf="vendor?.credentialId">
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <button type="button" class="btn btn-primary me-2" (click)="onRepriceFieldsClicked()">
          Field Editor
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isChannelModeEnabled">
    <div class="row">
      <!-- <div class="col-md-2 text-end mt-3">
        <label class="custom-control-label"> Business Channels </label>
      </div>
      <div class="col-md-10 mt-3">
        <tag-input theme='bootstrap' [(ngModel)]="channels" [placeholder]="'+ Add Channel'" [onlyFromAutocomplete]="true">
          <tag-input-dropdown [autocompleteItems]="businessChannelsNames"
            [showDropdownIfEmpty]="true"></tag-input-dropdown>
        </tag-input>
      </div> -->
      <div class="col-md-2 text-end mt-3">
        <label class="custom-control-label"> Business Channels </label>
      </div>
      <div class="col-md-10 mt-3">
        <ng-multiselect-dropdown name="channels" id="channels" required [placeholder]="'-- Select --'"
          [settings]="multiSelectSettings" [data]="channelOptions" [(ngModel)]="selectedChannels">
        </ng-multiselect-dropdown>
      </div>
    </div>
    <div class="row mt-3" *ngIf="vendor?.credentialId">
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <button type="button" class="btn btn-primary me-2" (click)="onRepriceFieldsClicked()">
          Field Editor
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #otherConfig>
  <form #editorForm="ngForm" *ngIf="!isChannelModeEnabled">
    <div class="row">
      <div class="col-md-4 col-xs-12 text-start">
        <button class="btn btn-outline-primary" (click)="addBusinessChannel()">
          Add Business Channels
        </button>
      </div>
    </div>
    <div class="row mt-2" *ngFor="let item of kvpOriginators; let i = index;  trackBy: trackByIndex">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Business Channels</label>
      </div>
      <div class="col-md-4">
        <select class="form-select" name="businessChannelName_{{i}}" [(ngModel)]="item.key" #bcNameField="ngModel"
          [ngClass]="{'is-invalid': bcNameField && bcNameField.touched && bcNameField.invalid}"
          (change)="clearOriginator(item)" required>
          <option value="">-- Select One --</option>
          <option *ngFor="let ele of businessChannels" value={{ele.businessChannelId}}>
            {{ele.name}}
          </option>
        </select>
      </div>
      <div class="col-md-1 text-end">
        <label class="custom-control-label">Originators</label>
      </div>
      <div class="col-md-4">
        <select class="form-select" name="originatorName_{{i}}" [(ngModel)]="item.value" #originatorNameField="ngModel"
          [ngClass]="{'is-invalid': originatorNameField && originatorNameField.touched && originatorNameField.invalid}"
          required>
          <option value="">-- Select One --</option>
          <option *ngFor="let ele of businessChannelOriginators[item.key]" value={{ele.originatorId}}>
            {{ele.name}}
          </option>
        </select>
      </div>
      <div class="col-md-1">
        <confirm-delete [list]="kvpOriginators" [index]="i"></confirm-delete>
      </div>
    </div>
  </form>

  <ng-container *ngIf="isChannelModeEnabled" [ngTemplateOutlet]="newModeConfig"></ng-container>
</ng-template>

<ng-template #newModeConfig>
  <form #editorForm="ngForm">
    <div class="row mt-3" *ngIf="scope != 'User'">
      <div class="col-md-4 col-xs-12 text-start">
        <button class="btn btn-outline-primary" (click)="addNewModeChannel()">
          Add Channel
        </button>
      </div>
    </div>
    <ng-container *ngIf="scope != 'User'">
      <div class="row mt-2" *ngFor="let item of kvpChannels; let i = index; trackBy: trackByIndex">
        <div class="col-md-2 text-end">
          <label class="custom-control-label">Loda Channel</label>
        </div>
        <div class="col-md-3">
          <select class="form-select" name="lodaChannelName_{{i}}" [(ngModel)]="item.key" #lodaChannelNameField="ngModel"
            [ngClass]="{'is-invalid': lodaChannelNameField && lodaChannelNameField.touched && lodaChannelNameField.invalid}"
            required>
            <option value="">-- Select One --</option>
            <option *ngFor="let opt of lodaChannels" [ngValue]="opt.name">
              {{opt.name}}
            </option>
          </select>
        </div>

        <div class="col-md-2 text-end">
          <label class="custom-control-label">OB Business Channel</label>
        </div>
        <div class="col-md-3">
          <select class="form-select" name="newModeBusinessChannelName_{{i}}" [(ngModel)]="item.value" #newModeBcNameField="ngModel"
            [ngClass]="{'is-invalid': newModeBcNameField && newModeBcNameField.touched && newModeBcNameField.invalid}"
            required>
            <option value="">-- Select One --</option>
            <option *ngFor="let ele of businessChannels" [ngValue]="ele.name">
              {{ele.name}}
            </option>
          </select>
        </div>

        <div class="col-md-2">
          <confirm-delete [list]="kvpChannels" [index]="i"></confirm-delete>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="kvpChannels.length">
      <div class="row mt-3">
        <div class="col-md-4 col-xs-12 text-start">
          <button class="btn btn-outline-primary" (click)="addNewModeOriginator()">
            Add Originator
          </button>
        </div>
      </div>
      <div class="row mt-2" *ngFor="let item of kvpNewModeOriginators; let i = index; trackBy: trackByIndex">
        <div class="col-md-2 text-end">
          <label class="custom-control-label">OB Channel</label>
        </div>
        <div class="col-md-3">
          <select class="form-select" name="kvpNewModeOriginatorChannelName_{{i}}" [(ngModel)]="item.key" #kvpNewModeOriginatorChannelNameField="ngModel"
            [ngClass]="{'is-invalid': kvpNewModeOriginatorChannelNameField && kvpNewModeOriginatorChannelNameField.touched && kvpNewModeOriginatorChannelNameField.invalid}"
            required>
            <option value="">-- Select One --</option>
            <option *ngFor="let kvp of kvpChannels" [ngValue]="kvp.value">
              {{kvp.key}} -> {{kvp.value}}
            </option>
          </select>
        </div>

        <div class="col-md-2 text-end">
          <label class="custom-control-label">User</label>
        </div>
        <div class="col-md-3">
          <select class="form-select" name="userName_{{i}}" [(ngModel)]="item.value" #userNameField="ngModel"
            [ngClass]="{'is-invalid': userNameField && userNameField.touched && userNameField.invalid}"
             required>
            <option value="">-- Select One --</option>
            <option *ngFor="let opt of users" [ngValue]="opt.value">
              {{opt.name}}
            </option>
          </select>
        </div>

        <div class="col-md-2">
          <confirm-delete [list]="kvpNewModeOriginators" [index]="i"></confirm-delete>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>
