<div class="popover-menu" style="max-height: 80vh !important; overflow-y: auto !important;">
  <a class="dropdown-item" [ngClass]="{'control-btn': !isNiceInContactVisible}" (click)="onKeyDatesClicked()"> Key Dates </a>
  <a class="dropdown-item" [ngClass]="{'control-btn': !isNiceInContactVisible}" (click)="onGenerateDocClicked()"> Generate Custom Document </a>
  <a class='dropdown-item' [ngClass]="{'control-btn': !isNiceInContactVisible}" (click)="$event.stopPropagation(); systemGenerateDocumentMenuActive = !systemGenerateDocumentMenuActive;"> Generate System Document <i class="ms-1 fas fa-caret-{{systemGenerateDocumentMenuActive ? 'down' : 'right'}}"></i></a>
  <div style="padding-left: 10px !important;" *ngIf="systemGenerateDocumentMenuActive">
    <a class='dropdown-item' (click)="systemGenerateDocumentMenuActive = false; onCreditInquiryClicked()">Credit Inquiry LOE</a>
    <a class='dropdown-item' (click)='systemGenerateDocumentMenuActive = false; onGenerateDocClicked(true)'>Prequalification Letter</a>
    <a class='dropdown-item' (click)="systemGenerateDocumentMenuActive = false; exportUrlaDocs()">URLA</a>
  </div>
  <a class="dropdown-item" [ngClass]="{'control-btn': !isNiceInContactVisible}" (click)="onLoanActivityToggled()"> Loan Activity </a>

  <ng-container *ngIf="isNiceInContactVisible">
    <div class="divider"></div>
    <a class="dropdown-item" (click)="$event.stopPropagation(); addNewTaskMenuActive = !addNewTaskMenuActive;"> Add New Task
      <i class="ms-1 fas fa-caret-{{addNewTaskMenuActive ? 'down' : 'right'}}"></i></a>
    <div style="padding-left: 10px !important;" *ngIf="addNewTaskMenuActive">
      <a class="dropdown-item" (click)="$event.stopPropagation();internalMenuActive = !internalMenuActive"> Internal
        <i class="ms-2 fas fa-caret-{{ internalMenuActive ? 'down' : 'right'}}"></i>
      </a>
      <div style="padding-left: 10px !important" *ngIf="internalMenuActive">
        <a class="dropdown-item" (click)="addNewTask('RequestDocument', false, null, null)"> Task </a>
        <a class="dropdown-item" (click)="addNewTask('RequestDocument', true, null, null)"> Condition </a>
      </div>
      <a class="dropdown-item" (click)="$event.stopPropagation();borrowerMenuActive = !borrowerMenuActive"> Borrower
        <i class="ms-2 fas fa-caret-{{ borrowerMenuActive ? 'down' : 'right'}}"></i>
      </a>
      <div style="padding-left: 10px !important" *ngIf="borrowerMenuActive">
        <a class="dropdown-item" (click)="addNewTask('RequestDocument', false, true, null)"> Task </a>
        <a class="dropdown-item" (click)="addNewTask('RequestDocument', true, true, null)"> Condition </a>
        <a class="dropdown-item" (click)="addNewTask('RequestInformation', false, true, null)"> Ask Question </a>
      </div>
      <a class="dropdown-item" (click)="$event.stopPropagation();quickClientUpdateMenuActive = !quickClientUpdateMenuActive"> Quick Client Update
        <i class="ms-2 fas fa-caret-{{ quickClientUpdateMenuActive ? 'down' : 'right'}}"></i>
      </a>
      <div style="padding-left: 10px !important" *ngIf="quickClientUpdateMenuActive">
        <a class="dropdown-item" (click)="quickClientUpdateClicked('QuickClientUpdateReferralLead')"> Referral Lead </a>
        <a class="dropdown-item" (click)="quickClientUpdateClicked('QuickClientUpdatePreApproved')"> Pre Approved </a>
        <a class="dropdown-item" (click)="quickClientUpdateClicked('QuickClientUpdateInProcess')"> In Process </a>
      </div>
      <a *ngIf="showESignTaskOption" class="dropdown-item" (click)="addNewTask('EsignDocument', null, null, null)"> eSignature
        Task </a>
      <a *ngIf="onlineAppEnabled && !onlineAppTaskExists" class="dropdown-item" (click)="addOnlineAppTask()"> Borrower
        Online App </a>
      <a *ngIf="loanBeamEnabled && !loanBeamDocSyncTaskExists" class="dropdown-item"
        (click)="addLoanBeamDocSyncTask()"> LoanBeam Doc Sync </a>
    </div>
  </ng-container>

  <div class="divider" [ngClass]="{'control-btn': !isNiceInContactVisible}"></div>

  <a class="dropdown-item" (click)="onExportMismoClicked()" *ngIf="!isUrlaFormOpened"> Export MISMO 3.4 </a>
  <a class="dropdown-item" (click)="onExportDUClicked()" *ngIf="!isUrlaFormOpened"> Export DU 3.2 </a>
  <a class="dropdown-item" (click)="onImportMismoClicked()" *ngIf="!isUrlaFormOpened"> Import MISMO 3.4 </a>
  <a class="dropdown-item" (click)="onDisclosureTrackingClicked()" *ngIf="permissions?.docMagicEnabled"> Disclosure Tracking </a>
  <a class="dropdown-item" (click)="onIdentifyingDocumentsClicked()"> Identifying Documents </a>
  <div class="divider" *ngIf="!isUrlaFormOpened"></div>
  <!-- <a class="dropdown-item" (click)="onProcessingWorksheetClicked()">Processing Worksheet </a> -->
  <!-- <a class="dropdown-item" (click)="onGenerateDocClicked()"> Generate Document</a> -->
  <a class="dropdown-item" (click)="onViewEsignatureHistoryClicked()"> View eSign History </a>
  <div class="divider"></div>
  <a class="dropdown-item" (click)="onBorrowerMessagingClicked()"> Borrower Messaging </a>
  <a class="dropdown-item" (click)="onAttemptedContactClicked()"> Log Attempted/Successful Contact </a>
  <a class="dropdown-item" (click)="onSetAppointmentClicked()"> Set Appointment </a>

  <a class="dropdown-item" (click)="onSendEmailClicked()"> Send Email </a>
  <!--
  <a class="dropdown-item" (click)="onSendEmailSmsClicked()"> Send Email {{applicationContext.userPermissions.smsAlertsEnabled ? '/ SMS' : ''}}</a>
  -->
  <a class="dropdown-item" (click)="onEmailSmsHistoryClicked()"> Email {{applicationContext.userPermissions.smsAlertsEnabled ? '/ SMS ' : ''}}History</a>
  <a class="dropdown-item" (click)="onViewTitleHistoryClicked()"> Title History</a>
  <a class="dropdown-item" (click)="onDenialClicked()"> Denial / Termination / Adverse Action</a>
  <a class="dropdown-item" (click)="onMarkManualTransferClicked()"> Mark Manual Transfer </a>
  <div class="divider"></div>
  <a class="dropdown-item" *ngIf="canBeOpenedInTpoPortal"
    (click)="onOpenLoanInTpoPortalClicked()">
    Open in TPO Portal
  </a>
  <a class="dropdown-item" *ngIf="permissions.cobrowseEnabled"
    [routerLink]="['/' + applicationMode + '/app-details/' + application.applicationId + '/co-browse']">
    Start Cobrowse
  </a>
  <a class="dropdown-item" (click)="onCopyApplicationClicked()"> Copy Application </a>
  <a class="dropdown-item" (click)="onLinkToLeadClicked()"> Link to Lead </a>
  <div *ngIf="permissions.allowMortgageCallReports" class="divider"></div>
  <a *ngIf="permissions.allowMortgageCallReports" class="dropdown-item" (click)="onMcrAuditDataClicked()">
    MCR Audit Data
  </a>
  <div class="divider"></div>
  <a class="dropdown-item" (click)="onMortgageCalculatorsClicked()">
    Benefit Summaries
  </a>
  <a class="dropdown-item" (click)="onMonsterInsightsClicked()">
    Monster Insights
  </a>
  <div class="divider"></div>
  <a class="dropdown-item" (click)="onCheckListsClicked()">
    Checklists
  </a>
  <div class="divider"></div>
  <a class="dropdown-item" (click)="onCreateWireRequestClicked()">
    Create Wire Request
  </a>
  <div class="divider"></div>
  <ng-container *ngIf="application.companyId === 2">
    <a class="dropdown-item" (click)="onPurchaseAdviceClicked()">
      Purchase Advice
    </a>
    <div *ngIf="canRemoveFromAllReports" class="divider"></div>
  </ng-container>
  <a *ngIf="canRemoveFromAllReports" class="dropdown-item d-flex justify-content-between" (click)="onRemoveFromAllReportsToggled()">
    Remove From All Reports
    <div class="form-check form-switch form-switch-success">
      <input class="form-check-input form-check-input-lg" type="checkbox" name="removeFromReporting" id="removeFromReporting"
        [(ngModel)]="application.removeFromReporting">
    </div>
  </a>
</div>
