<div class="account-body accountbg"
  [style.background-image]="backgroundImageUrl ? 'url(' + backgroundImageUrl + ')' : ''">
  <img #backgroundTestImage [src]="backgroundImageUrl" height="90" [hidden]="true"
    (error)="onTestBackgroundImageErrored()">
  <!-- Log In page -->
  <div class="container">
    <div class="row vh-100 d-flex justify-content-center align-self-center">

      <div class="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-xs-10 mx-auto align-self-center">
        <div class="card">
          <div class="card-body p-0 login-header">
            <div class="text-center p-3">
              <logo [logoUrlsToTry]="possibleLogoUrls"></logo>
              <p *ngIf="!alreadyLoggedIn" class="text-muted  mb-0">Please login to access your portal</p>
            </div>
          </div>
          <div *ngIf="!alreadyLoggedIn" class="card-body p-0">
            <!-- Tab panes -->
            <div class="tab-content">
              <div class="px-3 pt-2" *ngIf="error">
                <div class="alert alert-danger border-0 mb-0" role="alert">
                  <strong>
                    <div>{{error.header}}</div>
                  </strong>
                  {{error.message}}
                </div>
              </div>
              <form class="form-horizontal auth-form" #loginForm="ngForm" novalidate id="loginForm" name="loginForm">
                <ngb-carousel #slider [showNavigationIndicators]="false" [showNavigationArrows]="false" [interval]="0"
                  [keyboard]="false" (slid)="onCarouselTransitionComplete($event)">
                  <ng-template ngbSlide id="userNameEntry">
                    <div class="p-3">

                        <div class="form-group mb-2">
                          <label class="form-label" for="username">Enter Your Username</label>
                          <div class="input-group">
                            <input type="email" autocomplete="off" class="form-control" name="username" id="login-username"
                              [disabled]="loggingIn" placeholder="Enter username" #userName="ngModel" trim
                              [ngClass]="{'is-invalid' : userName && userName.touched && userName.invalid}"
                              (keyup.enter)="onLoginClicked()" [(ngModel)]="loginInfo.username" required email>
                            <div class="invalid-feedback">Please enter your username.</div>
                          </div>
                        </div>

                        <div class="form-group mb-0 row">
                          <div class="col-12">
                            <button class="btn btn-primary w-100 waves-effect waves-light" type="button" name="loginBtn" id="login-login-btn2"
                              *ngIf="!loggingIn" (click)="onLoginClicked()">Login <i
                                class="fas fa-sign-in-alt ms-1"></i></button>
                            <button class="btn btn-primary w-100 waves-effect waves-light" type="button" disabled=""
                              *ngIf="loggingIn">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              {{companyName ? 'Going to ' + companyName + '...' : 'Please wait...'}}
                            </button>
                          </div>
                        </div>
                        <div class="m-3 text-center text-muted">
                          <p *ngIf="tpoRegistrationLink" class="mb-0">
                            <a class="text-primary" href="{{tpoRegistrationLink}}" target="_blank">New User Registration</a>
                          </p>
                        </div>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide id="companySelection">
                    <div class="slider-pane p-3">
                      <div class="mt-4">
                        <h4 class="card-title mb-2">Your account is linked to multiple companies</h4>
                        <p *ngIf="!loginInfo.userCompanyGuid" class="text-muted mb-2">Please, select the
                          company you'd
                          like to log into.</p>
                        <p *ngIf="selectedCompanyName" class="text-muted mb-2"> Logging into <strong>
                            {{selectedCompanyName}}. </strong> </p>
                        <div class="mt-2">
                          <select class="form-select" name="company" id="login-company"
                            [(ngModel)]="loginInfo.userCompanyGuid"
                            (ngModelChange)="onAvailableCompanyPickedForLogin()" placeholder="Select a Company">
                            <option [ngValue]="null">--Select One--</option>
                            <ng-container *ngFor="let companies of availableCompanies | groupBy: 'companyGuid'">
                              <optgroup label="{{companies.value[0].companyName}}">
                                <option *ngFor="let company of companies.value; let i = index"
                                  value="{{company.userCompanyGuid}}">
                                  {{company.userType}}
                                </option>
                              </optgroup>
                            </ng-container>
                          </select>
                          <div class="d-flex bd-highlight mt-3">
                            <ng-container *ngIf="!loggingIn">
                              <button type="button" class="btn btn-soft-secondary" (click)="onCancelLoginClicked()">Cancel</button>
                              <button (click)="onLetsGoClickedOnCompanySelectionPage()"
                                [disabled]="!loginInfo.companyGuid || !loginInfo.userCompanyGuid" class="btn btn-primary ms-auto" name="letsGoBtn"
                                id="login-letsgo-btn">Lets go!</button>
                            </ng-container>

                            <button class="btn btn-primary waves-effect waves-light ms-auto" type="button" disabled=""
                              *ngIf="loggingIn">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Please wait...
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide id="passwordEntry">
                    <div class="p-3">

                        <div [hidden]="!passwordRequired">
                          <div class="form-group mb-2">
                            <label class="form-label" for="userpassword">Enter Your Password</label>
                            <div class="input-group">
                              <input type="password" autocomplete="off" class="form-control" name="password"
                                [disabled]="loggingIn" id="login-password" placeholder="Enter password"
                                (keyup.enter)="onLoginClicked()" #userPasswordToLogin="ngModel"
                                [ngClass]="{'is-invalid' : userPasswordToLogin && userPasswordToLogin.touched && userPasswordToLogin.invalid}"
                                [required]="passwordRequired" [(ngModel)]="loginInfo.password">
                              <div class="invalid-feedback">Please enter your password.</div>
                            </div>
                          </div>
                          <div class="form-group row my-3">
                            <div class="col-sm-6">
                              
                            </div>
                            <div class="col-sm-6 text-end">
                              <a href="JavaScript:void(0);" (click)="onForgotPasswordClicked()" [hidden]="loggingIn"
                                class="text-muted font-13"><i class="dripicons-lock"></i>
                                Forgot password?
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="form-group mb-0 row">
                          <div class="col-12" style="text-align: center;">
                            <button class="btn btn-primary w-100 waves-effect waves-light" type="button" name="loginBtn" id="login-login-btn1"
                              *ngIf="!loggingIn" (click)="onLoginClicked()">Login <i
                                class="fas fa-sign-in-alt ms-1"></i></button>
                            <button class="btn btn-primary w-100 waves-effect waves-light" type="button" disabled=""
                              *ngIf="loggingIn">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              {{companyName ? 'Going to ' + companyName + '...' : 'Please wait...'}}
                            </button>
                          </div>
                          <div [hidden]="loggingIn" class="col-12 mt-2" style="text-align: center;">
                            <a href="JavaScript:void(0);" (click)="onBackClickedOnPasswordPage()"
                            class="text-muted font-13">&lt; Back</a>
                          </div>
                        </div>
                        <div class="m-3 text-center text-muted">
                          <p *ngIf="tpoRegistrationLink" class="mb-0">
                            <a class="text-primary" href="{{tpoRegistrationLink}}" target="_blank">New User Registration</a>
                          </p>
                        </div>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide id="twoFactorCodeEntry">
                    <div class="slider-pane p-3">
                      <div class="center mt-4">
                        <h4 class="card-title mb-2">We need to confirm it's really you</h4>
                        <p class="text-muted mb-2">Please, enter the code we sent you.</p>
                        <p class="text-muted mb-2">
                          Need a new code? <a *ngIf="!loggingIn" (click)="onSendCodeClicked()"> Resend
                            Code </a></p>

                        <div class="mt-2">
                          <input type="text" autocomplete="off" class="form-control" name="twoFactorCode"
                            id="twoFactorCode" [disabled]="loggingIn" placeholder="Enter Code" #twoFactorCode="ngModel"
                            [ngClass]="{'is-invalid' : twoFactorCode && twoFactorCode.touched && twoFactorCode.invalid}"
                            [(ngModel)]="loginInfo.twoFactorCode" (keyup.enter)="onLoginClicked()" autofocus="autofocus"
                            #twoFactorCodeInput>

                          <div class="invalid-feedback">Incorrect code. Please re-enter the code we sent you.
                          </div>
                          <div class="d-flex bd-highlight mt-3">
                            <button type="button" *ngIf="!loggingIn" class="btn btn-soft-secondary" (click)="onCancelLoginClicked()">Cancel</button>

                            <button *ngIf="!loggingIn" (click)="onLoginClicked()" [disabled]="!loginInfo.twoFactorCode"
                              class="btn btn-primary ms-auto">Verify</button>
                            <button class="btn btn-primary waves-effect waves-light ms-auto" type="button" disabled=""
                              *ngIf="loggingIn">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Please wait...
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide id="mfaSetup">
                    <div class="p-3">
                      <form class="form-horizontal auth-form" #mfaSetupForm="ngForm" novalidate id="mfaSetupForm"
                        name="mfaSetupForm">
                        <div class="center mt-4">
                          <h4 class="card-title mb-2">You need to setup MFA on your account</h4>
                          <p class="text-muted mb-2" *ngIf="!needToConfirmPhone">Please, enter your phone number.</p>
                          <p class="text-muted mb-2">
                          </p>

                          <div class="mt-2">
                            <div class="form-group mb-3 d-flex" *ngIf="!needToConfirmPhone">
                              <div>
                                <label class="form-label">Country Code</label>
                                <ng-select2 class="select2 mb-3 select2-multiple"
                                  name="areaCode" [options]="countriesOptions"
                                  [(ngModel)]="setting.areaCode" [data]="countries">
                                </ng-select2>
                              </div>

                              <div class="ms-2 flex-grow-1">
                                <label class="form-label">Phone Number</label>
                                <input name="phoneNumber" class="form-control" [(ngModel)]="setting.phone" [mask]="'(000) 000-0000'"
                                  (input)="onNewPhoneNumberChanged()" [placeholder]="'(###) ###-####'" type="text">
                              </div>
                            </div>

                            <div *ngIf="needToConfirmPhone">
                              <p class="text-muted mb-2">Please, enter the code we sent you.</p>

                              <div class="mt-2 d-flex">
                                <input type="text" autocomplete="off" class="form-control" name="verificationCode"
                                  id="verificationCode" [disabled]="loggingIn" placeholder="Enter Code" #verificationCodeValue="ngModel"
                                  [ngClass]="{'is-invalid' : verificationCodeValue && verificationCodeValue.touched && verificationCodeValue.invalid}"
                                  [(ngModel)]="verificationCode" (keyup.enter)="onLoginClicked()" autofocus="autofocus"
                                  #verificationCodeInput>

                                <div class="invalid-feedback">Incorrect code. Please re-enter the code we sent you.
                                </div>
                                <!-- <button style="width: 180px" [disabled]="loggingIn" (click)="onChangeNumberClicked()" class="btn btn-primary ms-auto">Change Number</button> -->
                              </div>
                            </div>

                            <div class="d-flex bd-highlight mt-3" *ngIf="!needToConfirmPhone">
                              <button type="button" *ngIf="!loggingIn" class="btn btn-soft-secondary" (click)="onCancelLoginClicked()">Cancel</button>

                              <button *ngIf="!loggingIn" (click)="onSaveNumberClicked()" [disabled]="!setting.areaCode || !setting.phone"
                                class="btn btn-primary ms-auto">Save Number</button>
                              <button class="btn btn-primary waves-effect waves-light ms-auto" type="button" disabled="" *ngIf="loggingIn">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Please wait...
                              </button>
                            </div>
                            <div class="d-flex justify-content-between bd-highlight mt-3" *ngIf="needToConfirmPhone">

                              <ng-container *ngIf="!loggingIn" >
                                <button type="button" class="btn btn-soft-secondary" (click)="onCancelLoginClicked()">Cancel</button>

                                <a href="javascript:void(0)" style="text-decoration: underline" class="pt-1"
                                  (click)="onChangeNumberClicked()">Change Number</a>

                                <button (click)="onConfirmPhoneClicked()" [disabled]="!verificationCode"
                                  class="btn btn-primary">Verify</button>
                              </ng-container>

                              <button class="btn btn-primary waves-effect waves-light ms-auto" type="button" disabled="" *ngIf="loggingIn">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Please wait...
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </ng-template>
                </ngb-carousel>
              </form>
            </div>
          </div>
          <div *ngIf="alreadyLoggedIn" class="card-body text-center">
            <h5> You appear to be already logged in. Verifying login information and redirecting to dashboard...</h5>
          </div>
          <!--end card-body-->
          <div class="card-body bg-light-alt text-center login-footer">
            <span class="text-muted d-none d-sm-inline-block">Lodasoft © {{currentYear}}
              <script>
                { { document.write(new Date().getFullYear()) } }
              </script>
            </span>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <!--end row-->
  </div>
  <!--end container-->
  <!-- End Log In page -->
</div>
