import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import * as _ from 'lodash';
import { Dropdown } from 'primeng/dropdown';
import { AbstractValueAccessor, MakeProvider } from 'src/app/core/abstract-value-accessor';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EditorMode } from '../currency-input/currency-input.component';

@Component({
  selector: 'select-input-vscroll',
  templateUrl: 'select-input-vscroll.component.html',
  styleUrls: ['./select-input-vscroll.component.scss'],
  providers: [MakeProvider(SelectInputVScrollComponent)],
  viewProviders: [formViewProvider]
})

export class SelectInputVScrollComponent extends AbstractValueAccessor implements OnInit, OnChanges {

  @ViewChild('model')
  model: NgModel;

  @ViewChild('dropdown')
  dropdown: Dropdown;

  @Input()
  readonly: boolean;

  @Input()
  disabled: boolean;

  @Input()
  name: string;

  @Input()
  required: boolean;

  @Input()
  editorMode: EditorMode = EditorMode.Classic;

  @Input()
  inlineTextClass: string;

  @Input()
  shiftInputToLeftWhenEditingInline: boolean = false;

  @Input()
  isMultiple: boolean = false;

  @Input()
  options: EnumerationItem[] | any[] = [];

  @Input()
  optionValueKey: string = "value";

  @Input()
  optionNameKey: string = "name";

  @Input()
  optionGroupNameKey: string;

  @Input()
  placeholder: string;

  @Output()
  blur: EventEmitter<any> = new EventEmitter<any>();

  id: string;

  isEditActive: boolean = false;

  originalValue: any;

  groupedOptions: any[] = [];
  groupShowingEnabled: boolean = false;

  private blurCount = 0;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.options && !changes.options.firstChange) {
      this.initializeOptions();
    }
  }

  ngOnInit(): void {
    this.id = this.name;
    this.name = this.name + Math.floor(Math.random() * Date.now());

    this.initializeOptions();
  }

  private initializeOptions() {
    if (!this.options || this.options.length == 0) {
      this.groupedOptions = [];
    }
    if (!!this.optionGroupNameKey) {
      this.groupShowingEnabled = true;
      let groups = _.groupBy(this.options, this.optionGroupNameKey);

      this.groupedOptions = Object.keys(groups).map((g, index) => {
        return {
          label: groups[g][0][this.optionGroupNameKey],
          value: index + 1,
          items: groups[g]
        }
      })
    } else {
      this.groupedOptions = this.options;
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onBlur = ($event: any) => {
    this.blurCount++;
    if (this.blurCount > 1) {
      this.blur.emit($event);
      this.applyClicked();
    }
  }

  getSelectedOptionName = (v: any): string => {
    if (!v || !this.options) {
      return null;
    }

    let selected = this.options.find(o => o[this.optionValueKey] == v);
    return selected ? selected[this.optionNameKey] : null;
  }


  override writeValue(value: any) {
    this._value = value;
    this.onChange(value);
    this.originalValue = value; // for initializing
  }

  cancelClicked = () => {
    this.isEditActive = false;
    this.blurCount = 0;
    this.value = this.originalValue;
    this.blur.emit();
  }

  applyClicked = () => {
    if (this.model.valid) {
      this.originalValue = this.value;
      this.isEditActive = false;
      this.blurCount = 0;
    }
  }

  onClear = (event) => {
    this.dropdown.focus();
    this.blurCount++;
  }
}
