<ng-container *ngIf="!isLoading else loading">

  <div class="card">
    <div class="card-header d-flex justify-content-between">
      <h4 class="card-title"> Product </h4>
      <div class="text-center">
        <button type="button" class="btn btn-sm btn-primary" (click)="onSearchProductsClicked()"
          [disabled]="!currentMortgage">
          <span class="spinner-border spinner-border-sm" role="status" *ngIf="!currentMortgage">
          </span>
          {{currentMortgage ? 'Search Products' : 'Loading...'}}
        </button>
      </div>
    </div>

    <div class="card-body pricing-body" *ngIf="productPricing">
      <div class="row">
        <ul>
          <li>
            <span class="key"> Product Name: </span>
            <span class="value"> {{productPricing.productName || "-"}} </span>
          </li>
          <li *ngIf="!isTpoUser">
            <span class="key"> Investor: </span>
            <span class="value"> {{productPricing.investor || "-"}} </span>
          </li>
          <li *ngIf="isTpoUser">
            <span class="key"> &nbsp;</span>
            <span class="value"> &nbsp; </span>
          </li>
          <li>
            <span class="key"> Ratesheet Date/Time: </span>
            <span class="value"> {{productPricing.rateSheetId || "-"}} </span>
          </li>
          <li>
            <span class="key"> Amortization Type: </span>
            <span class="value"> {{amortizationTypeForMortgage}} </span>
          </li>
          <li>
            <span class="key"> Term: </span>
            <span class="value"> {{productPricing.term || "-"}} </span>
          </li>
          <li>
            <span class="key"> Arm Index: </span>
            <span class="value"> {{productPricing.armIndex || 'N/A'}} </span>
          </li>

          <li>
            <span class="key"> Notes: </span>
            <span class="value">
              <a type="button" class="text-primary" (click)='viewNotesAndAdvisories()'> View
                Notes/Advisories </a>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header pricing-header">
      <h4 class="card-title"> Pricing </h4>

      <div *ngIf="productPricing && !productPricing.lockDate && productPricing.pricingVendor !== 'LenderPriceIframe'">
        <ng-container *ngIf="!isLoadingPricingValidity else loadingPricingValidity">
          <h4 class="card-title" style="color: grey; margin:0" *ngIf="!productPricing.assignDate">Pricing Not Assigned
          </h4>
          <h4 class="card-title text-danger" *ngIf="productPricing.assignDate && !isProductPricingValid">
            Assigned Pricing is Invalid
            <a class="mt-1 ms-1" (click)="showPricingValidationModal()"> <i title="Click to show comparison"
                class="fa fa-eye link" style="color:black">
              </i> </a>
          </h4>
          <h4 class="card-title text-success" *ngIf="productPricing.assignDate && isProductPricingValid">
            Pricing is Valid</h4>
        </ng-container>

        <ng-template #loadingPricingValidity>
          <h4 class="card-title" style="color: grey; margin:0">
            Checking Pricing Validity <i class="fa fa-circle-notch fa-spin"></i>
          </h4>
        </ng-template>
      </div>
    </div>

    <div class="card-body pricing-body" *ngIf="productPricing">
      <div class="row">
        <ul>
          <li>
            <span class="key"> Rate: </span>
            <span class="value"> {{(productPricing.rate || 0) | percent:'1.3-3'}}</span>
          </li>
          <li>
            <span class="key"> Points: </span>
            <span class="value"> {{(productPricing.price || 0) | number: '1.0' }} </span>
          </li>
          <li>
            <span class="key"> APR: </span>
            <span class="value"> {{((productPricing.apr || 0) / 100) | percent:'1.3-3'}}</span>
          </li>
          <li>
            <span class="key"> Comp Type: </span>
            <span class="value"> {{productPricing.compType || "-" }} <i *ngIf="productPricing.compType == 'Lender'"
                [outsideClick]="true" [popover]="antiSteeringTemplate" class="fa fa-info-circle ms-2"
                placement="bottom"></i></span>
          </li>
          <li>
            <span class="key"> Lock Term: </span>
            <span class="value"> {{productPricing.lockTerm || "-" }} </span>
          </li>
          <li>
            <span class="key"> Adjustments: </span>
            <span class="value"><a type="button" class="text-primary" (click)='onAdjustmentsClicked()'> View Adjustments
              </a></span>
          </li>
          <li>
            <span class="key"> Assignment Date: </span>
            <span class="value"> {{(productPricing.assignDate | date: 'MM/dd/yyyy hh:mm a') || "-"}} </span>
          </li>
          <li>
            <span class="key"> Search ID: </span>
            <span class="value"> {{productPricing.assignedSearchId || "-" }} </span>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <h4 class="card-title"> Lock </h4>
    </div>

    <div class="card-body pricing-body" *ngIf="productPricing">
      <div class="row">
        <ul>
          <li *ngIf="productPricing.pricingVendor !== 'LenderPriceIframe'">
            <span class="key"> Actions: </span>
            <ng-container *ngIf="!isLoadingPricingValidity else loadingPricingActionsValidity">
              <span class="value" *ngIf="isTpoUser || losStatusType !== 'notAssociated' || !externalLockEnabled">
                <a *ngIf="(!isTpoUser && (losStatusType !== 'notAssociated' || !externalLockEnabled) || tpoPricingLockEnabled) && (!productPricing.lockStatus || productPricing.lockStatus == 'Rejected' || productPricing.lockStatus == 'Canceled')
                        && isProductPricingValid" class="pb-2 text-danger request-lock-style pricing-action"
                  (click)="onRequestRateLockClicked()">
                  Request Rate Lock </a>

                <a *ngIf="isTpoUser && showManualLockForm && (!productPricing.lockStatus || productPricing.lockStatus == 'Rejected' || productPricing.lockStatus == 'Canceled')"
                  [routerLink]="['/tpo/app-details/' + loanId + '/manual-lock']" class="pb-2 pricing-action">
                  <span>Manual Lock Request</span></a>

                <a *ngIf="productPricing.lockStatus == 'Requested' && isProductPricingValid && allowPricingActions"
                  class="pb-2 text-success request-lock-style pricing-action" (click)="onApproveRateLock()"> Approve
                  Rate
                  Lock </a>

                <a *ngIf="productPricing.lockStatus == 'Requested'  && allowPricingActions"
                  class="pb-2 text-danger request-lock-style pricing-action" (click)="onRejectRateLock()"> Reject Rate
                  Lock </a>

                <a *ngIf="productPricing.lockStatus == 'CancelRequested' && allowPricingActions"
                  class="pb-2 text-danger request-lock-style pricing-action" (click)="onCancelRateLock()"> Cancel Rate
                  Lock </a>

                <a *ngIf="(!isTpoUser || tpoPricingCancelLockEnabled) && (productPricing.lockStatus == 'Requested' || productPricing.lockStatus == 'Accepted')"
                  class="pb-2 text-danger request-lock-style pricing-action" (click)="onRequestLockCancellation()">
                  Request Lock
                  Cancellation
                </a>
                <!--
                productPricing.pricingVendor === 'Polly' case is temporary util the apis support all vendors
              -->
                <a *ngIf="productPricing.pricingVendor === 'Polly' && (!isTpoUser || tpoPricingRepriceLockEnabled) && productPricing.lockStatus == 'Accepted'"
                  class="pb-2 text-danger request-lock-style pricing-action"
                  (click)="onSearchProductsClicked('reprice')">
                  Reprice Lock
                </a>
                <a *ngIf="productPricing.pricingVendor === 'Polly' && (!isTpoUser || tpoPricingProgramChangeEnabled) && productPricing.lockStatus == 'Accepted'"
                  class="pb-2 text-danger request-lock-style pricing-action"
                  (click)="onSearchProductsClicked('programChange')"> Program Change
                </a>
                <a *ngIf="productPricing.pricingVendor === 'Polly' && (!isTpoUser || tpoPricingRequestLockExtensionEnabled) && productPricing.lockStatus == 'Accepted'"
                  class="pb-2 text-danger request-lock-style pricing-action" (click)="onRequestLockExtension()"> Request
                  Lock Extension
                </a>
              </span>
              <span class="value" *ngIf="(!isTpoUser && losStatusType === 'notAssociated' && externalLockEnabled)">
                Lock not available, loan is not synced with LOS
              </span>
            </ng-container>
            <ng-template #loadingPricingActionsValidity>
              <span>Loading Pricing Actions <i class="fa fa-circle-notch fa-spin"></i></span>
            </ng-template>
          </li>
          <li *ngIf="productPricing.lockStatus">
            <span class="key"> Lock Period: </span>
            <span class="value"> {{productPricing.lockTerm || "-"}} </span>
          </li>
          <li *ngIf="productPricing.lockStatus">
            <span class="key"> Lock Date: </span>
            <span class="value"> {{productPricing.lockDate ? (productPricing.lockDate | date:'MM/dd/yyyy' : 'UTC') : "-"}}
            </span>

          </li>
          <li *ngIf="productPricing.lockStatus">
            <span class="key"> Lock Expiration Date: </span>
            <span class="value"> {{productPricing.lockExpirationDate ? (productPricing.lockExpirationDate |
              date:'MM/dd/yyyy' : 'UTC') : "-"}} </span>

          </li>
          <li *ngIf="productPricing.lockStatus">
            <span class="key"> Lock Status: </span>
            <span class="value" [ngClass]="{'text-danger': productPricing.lockStatus === 'Expired'}">
              {{productPricing.lockStatus}} </span>
          </li>
          <li *ngIf="productPricing.lockStatus && productPricing.lockStatus === 'Accepted' && externalLockEnabled &&
            productPricing.pricingVendorLoanId">
            <span class="key"> External Loan Id: </span>
            <span class="value"> {{ productPricing.pricingVendorLoanId }} </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <loading-indicator [loadingMessage]="'Loading pricing details...'">
  </loading-indicator>
</ng-template>

<ng-template #antiSteeringTemplate>
  <div class="card-body" style="min-width: 540px; background-color:#ced1da40; margin: -1rem -1rem -1.8rem -1rem;">
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Option 1 <i class="fa fa-percent pull-right mt-1"></i><i
                class="fa fa-arrow-down pull-right mt-1"></i></h4>
          </div>
          <div class="card-body pricing-popover">
            <ul>
              <li>
                <span class="key"> Rate: </span>
                <span class="value"> {{(productPricing.altOption1Rate || 0)/100 | percent:'1.3-3'}}</span>
              </li>
              <li>
                <span class="key"> Points: </span>
                <span class="value"> {{(productPricing.altOption1Price || 0) | number: '1.0' }} </span>
              </li>
              <li>
                <span class="key"> Dollars: </span>
                <span class="value" style="margin-left: -7px;">${{(productPricing.altOption1Dollar || 0) | number: '1.2'
                  }} </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Option 2 <i class="fa fa-percent pull-right mt-1"></i><i
                class="fa fa-arrow-down pull-right mt-1"></i></h4>
          </div>
          <div class="card-body pricing-popover">
            <ul>
              <li>
                <span class="key"> Rate: </span>
                <span class="value"> {{(productPricing.altOption2Rate || 0)/100 | percent:'1.3-3'}}</span>
              </li>
              <li>
                <span class="key"> Price: </span>
                <span class="value"> {{(productPricing.altOption2Price || 0) | number: '1.0' }} </span>
              </li>
              <li>
                <span class="key"> Dollars: </span>
                <span class="value" style="margin-left: -7px;">${{(productPricing.altOption2Dollar || 0) | number: '1.2'
                  }} </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Option 3 <i class="fa fa-percent pull-right mt-1"></i><i
                class="fa fa-arrow-up pull-right mt-1"></i></h4>
          </div>
          <div class="card-body pricing-popover">
            <div class="row">
              <ul>
                <li>
                  <span class="key"> Rate: </span>
                  <span class="value"> {{(productPricing.altOption3Rate || 0)/100 | percent:'1.3-3'}}</span>
                </li>
                <li>
                  <span class="key"> Points: </span>
                  <span class="value"> {{(productPricing.altOption3Price || 0) | number: '1.0' }} </span>
                </li>
                <li>
                  <span class="key"> Dollars: </span>
                  <span class="value" style="margin-left: -7px;">${{(productPricing.altOption3Dollar || 0) | number:
                    '1.2' }} </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
