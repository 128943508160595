import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, timer, switchMap } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';


@Injectable()
export class ThrottledApiCallHttpInterceptor implements HttpInterceptor {
  // Hardcoded list of specific URL paths for which requests should be queued
  private restrictedEndpoints: string[] = ['get-detailed-kpi', 'get-lead-pipeline-breakdown', 'get-kpi-detail-extended'];

  // Hardcoded list of specific URL paths with delay and the delay time (in milliseconds)
  private delayedEndpoints: { [url: string]: number } = { 
    // 'get-detailed-kpi': 500,
  };

  private queueMap: Map<string, Array<{ req: HttpRequest<any>, next: HttpHandler, resolve: (event: HttpEvent<any>) => void, reject: (error: any) => void }>> = new Map();
  private inProgressMap: Map<string, boolean> = new Map();

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestPath = this.getUrlPath(req.url); // Get the final part of the URL path

    // Check if the request URL matches any of the delayed endpoints
    if (this.delayedEndpoints.hasOwnProperty(requestPath)) {
      return this.handleDelayedRequest(req, next, this.delayedEndpoints[requestPath]);
    }

    // Check if the request URL matches any of the restricted endpoints
    if (this.restrictedEndpoints.includes(requestPath)) {
      return this.handleQueuedRequest(req, next, requestPath);
    }

    // For all other URLs, proceed normally
    return next.handle(req);
  }

  private handleDelayedRequest(req: HttpRequest<any>, next: HttpHandler, delay: number): Observable<HttpEvent<any>> {
    // Delay the execution by the specified number of milliseconds
    return timer(delay).pipe(
      switchMap(() => next.handle(req)) // After the delay, proceed with the request
    );
  }

  private handleQueuedRequest(req: HttpRequest<any>, next: HttpHandler, requestPath: string): Observable<HttpEvent<any>> {
    // If there's no queue for this path, initialize it
    if (!this.queueMap.has(requestPath)) {
      this.queueMap.set(requestPath, []);
      this.inProgressMap.set(requestPath, false);
    }

    // Create a subject to manage the request
    const subject = new Subject<HttpEvent<any>>();

    // Add the request to the queue for the specific URL path
    this.queueMap.get(requestPath)!.push({
      req,
      next,
      resolve: (event: HttpEvent<any>) => subject.next(event),
      reject: (error: any) => subject.error(error),
    });

    // Start processing the queue for this path if it's not already
    if (!this.inProgressMap.get(requestPath)) {
      this.processQueue(requestPath);
    }

    return subject.asObservable();
  }

  private processQueue(requestPath: string) {
    const queue = this.queueMap.get(requestPath);

    if (!queue || queue.length === 0) {
      this.inProgressMap.set(requestPath, false);
      return;
    }

    this.inProgressMap.set(requestPath, true);

    const { req, next, resolve, reject } = queue.shift()!;

    next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        reject(error);
        throw error; // Rethrow to allow `finalize` to still run
      }),
      finalize(() => {
        this.processQueue(requestPath); // Continue with the next request in the same URL queue
      })
    ).subscribe({
      next: resolve,
      error: reject,
    });
  }

  private getUrlPath(url: string): string {
    const urlParts = url.split('/');
    if (urlParts.length > 0) {
      const lastPart = urlParts[urlParts.length - 1];
      const queryIndex = lastPart.indexOf('?');
      return queryIndex === -1 ? lastPart : lastPart.slice(0, queryIndex);
    }
  }
}
