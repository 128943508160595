import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import { Event, EventType, EventVisibility } from '../../models/event.model';
import { EventsService } from '../../services/events.service';
import { DateTime } from 'luxon';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import {ApplicationContextBoundComponent} from '../../../../shared/components';
import { NotificationService } from 'src/app/services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { InternalContactsService } from 'src/app/modules/internal-contacts/services/internal-contacts.service';
import { Utils } from 'src/app/core/services/utils';
import { InternalContact } from 'src/app/modules/internal-contacts/models/internal-contact.model';

@Component({
  selector: 'event-editor',
  templateUrl: 'event-editor.component.html',
  styleUrls: ['event-editor.component.scss']
})
export class EventEditorComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('eventForm') eventForm: NgForm | undefined;

  @Input()
  showActionControls: boolean = true;

  @Input()
  readonly: boolean = false;

  @Input()
  set event(event: Event) {
    if (!event) {
      return;
    }
    this._event = _.cloneDeep(event);
    if (event.eventId > 0 || event.eventType == EventType.Appointment) {
      this.inEditMode = true;
    }
    this.selectedDate = new Date(event.dateStart);

    if (this._event.thirdPartyEventId && this._event.description) {
      this._event.description = this._event.description.replace(/<[^>]*>/g, ''); // remove html
      this._event.description = this._event.description.replace(/(\r?\n){1,}/g, '\n'); // remove new lines
      this._event.description = this._event.description.replace(/&nbsp;/g, ""); // when empty it used to show '&nbsp;'

      // Remove all newline characters if there are no other characters
      if (/^\n+$/.test(this._event.description)) {
        this._event.description = '';  
      }
    }

    this.setEventTypeOptions(this._event.eventType);
  }

  get event(): Event {
    return this._event;
  }

  @Input()
  set eventType(type: EventType) {
    this.setEventTypeOptions(type);
  }

  @Input()
  selectedDate: Date;

  @Output()
  eventSaved: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  eventDeleted: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  dataChanged: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  errorOccurredSavingEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  errorOccurredDeletingEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  openLead: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  onFormInvalid: EventEmitter<never> = new EventEmitter<never>();

  startTimeString: string;
  startTime: Date;

  endTimeString: string;
  duration: number = 30;

  inEditMode: boolean = false;
  appointmentForSomebodyElse: boolean = false;
  inviteBorrowerByDefault: boolean = true;

  url: string = "";
  componentId: string = "";
  eventEditorSpinner: string = "eventEditorSpinner";
  userCompanyGuid: string = "";

  visibilityOptions: EnumerationItem[] = [
    new EnumerationItem(EventVisibility.Private, EventVisibility.Private),
    new EnumerationItem(EventVisibility.Public, EventVisibility.Public)
  ];
  eventTypeOptions: EnumerationItem[] = [
    new EnumerationItem("Select One", null),
    new EnumerationItem(EventType.Appointment, EventType.Appointment),
    new EnumerationItem("Estimated Closing", EventType.EstimatedClosing),
    new EnumerationItem("Lock Expiration", EventType.LockExpiration)
  ];
  dayEvents: EventTimes[] = [];
  userEvents: EventTimes[] = [];
  contacts: ExtendedInternalContact[] = []

  private _event: Event = null;

  constructor(
    private readonly injector: Injector,
    private readonly _eventsService: EventsService,
    private readonly _notificationService: NotificationService,
    private readonly _navigationService: NavigationService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _internalContactsService: InternalContactsService
  ) {
    super(injector);

    const initialStartTime = this.calculateInitialStartTime(new Date());
    this.startTimeString = this.parseHHmm(initialStartTime);
    this.startTime = initialStartTime;
    this.endTimeString = this.parseHHmm(this.calculateEndTime());

    this.componentId = uuidv4();
    this.eventEditorSpinner += this.componentId;
    this.userCompanyGuid = this.applicationContext.userPermissions.userId;

    this._applicationContextService.context.subscribe(context => {
      this._event = new Event();
      this._event.userId = context.currentlyLoggedInUser.userCompanyGuid;
      this._event.privilege = EventVisibility.Private;
    });
  }

  ngOnInit(): void {
    if (this._navigationService.applicationMode == ApplicationMode.Classic) {
      this.url = "/admin/app-details/";
    } else {
      this.url = "/loda-nextgen/app-details/";
    }

    if (this.event.applicationId) {
      this.loadLoanContactRoles();
    } else if (this.event.leadId) {
      this.loadLeadContacts();
    }

    let date: string;
    if (!this.inEditMode) {
      date = (this.selectedDate?.getMonth() + 1) + '/' + this.selectedDate?.getDate() + '/' + this.selectedDate?.getFullYear();
      this._event.dateStart = DateTime.fromJSDate(this.selectedDate).toFormat("yyyy-MM-dd");
    } else {
      const eventDate = new Date(this._event.dateStart || this._event.systemAllDayEventDate);
      this.startTimeString = this.parseHHmm(eventDate);
      this.startTime = eventDate;
      const endTime = new Date(this._event.dateEnd);

      this.duration = DateTime.fromJSDate(endTime).diff(DateTime.fromJSDate(eventDate), ["minutes"]).minutes;
      this.endTimeString = this.parseHHmm(endTime);
      this._event.dateStart = DateTime.fromJSDate(eventDate).toFormat("yyyy-MM-dd");
      date = (eventDate.getMonth() + 1) + '/' + eventDate.getDate() + '/' + eventDate.getFullYear();
    }

    this._eventsService.getEventsByDate({ filterDate: date }).subscribe({
      next: (events) => {
        this.dayEvents = events.map(e => {
          const start = new Date(e.dateStart).toLocaleTimeString().replace(/(.*)\D\d+/, '$1');
          const end = new Date(e.dateEnd).toLocaleTimeString().replace(/(.*)\D\d+/, '$1');

          return {
            userId: e.userId,
            ...new EventTimes(start, end),
          }
        });

        this.filterUserEvents(this.userCompanyGuid);
      },
      error: (error) => {
        this._notificationService.showError(error?.message || "Couldn't load events", "Error!");
      }
    });
  }

  validate = (): boolean => {
    this.eventForm.form.markAllAsTouched();
    return this.eventForm.form.valid;
  }

  save = () => {
    if (!this.validate()) {
      this.onFormInvalid.emit();
      return;
    }

    const { dateStart, dateEnd } = this.setStartAndEndTimes();
    const event = {
      ...this._event,
      dateStart,
      dateEnd
    };

    if (!this.inviteBorrowerByDefault) {
      event.borrowerId = null;
    }

    let service = this._eventsService.createEvent(event); // createEvent is actually upsert

    if (!event.userId) {
      event.userId = this.userCompanyGuid;
    }

    this._spinner.show();
    service.subscribe({
      next: (response) => {
        this._event = {
          ...response,
          customEventId: this._event['customEventId'] || Utils.getUniqueId()
        } as any;
        this._notificationService.showSuccess(`Event ${this._event.eventId && this._event.eventId !== 0 ? 'updated' : 'added'} successfully.`, "Success");
        this.eventSaved.emit(this._event);
        this.dataChanged.emit(this._event);
      },
      error: (error) => {
        this._notificationService.showError(error?.message || `Couldn't ${this._event.eventId !== 0 ? 'update' : 'add new'} event.`, "Error");
      }
    }).add(() => this._spinner.hide());
  }

  delete = () => {
    this._spinner.show();
    this._eventsService.deleteEvent(this.event.eventId, this.event.thirdPartyEventId).subscribe({
      next: (response) => {
        this.eventDeleted.emit(this.event);
        this.dataChanged.emit(this._event);
      },
      error: (error) => {
        this._notificationService.showError(error?.message || `Couldn't delete this event.`, "Error");
      }
    }).add(() => this._spinner.hide());
  }

  onStartTimeStringChanged = () => {
    this.startTime = this.parseTime(this.startTimeString);
    this.startTimeString = this.parseHHmm(this.startTime);
    this.endTimeString = this.parseHHmm(this.calculateEndTime());
  }

  onStartTimeChanged = () => {
    this.startTimeString = this.parseHHmm(this.startTime);
    this.endTimeString = this.parseHHmm(this.calculateEndTime());
  }

  onDurationChanged = () => {
    this.endTimeString = this.parseHHmm(this.calculateEndTime());
  }

  onSaveClicked = () => {
    this.save();
  }

  onDeleteClicked = () => {
    this.delete();
  }

  onLeadOpened = (event) => {
    this.openLead.emit(event);
  }

  onAppointmentForOthetUserCheckboxChanged = () => {
    this.filterUserEvents(this.event?.userId);
  }

  onAppointmentForOthetUserChanged = () => {
    this.filterUserEvents(this.event?.userId);
  }

  private filterUserEvents = (userId: string) => {
    if (!userId) return;
    this.userEvents = this.dayEvents.filter(u => u.userId === userId);
  }

  private setStartAndEndTimes = () => {
    let dateStart = new Date(this._event.dateStart);
    dateStart = new Date(dateStart.toISOString().slice(0, -1));

    dateStart.setHours(this.startTime.getHours());
    dateStart.setMinutes(this.startTime.getMinutes());
    dateStart.setSeconds(0, 0);

    let dateEnd = new Date(this._event.dateStart);
    dateEnd = new Date(dateEnd.toISOString().slice(0, -1));

    dateEnd.setHours(this.startTime.getHours());
    dateEnd.setMinutes(this.startTime.getMinutes() + this.duration);
    dateEnd.setSeconds(0, 0);

    if (this._event.allDay) {
      this._event.dateEnd = this._event.dateStart;
    }

    return {
      dateStart: dateStart.toISOString(),
      dateEnd: dateEnd.toISOString()
    }
  }

  private calculateEndTime = (): Date => {
    const endTime = new Date();
    endTime.setHours(this.startTime.getHours());
    endTime.setMinutes(this.startTime.getMinutes() + this.duration);
    endTime.setSeconds(0, 0);
    return endTime;
  }

  private calculateInitialStartTime = (date): Date => {
    let minutes = date.getMinutes();
    if (minutes >= 0 && minutes < 15) {
      minutes = 15;
    } else if (minutes > 15 && minutes < 30) {
      minutes = 30;
    } else if (minutes > 30 && minutes <= 45) {
      minutes = 45;
    } else {
      minutes = 60;
    }
    date.setMinutes(minutes);
    return date;
  }

  private parseTime = (timeString: string): Date => {
    if (timeString == '' || timeString == undefined) return null;

    const time = timeString.match(/(\d+)(:(\d\d))?\s*(p?)/i);
    if (time == null) {
      const d = new Date();
      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0, 0);
      return d;
    }

    time[4] = timeString.indexOf('PM') > 0 ? '12' : '';
    let hours = parseInt(time[1], 10);
    if (hours == 12 && !time[4]) {
      hours = 0;
    } else {
      hours += (hours < 12 && time[4]) ? 12 : 0;
    }
    const d = new Date();
    d.setHours(hours);
    d.setMinutes(parseInt(time[3], 10) || 0);
    d.setSeconds(0, 0);
    return d;
  }

  private parseHHmm = (dateTime: Date): string => {
    let hours = dateTime.getHours() < 13 ? dateTime.getHours() : dateTime.getHours() - 12;
    hours = hours == 0 ? 12 : hours;
    let minutes: any = dateTime.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const ampm = dateTime.getHours() < 12 ? "AM" : "PM";
    return `${hours} : ${minutes} ${ampm}`;
  }

  // TODO - kkilinc - why are we doing this?? Why are we restricting the event types?
  private setEventTypeOptions = (type: EventType) => {
    this.eventTypeOptions = [];
    if (type === EventType.Appointment) {
      this.eventTypeOptions.push(new EnumerationItem(EventType.Appointment, EventType.Appointment));
    } else if (type === EventType.EstimatedClosing) {
      this.eventTypeOptions.push(new EnumerationItem("Estimated Closing", EventType.EstimatedClosing));
    } else if (type === EventType.LockExpiration) {
      this.eventTypeOptions.push(new EnumerationItem("Lock Expiration", EventType.LockExpiration));
    } else {
      this.eventTypeOptions.push(new EnumerationItem(EventType.Appointment, EventType.Appointment));
      this.eventTypeOptions.push(new EnumerationItem("Estimated Closing", EventType.EstimatedClosing));
      this.eventTypeOptions.push(new EnumerationItem("Lock Expiration", EventType.LockExpiration));
    }
    if (this._event && !this.inEditMode) {
      this._event.eventType = type;
    }
  }

  private loadLoanContactRoles = () => {    
    this._spinner.show(this.eventEditorSpinner);

    this._internalContactsService.getInternalContacts(this.event.applicationId).subscribe({
      next: (response) => {
        this.contacts = response.filter(c => c.userId).map(c => {
          const matchingUser = this.applicationContext.globalConfig.usersAll.find(u => u.userCompanyGuid == c.userId);
          const formattedName = matchingUser ?
            Utils.getPersonsDisplayName(matchingUser) + (matchingUser.active ? "" : ' - (Inactive)') :
            "-";

          return {
            ...c,
            formattedName: formattedName,
            roleName: this.getRoleNameById(c.roleId),
          }
        });
      },
      error: (error) => {
        this._notificationService.showError(error?.message || "Couldn't load loan contact roles.", "Error!");
      }
    }).add(() => this._spinner.hide(this.eventEditorSpinner));
  }

  private loadLeadContacts = () => {    
    this.contacts = this.applicationContext.globalConfig.users.map(user => ({
      userId: user.userCompanyGuid,
      formattedName: Utils.getPersonsDisplayName(user),
    }));
  }

  private getRoleNameById(roleId: number) {
    return this.applicationContext.globalConfig.roles.find(u => u.roleId == roleId)?.roleName || '';
  }
}
export class EventTimes {
  start: string;
  end: string;
  userId: string;

  constructor(start: string, end: string) {
    this.start = start;
    this.end = end;
  }
}

interface ExtendedInternalContact extends Partial<InternalContact> {
  roleName?: string;
  formattedName: string;
}