import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom, Subscription } from 'rxjs';
import { ApplicationContext, Borrower, LoanApplication } from 'src/app/models';
import { BorrowerEditorComponent } from 'src/app/modules/borrower/components/borrower-editor/borrower-editor.component';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from 'src/app/shared/services/drawer.service';
import { AppDetailsService } from '../../../services/app-details.service';
import Swal from 'sweetalert2';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'loan-borrowers',
  templateUrl: './loan-borrowers.component.html',
  styleUrls: ['./loan-borrowers.component.scss']
})
export class LoanBorrowersComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {


  @ViewChild('editAppBorrowerDrawerContentRef')
  borrowerEditorDrawer: DrawerComponent;

  @ViewChild('popoverBorrowerInfo', { static: false }) popoverBorrowerInfo: PopoverDirective;

  @Input()
  actionButtonsVisible: boolean = false;

  @Input()
  title = "Borrowers";

  @Input()
  isLoanReadOnly: boolean = false;

  private _borrowers: Borrower[] = [];
  get borrowers(): Borrower[] {
    return this._borrowers;
  }
  @Input() set borrowers(value: Borrower[]) {
    this._borrowers = value;
    this.canDeleteBorrower = value.length > 1;
  }

  @Output()
  deleted: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  updated: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  updatedPrimaryBorrower: EventEmitter<{ application: LoanApplication, borrowers: Borrower[] }> = new EventEmitter<any>();

  editAppBorrowerDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null
  }

  applicationMode: string;

  portalMergeHover: number = -1;

  dialerEnabled: boolean = false;

  protected applicationId: number;
  /**
   * Whether the user can delete a borrower.
   * There must be at least two borrowers to delete one.
   */
  protected canDeleteBorrower: boolean = false;
  protected disableBorrowerPortalInviteLink: boolean = false;

  private _borrowerSavedEventSubscription: Subscription;
  private _borrowerEditCancelledEventSubscription: Subscription;

  constructor(
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _drawerService: DrawerService,
    private readonly _navigationService: NavigationService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _configurationService: ConfigurationService,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.applicationMode = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
      this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';

    if (this.applicationContext.application) {
      this.initialize(this.applicationContext);
    }
    this.applicationContextService.loanInfoChanges.subscribe(applicationContext => {
      if (applicationContext.application) {
        this.initialize(applicationContext);
      }
    })
  }

  ngOnDestroy(): void {
    if (this._borrowerSavedEventSubscription) {
      this._borrowerSavedEventSubscription.unsubscribe();
    }
    if (this._borrowerEditCancelledEventSubscription) {
      this._borrowerEditCancelledEventSubscription.unsubscribe();
    }
  }

  getBorrowerFullName = (borrower: Borrower): string => {
    return Utils.getBorrowerFullName(borrower);
  }

  openBorrowerDetails = (borrowerId: number) => {
    if (!this.applicationId) return;

    const selectedBorrower = this._borrowers.find(b => b.borrowerId == borrowerId);
    const dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = BorrowerEditorComponent;
    dynamicComponentInfo.parameters.set("borrowerId", borrowerId);
    dynamicComponentInfo.parameters.set("loanId", this.applicationId);
    this._drawerService.show("editAppBorrowerDrawer", 100, 'Editing Borrower - ' +
      (selectedBorrower
        ? this.getBorrowerFullName(selectedBorrower)
        : ''), dynamicComponentInfo)
      .then(() => {
        this._borrowerSavedEventSubscription = this.borrowerEditorDrawer.componentInstance.borrowerSaved.subscribe((updatedBorrower) => {
          this.onBorrowerSaved(updatedBorrower);
        });
        this._borrowerEditCancelledEventSubscription = this.borrowerEditorDrawer.componentInstance.borrowerEditCancelled.subscribe(() => {
          this.onBorrowerEditCancelled();
        });
      });
  }

  onBorrowerEditCancelled = () => {
    this._drawerService.hide("editAppBorrowerDrawer", 10);
  }

  onBorrowerSaved = (updatedBorrower: Borrower) => {
    const updatedBorrowerIndex = this._borrowers.findIndex(b => b.borrowerId === updatedBorrower.borrowerId);
    if (updatedBorrowerIndex >= 0) {
      this._borrowers[updatedBorrowerIndex] = {
        ...this._borrowers[updatedBorrowerIndex],
        ...updatedBorrower
      };
    }
    this.updated.emit(this._borrowers);
    this._drawerService.hide("editAppBorrowerDrawer", 10);
  }

  onBorrowerEditorClosed = () => {
    if (this._borrowerSavedEventSubscription) {
      this._borrowerSavedEventSubscription.unsubscribe();
    }
    if (this._borrowerEditCancelledEventSubscription) {
      this._borrowerEditCancelledEventSubscription.unsubscribe();
    }
  }

  mergePortal = (borrowerId: number, portalMerged: boolean) => {
    this._spinner.show();
    this._appDetailsService.setAllowPrimaryToSatisfy(this.applicationId, borrowerId, portalMerged).subscribe(result => {
      let borrower = this._borrowers.find(b => b.borrowerId === borrowerId);
      if (borrower) {
        borrower.isPortalMerged = portalMerged;
      }
      this.updated.emit(this._borrowers);
      this._notifyService.showSuccess(
        'Borrower merged succesfully.',
        'Success!'
      );
    }, (error) => {
      this._notifyService.showError(
        error ? error.message : 'An error occurred merging the borrower.',
        'Error!'
      );
    }).add(() => {
      this._spinner.hide();
    });
  }

  setBorrowerAsPrimary = (borrowerId: number) => {
    this._spinner.show();
    this._appDetailsService.setBorrowerPrimaryLoan(this.applicationId, borrowerId).subscribe(result => {
      this._borrowers.forEach(b => {
        b.isPrimary = (b.borrowerId == borrowerId);
      });
      this._borrowers.sort((a, b) => {
        if (a.isPrimary) {
          return -1;
        }
        if (b.isPrimary) {
          return 1;
        }
      });
      this.updatedPrimaryBorrower.emit({ application: result, borrowers: this._borrowers });
      this._spinner.hide();
      this._notifyService.showSuccess(
        'Borrower is set as primary.',
        'Success!'
      );
    },
      (error) => {
        this._spinner.hide();
        this._notifyService.showError(
          error ? error.message : 'Unable to set loan as Primary',
          'Error!'
        );
      });
  }

  protected onDeleteBorrowerClicked(index: number) {
    return this.showDeleteBorrowerConfirmation(index);
  }

  private async showDeleteBorrowerConfirmation(borrowerIndex: number) {
    // "Cancel" is used as "confirm" here because the colors are more suitable.

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this borrower?',
      showCancelButton: true,
      cancelButtonText: 'Delete',
      confirmButtonText: 'Cancel',
      focusCancel: true, // "Confirm", actually.
      icon: 'question',
    });

    // "Cancel" actually means "confirm" here.
    if (result?.dismiss !== Swal.DismissReason.cancel) {
      return;
    }

    const borrower = this._borrowers[borrowerIndex];

    await this._spinner.show();

    try {
      await firstValueFrom(
        this._appDetailsService.removeBorrower(
          this.applicationId,
          borrower.borrowerId,
        ),
      );

      this._borrowers.splice(borrowerIndex, 1);
      this.borrowers = this._borrowers; // Update canDeleteBorrower.
      this.deleted.emit(this._borrowers);
    } catch (e) {
      const message = e?.message
        || `An error occurred deleting the borrower ${borrower.borrowerId}.`;
      console.error(message, e);

      this._notifyService.showError(message, 'Error!');
    } finally {
      await this._spinner.hide();
    }
  }

  private initialize = (applicationContext: ApplicationContext) => {
    if (applicationContext.application) {
      this.applicationId = applicationContext.application.applicationId;
      this.borrowers = cloneDeep(this.applicationContext.borrowers);
      this.populateDisabledBorrowerInviteChannels(applicationContext);
    }
    this.dialerEnabled = applicationContext.userPermissions.dialerEnabled;
  }

  protected getAddress1String(borrower: Borrower): string | undefined {
    return borrower.mailingStreet || undefined;
  }

  protected getAddress2String(borrower: Borrower): string | undefined {
    const city = borrower.mailingCity;

    const state = borrower.mailingState?.toUpperCase();
    const zip = borrower.mailingZip;
    const stateZip = [state, zip].filter(Boolean).join(' ') || undefined;

    return [city, stateZip].filter(Boolean).join(', ') || undefined;
  }

  private populateDisabledBorrowerInviteChannels = (appCtx: ApplicationContext) => {
    this._spinner.show();
    this._configurationService.getCompanyConfiguration('BorrowerInviteDisabledChannels').subscribe({
      next: (borrowerInviteDisabledChannels) => {
        this._spinner.hide();

        if (borrowerInviteDisabledChannels?.valueStr?.trim()) {
          const channels = borrowerInviteDisabledChannels.valueStr?.split(',');
          if (!channels) {
            return;
          }

          let disabledBorrowerInviteChannels = [];
          channels.forEach(disabledChannel => {
            const companyChannels = appCtx.globalConfig.enabledChannels;

            const channel = companyChannels.find(c => c.name == disabledChannel);
            if (channel) {
              disabledBorrowerInviteChannels.push(channel.name);
            }
          });

          if (disabledBorrowerInviteChannels.length && disabledBorrowerInviteChannels.includes(appCtx.application.channel)) {
            this.disableBorrowerPortalInviteLink = true;
          }
        }
      },
      error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message || 'Unable to get system level.','Error!');
      }
    })

  }
}
