import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NewApplicationService } from '../../../services/new-application.service';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ExternalCompany, Role } from 'src/app/models';
import { User } from 'src/app/models/user/user.model';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { finalize } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import * as _ from 'lodash';
import { AlignmentRoleUsers } from '../../../models/process-submission.model';

@Component({
  selector: 'import-mismo',
  templateUrl: './import-mismo.component.html',
  styleUrls: ['./import-mismo.component.scss']
})
export class ImportMismoComponent implements OnInit {
  @ViewChild('importMismoForm') importMismoForm: NgForm | undefined;

  mismoFiles: FileList;

  @Input()
  globalConfig: GlobalConfig;

  @Input()
  isAdminUser: boolean;

  @Input()
  isTpoUser: boolean;

  @Input()
  enabledChannels: Array<EnumerationItem>;

  @Input()
  externalCompanies: ExternalCompany[];

  @Input()
  availableUsers: User[];

  @Input()
  selectedChannel: string;

  @Input()
  selectedCompanyId: number | '';

  @Input()
  firstRole: Role;

  @Input()
  userCompanyGuid: string;

  @Input()
  sanitizeLoanData: boolean;

  @Input()
  tpoEnabledRoles: Role[] = [];

  @Output()
  processMismoFile = new EventEmitter();

  primaryRoleUserId: string;

  fileteredExternalCompanies: ExternalCompany[] = [];

  avaliableSecondaryRoles: Role[] = [];
  tpoAlignmentRoleUsers: AlignmentRoleUsers = {};

  availableRoleUsers: User[] = [];

  protected isProduction: boolean = false;

  constructor(
    private readonly _newApplicationService: NewApplicationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _environment: EnvironmentService) {
    this.isProduction = this._environment.production;
  }

  ngOnInit(): void {
    const userExist = this.availableUsers.findIndex(u => u.userCompanyGuid === this.userCompanyGuid) > -1;
    this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';
    this.sanitizeLoanData = false;
    this.handleChannelChange(this.selectedChannel);
  }

  handleFileInput = (files: FileList) => {
    this.mismoFiles = files;
  }

  handleChannelChange = (newVal: string) => {
    this.selectedChannel = newVal;
    this.selectedCompanyId = '';

    this.fileteredExternalCompanies = _.sortBy(this.externalCompanies.filter(c => c.enabledChannels.indexOf(this.selectedChannel) > -1), 'name', 'asc');

    if (newVal == 'Wholesale' || newVal == 'Correspondent' || newVal == 'NonDelegatedCorrespondent') {
      // this.availableUsers = [];
      this.availableRoleUsers = [];
      this.primaryRoleUserId = '';

    }

    this.firstRole = this.getFirstRoleByChannel(newVal);

    if (this.firstRole) {
      this.avaliableSecondaryRoles = this.tpoEnabledRoles.filter(r => r.roleId != this.firstRole.roleId && r.order != 1 &&
        r.roleChannels.map(rc => rc.channel.toLocaleLowerCase()).includes(this.selectedChannel.toLowerCase()));
    }

    if (newVal != 'Wholesale') {
      this.availableRoleUsers = _.cloneDeep(this.availableUsers);
    }

    const userExist = this.availableUsers.findIndex(u => u.userCompanyGuid === this.userCompanyGuid) > -1;
    this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';

    if (this.fileteredExternalCompanies.length == 1) {
      this.selectedCompanyId = this.fileteredExternalCompanies[0].externalCompanyId;
      this.handleCompanyChange(this.selectedCompanyId);
    }
  }

  handleCompanyChange = (externalCompanyId: number) => {
    this.selectedCompanyId = externalCompanyId;
    if (!this.selectedCompanyId) {
      this.availableRoleUsers = [];
      return;
    }
    if (this.isTpoUser) {
      this.availableRoleUsers = this.availableUsers.filter(x => x.externalCompanyId == this.selectedCompanyId);
      const userExist = this.availableUsers.findIndex(u => u.userCompanyGuid === this.userCompanyGuid) > -1;
      this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';
      return;
    }
    this._spinner.show();
    this._newApplicationService.getExternalCompanyUsers(this.selectedCompanyId)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: (response) => {
          this.availableRoleUsers = response || [];
          const userExist = this.availableUsers.findIndex(u => u.userCompanyGuid === this.userCompanyGuid) > -1;
          this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';
        }, error: (err) => {
          this.primaryRoleUserId = '';
          this._notificationService.showError(err?.message || "Couldn't get users for selected company", "Error")
        }
      });
  }

  onPrimaryContactChanged = (userId) => {
    this.primaryRoleUserId = userId;
  }

  onSecondaryContactChanged = (alignmentUsers: AlignmentRoleUsers) => {
    this.tpoAlignmentRoleUsers = alignmentUsers;
  }

  processFile = () => {
    this.importMismoForm.form.markAllAsTouched();
    if (this.importMismoForm.form.valid) {
      var data = {
        channel: this.selectedChannel,
        mismoFiles: this.mismoFiles,
        primaryRoleUserId: this.primaryRoleUserId,
        sanitizeLoanData: this.sanitizeLoanData,
        additionalAlignmentRoleUsers: this.tpoAlignmentRoleUsers
      }
      this.processMismoFile.emit(data);
    }
  }

  private getFirstRoleByChannel = (channel: string) => {
    if (!channel) {
      return this.globalConfig.firstRole;
    }
    return this.globalConfig.channelRoles[this.lowerFirst(channel)][0];
  }

  private lowerFirst =(str) => {
    return str[0].toLowerCase() + str.slice(1);
}
}
