import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Utils } from 'src/app/core/services/utils';
import { Income } from 'src/app/models/mortgage.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaFieldsConfigBoundComponent } from 'src/app/shared/components/urla-fields-config-bound.component';
import { PayPeriod, PayPeriodUtils } from 'src/app/shared/utils/pay-period/pay-period.utils';

@Component({
  selector: 'income-dialog',
  templateUrl: 'income-dialog.component.html',
  styleUrls: ['income-dialog.component.scss']
})
export class IncomeDialogComponent extends UrlaFieldsConfigBoundComponent implements OnInit {

  @ViewChild("incomeForm")
  incomeForm: NgForm;

  @Input()
  incomeTypes: EnumerationItem[] = [];
  incomePayPeriods: EnumerationItem[] = [];

  @Input()
  isFromIncomeAnalysis: boolean;

  title: string = "";

  editOnly: boolean = false;

  protected verificationStatusTypes: EnumerationItem[] = [];
  protected yesNoOptions: EnumerationItem[] = [];

  private _incomes: Income[] = [];

  set incomes(incomes: Income[]) {
    this._incomes = _.cloneDeep(incomes);
    this._incomes.forEach(i => {
      i.amount = i.monthlyIncome;
      i.payPeriod = PayPeriod.Monthly;
      if (!i.incomeId) {
        i.incomeId = Utils.getUniqueId();
      }
    })
    if (this._incomes.length === 0) {
      let income = new Income();
      income.payPeriod = PayPeriod.Monthly;
      if (!income.incomeId) {
        income.incomeId = Utils.getUniqueId();
      }
      this._incomes.push(income);
    }
    this.calculateTotalIncome();
  }

  get incomes(): Income[] {
    return this._incomes;
  }

  activeDeletionIndex: number = -1;

  totalIncome: number = 0;

  protected readonly PayPeriod = PayPeriod;

  constructor(
    private readonly injector: Injector,
    private readonly _enumsService: EnumerationService,
    public activeModal: NgbActiveModal,
  ) {
    super(injector);

    this.incomePayPeriods = this._enumsService.getIncomePayPeriods();
    this.yesNoOptions = this._enumsService.getYesNoEnumItems();
  }

  onOkClicked = () => {
    if (this.incomeForm) {
      this.incomeForm.form.markAllAsTouched();
      if (this.incomeForm.form.valid) {
        this.activeModal.close(this._incomes);
      }
    }
  }

  ngOnInit() {
    if (this.incomeTypes) {
      this.incomeTypes = _.orderBy(this.incomeTypes, x => x.name);
    }

    if (this.incomes && this.isFromIncomeAnalysis) {
      this.incomes.forEach(i => i.payPeriod = this.incomePayPeriods[0].value);
    }

    this.verificationStatusTypes = this._enumsService.verificationStatuses;
    this.incomeTypes = _.orderBy(this.incomeTypes, ['name'], ['asc']);
  }

  onDeleteIncomeClicked = (index: number) => {
    this.activeDeletionIndex = index;
  }

  onDeleteIncomeConfirmClicked = (income: Income) => {
    this.activeDeletionIndex = -1;
    const index = this.incomes.indexOf(income);
    if (index >= 0) {
      this.incomes.splice(index, 1);
      this.calculateTotalIncome();
    }
  }

  onDeleteIncomeAbortClicked = () => {
    this.activeDeletionIndex = -1;
  }

  onAmountChanged = (income: Income) => {
    income.monthlyIncome = PayPeriodUtils.calculateMonthlyAmountByPayPeriod(
      income.amount,
      income.payPeriod as PayPeriod,
      income.hoursPerWeek,
    );
    this.calculateTotalIncome();
  }

  onTypeChanged = (income: Income) => {
    if(!this.hasMCCTypedIncome(income)){
      income.mccIncomeIsMappedAsTaxCredit = undefined;
    }
  }

  onAddIncomeClicked = () => {
    let income = new Income();
    if (this.isFromIncomeAnalysis) {
      income.payPeriod = this.incomePayPeriods[0].value
    }
    income.incomeId = Utils.getUniqueId();
    this.incomes.push(income);
    this.calculateTotalIncome();
  }

  protected hasMCCTypedIncome = (i: Income) => {
    return  ['FNMGovernmentMortgageCreditCertificate', 'MortgateCreditCertificate'].includes(i.typeOfIncome as any);
  }

  protected haveMCCTypedIncomesControl = () => {
    return this.incomes.filter(this.hasMCCTypedIncome).length;
  }

  private calculateTotalIncome = () => {
    this.totalIncome = this.incomes.map(a => a.monthlyIncome).reduce(function (a, b) {
      if (!a) a = 0;
      if (!b) b = 0;
      return a + b;
    });
  }
}
