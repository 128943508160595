import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs/internal/Subscription';
import { LoanApplication, Mortgage } from 'src/app/models';
import { BuydownEnum, MiQuoteSearchCriteria, MiQuoteSearchRequest } from 'src/app/models/mi-quote-search-request.model';
import { MiQuoteDetail, MiQuoteSearchResult } from 'src/app/models/mi-quote-search-result.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { MiQuoteService } from 'src/app/services/mi-quote.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { MiQuoteSearchParametersComponent } from '../mi-quote-search-parameters/mi-quote-search-parameters.component';
import { MiQuoteSearchResultsComponent } from '../mi-quote-search-results/mi-quote-search-results.component';
import { SelectedMiQuoteInfo } from '../mi-quote-search-dialog/mi-quote-search-dialog.component';
import { ProductSearchRequest } from '../../models/pricing/product-search-request-info.model';

@Component({
  selector: 'mi-quote-search',
  templateUrl: './mi-quote-search.component.html',
  styleUrls: ['./mi-quote-search.component.scss']
})
export class MiQuoteSearchComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('quoteSearchParameters')
  quoteSearchParameters: MiQuoteSearchParametersComponent | undefined;

  @ViewChild('searchResults')
  searchResults: MiQuoteSearchResultsComponent | undefined;

  @Input()
  interestRate: number;

  @Input()
  viewType: number = 2; // 1: Grid, 2: Tabular

  @Input()
  pricingRequest: ProductSearchRequest;

  @Output()
  quotedDetailSelected: EventEmitter<MiQuoteDetail> = new EventEmitter();

  @Output()
  quotedDetailSelectedAfterReRun: EventEmitter<SelectedMiQuoteInfo> = new EventEmitter();

  quoteSearchRequestInfo: MiQuoteSearchRequest = null;
  currentApplication: LoanApplication = null;
  currentMortgage: Mortgage = null;

  tab: 'quoteParameters' | 'quoteResults' = 'quoteParameters';

  parameterPanelFullScreen: boolean = false;

  channels: EnumerationItem[];

  quoteRunResult: MiQuoteSearchResult | undefined;

  isSubmitted: boolean = false;
  isSearching: boolean = false;
  isTPO: boolean = false;

  private _contextChangeSubscription: Subscription;
  private _loanInfoChangesSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _miQuoteService: MiQuoteService,
    private readonly _notifsService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService

  ) {
    super(injector);
    this.isTPO = this.applicationContext.isTpo;
  }

  ngOnInit(): void {
    this._contextChangeSubscription = this.applicationContextService.context.subscribe(context => {
      this.currentApplication = context.application;
      this.currentMortgage = context.currentMortgage;
      this.channels = context.globalConfig.enabledChannels;
      this.initialize();
    });

    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(context => {
      this.currentApplication = context.application;
      this.currentMortgage = context.currentMortgage;
      this.channels = context.globalConfig.enabledChannels;
      this.initialize();
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._contextChangeSubscription) {
      this._contextChangeSubscription.unsubscribe();
    }
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  selectTab = (tab: any) => {
    this.tab = tab;
  }

  initialize = () => {

    if (this.currentMortgage) {
      this._miQuoteService.getRequest(this.currentMortgage.mortgageId).subscribe({
        next: (req: MiQuoteSearchRequest) => {
          req.loanInformation.interestRate = this.interestRate;
          req.loanInformation.buydown = this.pricingRequest.buydown as BuydownEnum;
          req.loanInformation.loanNumber = this.pricingRequest.applicationId ? this.pricingRequest.applicationId.toString() : "QUICKPRICE";

          this.quoteSearchRequestInfo = req;
          if (!req.searchCriteria) {
            this.quoteSearchRequestInfo.searchCriteria = new MiQuoteSearchCriteria();
          }
        },
        error: (err) => {
          this._notifsService.showError(err.message || err,"Error");
        }
      })
    }
    else {
      let reqest = this._miQuoteService.getDefaultQuoteSearchRequest();
      reqest.loanInformation.interestRate = this.interestRate;
      reqest.loanInformation.buydown = this.pricingRequest.buydown as BuydownEnum;
      reqest.loanInformation.loanNumber = this.pricingRequest.applicationId ? this.pricingRequest.applicationId.toString() : "QUICKPRICE";

      this.quoteSearchRequestInfo = reqest;
    }

  }

  onSubmitClicked = () => {
    this.quoteRunResult = undefined;
    if (this.quoteSearchParameters) {
      const valid = this.quoteSearchParameters.validate();
      if (valid) {
        this.isSubmitted = true;

        const request = this.fixPercentageValues(this.quoteSearchRequestInfo);
        this._spinnerService.show();
        this.isSearching = true;

        this._miQuoteService.search(request).subscribe({
          next: (res) => {
            this.quoteRunResult = res;

            this.isSubmitted = false;
            if (this.viewType == 2) {
              this.selectTab("quoteResults");
            }
          },
          error: (error) => {
            this.isSubmitted = false;
            let errorMessage = "Unable to search Quotes";
            if (error) {
              if (typeof error === 'string') {
                errorMessage = error;
              } else {
                errorMessage = error.message || error.error;
              }
            }
            this._notifsService.showError(
              errorMessage,
              'Error!'
            );
          }
        })
        .add(() => {
          this.parameterPanelFullScreen = false;
          this._spinnerService.hide();
          this.isSearching = false;
        });
      }
    }
  }

  onQuoteSelected = (quote: MiQuoteDetail) => {
    this.quotedDetailSelected.emit(quote);
  }

  onQuoteSelectedAfterReRun = (data: SelectedMiQuoteInfo) => {
    this.quotedDetailSelectedAfterReRun.emit(data);
  }

  private fixPercentageValues = (quoteSearchRequest: MiQuoteSearchRequest) => {
    let request = _.cloneDeep(quoteSearchRequest);
    let borrowerInformation = { ...request.borrowerInformation };
    let loanInformation = { ...request.loanInformation };
    request.borrowerInformation = borrowerInformation;
    request.loanInformation = loanInformation;

    request.borrowerInformation.debtToIncomeRatio = parseFloat((request.borrowerInformation.debtToIncomeRatio * 100).toFixed(3));
    request.borrowerInformation.housingExpenseRatioPercent = parseFloat((request.borrowerInformation.housingExpenseRatioPercent * 100).toFixed(3));
    request.searchCriteria.coveragePercentage = parseFloat((request.searchCriteria.coveragePercentage * 100).toFixed(3));
    request.loanInformation.interestRate = parseFloat((request.loanInformation.interestRate * 100).toFixed(3));
    return request;
  }


}
