import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PendingChangesGuard } from '../../core/route-guards/pending-changes.guard';
import { AddBorrowerComponent } from '../borrower/components/add-borrower/add-borrower.component';
import { SelectBorrowerComponent } from '../borrower/components/select-borrower/select-borrower.component';
import { ConditionsTasksComponent } from '../conditions-tasks/conditions-tasks.component';
import { LoanFeesComponent } from '../fees/loan-fees.component';
import { FileContactsComponent } from '../file-contacts/components/file-contacts.component';
import { InternalContactsComponent } from '../internal-contacts/components/internal-contacts.component';
import { AusComponent, TwnComponent, VoaComponent } from '../loan-services';
import { AppraisalComponent } from '../loan-services/appraisal/components/appraisal/appraisal.component';
import { LoanCreditComponent } from '../loan-services/loan-credit/components/loan-credit/loan-credit.component';
import { RemainContactComponent } from '../remain-contact/components/remain-contact/remain-contact.component';
import { AdminLoanSummaryComponent } from './components/admin-loan-summary/admin-loan-summary.component';
import { AppDetailsV2Component } from './components/app-details-v2/app-details-v2.component';
import { DealStructureComponent } from './components/deal-structure/deal-structure.component';
import { DocumentPreparationComponent } from './document-preparation/components/document-preparation.component';
import { ExternalContactsV2Component } from '../external-contacts-v2/components/external-contacts-v2/external-contacts-v2.component';
import { ReggoraComponent } from '../loan-services/reggora/components/reggora/reggora.component';
import { AppraisalManagementComponent } from '../loan-services/appraisal/components/appraisal-management/appraisal-management.component';
import { SubmissionComponent } from '../submission';

const routes: Routes = [
  {
    path: '',
    canActivate: [],
    component: AppDetailsV2Component,
    children: [
      { path: '', redirectTo: 'loan-summary', pathMatch: 'full' },
      { path: 'file-contacts', component: FileContactsComponent },
      { path: 'doc-preparation', component: DocumentPreparationComponent },
      { path: 'submission', component: SubmissionComponent },
      { path: 'internal-contacts', component: InternalContactsComponent },
      { path: 'external-contacts', component: ExternalContactsV2Component },
      { path: 'associate-file-contact', component: RemainContactComponent },
      // { path: 'credit-reports', component: CreditReportsComponent },
      { path: 'credit-reports', component: LoanCreditComponent },
      { path: 'digital-emp-income-verification', component: TwnComponent },
      { path: 'digital-asset-verification', component: VoaComponent },
      { path: 'aus', component: AusComponent },
      { path: 'appraisal', component: AppraisalComponent },
      { path: 'appraisal-management', component: AppraisalManagementComponent },
      {
        path: 'disclosures', loadChildren: () =>
          import('../../modules/loan-services/run-disclosures/run-disclosures.module').then((m) => m.RunDisclosuresModule),
      },
      { path: 'fees', component: LoanFeesComponent, canDeactivate: [PendingChangesGuard] },
      { path: 'escrow-schedule', component: LoanFeesComponent },
      { path: 'reggora', component: ReggoraComponent },
      {
        path: 'pricing',
        loadChildren: () =>
          import('../../modules/pricing/pricing.module').then((m) => m.PricingModule),
      },
      {
        path: 'deal-structure',
        canDeactivate: [PendingChangesGuard],
        component: DealStructureComponent,
      },
      {
        path: 'quick-apply',
        loadChildren: () =>
          import('../app-details/modules/quick-apply/quick-apply.module').then((m) => m.QuickApplyModule),
      },
      { path: 'conditions', component: ConditionsTasksComponent },
      { path: 'select-borrower', component: SelectBorrowerComponent },
      { path: 'add-borrower', component: AddBorrowerComponent },
      {
        path: 'loan-docs',
        loadChildren: () =>
          import('../../modules/loan-docs/loan-docs.module').then((m) => m.LoanDocsModule),
      },
      {
        path: 'loan-docs-v2',
        loadChildren: () =>
          import('../../modules/loan-docs-v2/loan-docs-v2.module').then((m) => m.LoanDocsV2Module),
      },
      {
        path: 'urla',
        loadChildren: () =>
          import('../../modules/urla/urla.module').then((m) => m.UrlaModule),
      },
      {
        path: 'co-browse',
        loadChildren: () =>
          import('../../modules/co-browse/co-browse.module').then((m) => m.CoBrowseModule),
      },
      {
        path: 'loan-summary',
        canDeactivate: [PendingChangesGuard],
        component: AdminLoanSummaryComponent,
      },
      {
        path: 'tax-transcripts',
        loadChildren: () =>
          import('../../modules/disclosure-documents/disclosure-documents.module').then((m) => m.DisclosureDocumentsModule),
      },
      {
        path: 'coc-details',
        loadChildren: () =>
          import('../../modules/coc-details/coc-details.module').then((m) => m.CocDetailsModule),
      },
      {
        path: 'tolerance-cures',
        loadChildren: () =>
          import('../../modules/tolerance-cures/tolerance-cures.module').then((m) => m.ToleranceCuresModule),
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppDetailsRoutingModule { }
