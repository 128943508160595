<form class="form-horizontal auth-form" #importMismoForm="ngForm" novalidate id="importMismoForm" name="importMismoForm"
    action="#">
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">Please upload a valid MISMO 3.4 file to start your submission</h4>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12">
                    <div class="mb-3 row">
                        <input type="file" id="import-mismo-input-file-xml" class="dropify" accept=".xml"
                            files="mismoFiles" (change)="handleFileInput($event.target.files)" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card" *ngIf="mismoFiles && mismoFiles.length > 0 && enabledChannels.length > 0">
        <div class="card-header">
            <h4 class="card-title">Choose the Channel</h4>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group mb-3 row">
                        <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Channel</label>
                        <div class="col-sm-10" *ngIf="!isTpoUser">
                            <select class="form-select" [(ngModel)]="selectedChannel"
                                (change)="handleChannelChange(selectedChannel)" id="import-mismo-channel"
                                [ngClass]="{'is-invalid' : channelField && channelField.touched && channelField.invalid}"
                                #channelField="ngModel" name="channelField" required>
                                <option value="" selected> -- Select One -- </option>
                                <option *ngFor="let item of enabledChannels" value="{{item.name}}">
                                    {{item.name}}</option>
                            </select>
                        </div>
                        <div class="col-sm-10" *ngIf="isTpoUser">
                            <select class="form-select" [(ngModel)]="selectedChannel"
                                (change)="handleChannelChange(selectedChannel)" id="import-mismo-channel"
                                [ngClass]="{'is-invalid' : channelField && channelField.touched && channelField.invalid}"
                                #channelField="ngModel" name="channelField" required>
                                <option value=""> -- Select One -- </option>
                                <option *ngFor="let item of enabledChannels" value="{{item.name}}">
                                    {{item.name == 'Wholesale' ? 'Brokered' : item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12"
                    *ngIf="mismoFiles && mismoFiles.length > 0 && (selectedChannel === 'Wholesale' || selectedChannel === 'Correspondent' || selectedChannel === 'NonDelegatedCorrespondent')">
                    <div class="mb-3 row">
                        <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Company</label>
                        <div class="col-sm-10">
                            <p-dropdown id="import-mismo-company" name="import-mismo-company"
                                [options]="fileteredExternalCompanies" [(ngModel)]="selectedCompanyId"
                                [showClear]="true" [filter]="true" optionLabel="name"
                                optionValue="externalCompanyId" (onChange)="handleCompanyChange(selectedCompanyId)"
                                [required]="selectedChannel === 'Wholesale'" placeholder="-- Select One --">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="card" *ngIf="firstRole && mismoFiles && mismoFiles.length > 0">
        <div class="card-header">
            <h4 class="card-title">Choose the Contacts</h4>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12">
                    <role-based-internal-contacts 
                        [hidden]="mismoFiles.length == 0"
                        [selectedCompanyId]="selectedCompanyId" 
                        [selectedChannel]="selectedChannel"
                        [roleUsers]="availableRoleUsers" 
                        [firstRole]="firstRole"
                        [secondaryRoles]="avaliableSecondaryRoles" 
                        [isTpoUser]="isTpoUser" 
                        [loadingUsers]="false"
                        [primaryRoleUserId]="primaryRoleUserId" 
                        [primaryContactIsRequired]="true"
                        (primaryRoleContactChanged)="onPrimaryContactChanged($event)"
                        (secondaryRoleContactChanged)="onSecondaryContactChanged($event)">
                    </role-based-internal-contacts>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center" *ngIf="isAdminUser && !isProduction">
        <div style="display: inline-block;" class="pt-2 pb-3">
            <div class="form-check text-start">
                <input type="checkbox" class="form-check-input" name="sanitizeLoanData" id="sanitizeLoanData"
                    [(ngModel)]="sanitizeLoanData">
                <label class="form-check-label" for="sanitizeLoanData">Sanitize Loan Data</label>
            </div>
        </div>
    </div>
    <div class="text-center">
        <button id="import-mismo-proceed-btn" class="btn btn-primary"
            [disabled]="!mismoFiles || (mismoFiles && mismoFiles.length === 0)" (click)="processFile()">Process MISMO
            File</button>
    </div>
</form>