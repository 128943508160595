<div class="modal-header">
  <h5 *ngIf="inEditMode" class="modal-title"> Edit Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <h5 *ngIf="!inEditMode" class="modal-title"> Create Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #pollyForm="ngForm" novalidate id="pollyForm">
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Username </label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" [(ngModel)]="vendor.userName" #userNameInput="ngModel" name="userName"
          id="userName" [ngClass]="{'is-invalid': userNameInput && userNameInput.touched && userNameInput.invalid}"
          required>
        <div class="invalid-feedback"> This field is required </div>
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Password </label>
      </div>
      <div class="col-md-4">
        <password-input [(ngModel)]="vendor.password" [name]="vendor.vendorName" required="true"></password-input>
      </div>
    </div>
    <div *ngIf="!['User', 'TpoUser', 'Branch', 'ExternalCompanyBranch'].includes(scope)" class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Client Id </label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" [(ngModel)]="pollyKeyValuePairs.clientId" #clientIdInput="ngModel"
          name="client-id" [ngClass]="{'is-invalid': clientIdInput && clientIdInput.touched && clientIdInput.invalid}"
          required />
        <div class="invalid-feedback"> This field is required </div>
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Client Secret </label>
      </div>
      <div class="col-md-4">
        <password-input [(ngModel)]="pollyKeyValuePairs.clientSecret" name="clientSecret"
          required="true"></password-input>
      </div>
    </div>
    <div *ngIf="!['User', 'TpoUser', 'Branch', 'ExternalCompanyBranch'].includes(scope)" class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Ticker Symbol </label>
      </div>
      <div class="col-md-4">
        <input type="text" name="ticket-symbol" class="form-control text-uppercase"
          [(ngModel)]="pollyKeyValuePairs.tickerSymbol" maxlength="3" />
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Url </label>
      </div>
      <div class="col-md-4">
        <input type="text" name="url-field" class="form-control" [(ngModel)]="vendor.url" #urlInput="ngModel"
          [ngClass]="{'is-invalid': urlInput && urlInput.touched && urlInput.invalid}" required />
        <div class="invalid-feedback"> This field is required </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="vendor?.credentialId">
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <button type="button" class="btn btn-primary me-2" (click)="onRepriceFieldsClicked()">
          Field Editor
        </button>
      </div>
    </div>
    <div class="row pt-4">
      <div class="col-md-12 ">
        <div class="fieldset-title">
          <div class="row">
            <div class="col-md-2 pt-2">Business Channels</div>
            <div class="col-md-4 text-start">
              <button class="btn btn-outline-primary" (click)="addBusinessChannel()">
                Add Business Channels
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!businessChannels?.length" class="row pt-3 text-center">
      <em>There are no business channels added. Please click add button to add one.</em>
    </div>
    <div class="row pt-3" *ngFor="let item of businessChannels; let i = index;  trackBy: trackByIndex">
      <ng-container *ngIf="!['User', 'TpoUser', 'Branch', 'ExternalCompanyBranch'].includes(scope)">
        <div class="col-md-2 text-end">
          <label class="custom-control-label">Name</label>
        </div>
        <div class="col-md-4">
          <input type="text" class="form-control" name="business-channel-name-{{i}}" [(ngModel)]="item.key"
            #businessChannelField="ngModel"
            [ngClass]="{'is-invalid': businessChannelField && businessChannelField.touched && businessChannelField.invalid}"
            required />
        </div>
        <div class="col-md-2 text-end">
          <label class="custom-control-label">AudienceId ID</label>
        </div>
        <div class="col-md-3">
          <input type="text" class="form-control" name="business-audienceId-id-{{i}}" [(ngModel)]="item.value"
            #businessAudienceIdIdField="ngModel"
            [ngClass]="{'is-invalid': businessAudienceIdIdField && businessAudienceIdIdField.touched && businessAudienceIdIdField.invalid}"
            required />
        </div>
        <div class="col-md-1">
          <confirm-delete [list]="businessChannels" [index]="i"></confirm-delete>
        </div>
      </ng-container>
      <ng-container *ngIf="['User', 'TpoUser', 'Branch', 'ExternalCompanyBranch'].includes(scope)">
        <div class="col-md-2 text-end">
          <label class="custom-control-label">Channel</label>
        </div>
        <div class="col-md-4">
          <select class="form-select" [(ngModel)]="item.key" #businessChannelField="ngModel"
            name="business-channel-{{i}}"
            [ngClass]="{'is-invalid': (businessChannelField && businessChannelField.touched && businessChannelField.invalid)}"
            required>
            <option value="">-- Select One --</option>
            <ng-container *ngFor="let ch of availableBusinessChannels">
              <option [value]="ch.value"> {{ch.name}} </option>
            </ng-container>
          </select>
        </div>
        <div class="col-md-1">
          <confirm-delete [list]="businessChannels" [index]="i"></confirm-delete>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="!['User', 'TpoUser', 'Branch', 'ExternalCompanyBranch'].includes(scope)">
      <div class="row pt-4">
        <div class="col-md-12 ">
          <div class="fieldset-title">
            <div class="row">
              <div class="col-md-2 pt-2">Custom Fields</div>
              <div class="col-md-4 text-start">
                <button class="btn btn-outline-primary" (click)="addCustomField()">
                  Add Custom Field
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-indicator *ngIf="loadingCustomFields" [loadingMessage]="'Loading Custom Fields...'"
        [customClass]="'no-shadow'"></loading-indicator>
      <pricing-custom-fields *ngIf="!loadingCustomFields" [customFields]="customFields"></pricing-custom-fields>
    </ng-container>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save </span>
  </button>
</div>
