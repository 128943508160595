import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as firebase from "firebase/messaging";
import { Messaging } from 'firebase/messaging';
import { cloneDeep, isArray } from 'lodash';
import { filter, take } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { ApplicationContext, UserPermissions, UserType } from 'src/app/models';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { FirebaseMessageService } from './firebase-message-service';
import { FirebaseServicesService } from './firebase-services.service';

@Injectable()
export class FirebaseService {
  constructor(
    private readonly _firebaseApiService: FirebaseServicesService,
    private readonly _firebaseMessageService: FirebaseMessageService,
    private readonly _environmentService: EnvironmentService,
    private readonly _route: ActivatedRoute
  ) { }

  private messaging: Messaging
  private registrationId: string
  private combinedNotifications: any

  run = (context: ApplicationContext) => {
    try {
      this.messaging = firebase.getMessaging();
    } catch (error: any) {
      throw new Error(error)
    }

    if (Notification.permission === "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          this.run(context);
          return;
        }
        else {
          Swal.fire({
            title: 'Just Letting You Know',
            text: 'Since you have denied permission for Lodasoft to provide browser notifications, SMS Chat, Borrower Chat, and Various real-time Alerts will not function properly.',
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: 'OK',
            reverseButtons: false
          }).then((result: SweetAlertResult) => {

          });
        }
      });
    }
    else {
      firebase.getToken(this.messaging,
        {
          vapidKey: this._environmentService.firebase.vapidKey,
        }
      ).then((token: string) => {
        if (!token) {
          return console.error('[FIREBASE] no token received');
        }

        this.combinedNotifications = this._getNotifications(context.userPermissions);

        this._firebaseMessageService.context = context;
        this._firebaseApiService
          .registerDevice({ NewRegistrationId: token, ClientType: 1 })
          .subscribe(
            (res) => {
              this.registrationId = token;
              this._initForegroundMessages(this.messaging, context);

              navigator.serviceWorker.addEventListener('message', event => {
                if (!event.data) return;
                if (event.data.isFirebaseMessaging) return; // this comes from foreground

                // listen only for lodasoft notifications
                if (event.data.type !== 'lodasoftNotification') return;

                this._handleNotification(event.data.payload);
              });
            },
            (error) => {
              console.error(
                `[FIREBASE] ${error ? error.message : "Couldn't set token to the server"
                }`
              );
            }
          );
      })
        .catch((err) => {
          console.error(
            `[FIREBASE] ${err ? err.message : "Couldn't register to firebase"}`
          );
        });
    }
  };

  //** stop listening notifiations */
  disconnect() {
    if (this.messaging && this.registrationId) {
      this._firebaseApiService.disconnect(this.messaging, this.registrationId);
    }
  }

  /** set up all foreground notifications */
  private _initForegroundMessages = (
    messaging: firebase.Messaging,
    { userPermissions }: ApplicationContext
  ) => {
    firebase.onMessage(messaging, payload => this._handleNotification(payload));

    // check service worker pending notifications
    this._route.queryParams.pipe(
      take(1),
      filter(params => params["swNotifications"])
    ).subscribe(params => this._handlePendingBackgroundNotifications());
  };

  private _handlePendingBackgroundNotifications() {
    const indexedDb = indexedDB.open("Lodasoft", 4);

    indexedDb.onsuccess = (indexDbSucc: any) => {
      const db = indexDbSucc.target.result;
      const transaction = db.transaction("Notifications_v2", "readwrite");

      const store = transaction.objectStore("Notifications_v2");
      const query = store.getAll();
      store.clear();

      query.onerror = () => {
        store.clear();
      }

      query.onsuccess = (indexDbQuerySucc) => {
        if (!indexDbQuerySucc?.target?.result) return;

        const filteredBackgroundNotifications = this._filterPendingBackgroundNotifications(indexDbQuerySucc.target.result);
        filteredBackgroundNotifications.forEach(data => {
          this._handleNotification(data.payload);
        })
        store.clear();
      }

      transaction.oncomplete = () => {
        db.close();
      }
    };
  }

  _notificationsToNotDedupe: Array<number>;
  private _getNotifications(userPermissions: UserPermissions): {
    [key: number]: (payload: any) => void
  } {
    const isTpoUser = userPermissions.userType == UserType.Tpo;

    /** 'admin' notifications */
    const adminNotificationsList = this._firebaseMessageService.adminNotifications;

    /** shared notifications between 'tpo' and 'admin' */
    const sharedNotificationsList = this._firebaseMessageService.sharedNotifications;

    /** 'dialer' notifications */
    const isDialer = userPermissions.dialerEnabled && !isTpoUser
    const dialerNotificationsList = isDialer
      ? this._firebaseMessageService.dialerNotifications
      : {};

    /** 'ocr' notifications */
    const isOcrEnabler = userPermissions.dialerEnabled && !isTpoUser
    const ocrNotificationsList = isOcrEnabler
      ? this._firebaseMessageService.ocrNotifications
      : {};

      this._notificationsToNotDedupe = Object.keys(this._firebaseMessageService.dialerNotifications).map(x => Number.parseInt(x));


    return {
      ...adminNotificationsList,
      ...sharedNotificationsList,
      ...dialerNotificationsList,
      ...ocrNotificationsList,
    }
  }

  /** get specified notification and opens it out */
  private _handleNotification(payload) {

    const clearPayload = typeof payload === "string" ? JSON.parse(payload) : payload
    if (!clearPayload.data.notificationData) {
      console.error("NotificationData doesn't exist.");
      return null;
    }

    const isUnhanledNotification =
      !this.combinedNotifications[payload.data.notificationType] ||
      typeof this.combinedNotifications[payload.data.notificationType] !== 'function';

    if (isUnhanledNotification) {
      console.error(
        `Unhandled event of type '${payload.data.notificationType}'`
      );
      return null;
    }

    const data = JSON.parse(payload.data.notificationData);
    let copyPayloadData;

    if (payload.data.traceId) {
      if (payload.data.notificationType === "5" || payload.data.notificationType === "11" || payload.data.notificationType === "36" || payload.data.notificationType === "37") {
        const alertData = {
          traceId: payload.data.traceId,
          data: data,
        }
        copyPayloadData = cloneDeep(alertData);
      } else {
        if (typeof data === 'object' && !isArray(data)) {
          data.traceId = payload.data.traceId;
          copyPayloadData = cloneDeep(data);
        } else {
          copyPayloadData = cloneDeep({ data: data, traceId: payload.data.traceId });
        }
      }
    } else {
      copyPayloadData = data;
    }
    this.combinedNotifications[payload.data.notificationType](copyPayloadData);
  }

  private _filterPendingBackgroundNotifications(indexDbResult) {
    return indexDbResult.reduce((pv, cv) => {
      if (!cv.payload?.data?.notificationData) {
        return pv;
      }

      const existingIndex = pv.findIndex(prev => prev.payload.data.notificationType == cv.payload.data.notificationType);
      // add new notification to the queue
      if (existingIndex === -1 || this._notificationsToNotDedupe.indexOf(cv.payload.data.notificationType) > -1) {
        pv.push(cv);
        return pv;
      }

      let parsedNewNotificationOfTheSameTypeData = JSON.parse(cv.payload.data.notificationData);
      if (Array.isArray(parsedNewNotificationOfTheSameTypeData)) {
        parsedNewNotificationOfTheSameTypeData = parsedNewNotificationOfTheSameTypeData[0];
      }

      let parsedPrevNotificationOfTheSameTypeData = JSON.parse(pv[existingIndex].payload.data.notificationData);
      if (Array.isArray(parsedPrevNotificationOfTheSameTypeData)) {
        parsedPrevNotificationOfTheSameTypeData = parsedPrevNotificationOfTheSameTypeData[0];
      }

      // there may be no dateUpdated
      if (!parsedPrevNotificationOfTheSameTypeData.dateUpdated && !parsedNewNotificationOfTheSameTypeData.dateUpdated) {
        if (parsedPrevNotificationOfTheSameTypeData.dateInserted < parsedNewNotificationOfTheSameTypeData.dateInserted) {
          pv[existingIndex] = cv;
          return pv;
        } else {
          return pv;
        }
      }

      // take the most recent notification of the same type and replace
      if (parsedPrevNotificationOfTheSameTypeData.dateUpdated < parsedNewNotificationOfTheSameTypeData.dateUpdated) {
        pv[existingIndex] = cv;
        return pv;
      }

      return pv;
    }, []);
  }
}
