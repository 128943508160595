import {HttpHeaders} from '@angular/common/http';
import {DateTime} from 'luxon';
import * as moment from 'moment';
import {SortEvent} from 'primeng/api';
import {Observable} from 'rxjs';
import {Attachment} from 'src/app/models/attachment.model';
import {BodyWithAttachments} from 'src/app/models/body-with-attachments.model';
import {IHaveName} from 'src/app/models/have-name.interface';
import {RecordType} from 'src/app/modules/dialer/models/dial-list-record-basic.model';
import {Constants} from 'src/app/services/constants';
import {
  IDateRange,
  ThisMonth,
  ThisWeek,
} from 'src/app/shared/components/date-range-filter/date-range-filter.component';
import {getDaysForMonth} from 'src/utils';
import {splitCamelCase, toProperCase} from './string-utils';
import {EnumerationItem} from '../../models/simple-enum-item.model';
import {AbstractControl, NgForm} from '@angular/forms';

type NamedLikeBorrower = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nameSuffix?: string;
}

export class Utils {
  private static _units: any[] = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];

  static urlPattern: string =
    '[Hh][Tt][Tt][Pp][Ss]?://(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::d{2,5})?(?:/[^s]*)?';

  static isValidUrl = (url: string) => {
    var regex = new RegExp(this.urlPattern);
    return regex.test(url || '');
  };

  static getUrlParameter = (name: string) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var decodedUrl = decodeURIComponent(location.search);
    var results = regex.exec(decodedUrl);
    return results === null ? '' : decodeURIComponent(results[1]); //.replace(/\+/g, ' '));
  };

  static getUniqueId = () => {
    const uniqueId = Date.now() & 0xffffffff
    return uniqueId < 0 ? uniqueId : (uniqueId * -1);
  };

  static parseHHmm = (dateTime: Date): string => {
    let hours = dateTime.getHours() < 13 ? dateTime.getHours() : dateTime.getHours() - 12;
    hours = hours == 0 ? 12 : hours;
    let minutes: any = dateTime.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const ampm = dateTime.getHours() < 12 ? 'AM' : 'PM';
    return `${hours}:${minutes} ${ampm}`;
  };

  static removeTimezoneOffset = (date: Date) => {
    let userTimezoneOffset = date.getTimezoneOffset() * 60000;
    if (userTimezoneOffset >= 0) {
      return new Date(date.getTime() + userTimezoneOffset);
    }
    return new Date(date.getTime() - userTimezoneOffset);
  };

  static parseAsLocal = (dateString: string, isEndDate?: boolean): Date => {
    const dateToFormat = new Date(dateString);
    let month = dateToFormat.getUTCMonth();
    let day = dateToFormat.getUTCDate();
    let year = dateToFormat.getUTCFullYear();
    const date = new Date(year, month, day);
    if (isEndDate) {
      date.setHours(23, 59, 59);
    }
    return date;
  };

  static formatDateWithoutTime = (dateToFormat: Date): string => {
    let month: number | string = dateToFormat.getUTCMonth() + 1;
    let day: number | string = dateToFormat.getUTCDate();
    let year = dateToFormat.getUTCFullYear();

    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    return month + '/' + day + '/' + year;
  };

  static formatISODateWithoutTime = (dateString: string) => {
    const date = new Date(dateString);
    let month: number | string = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    let day: number | string = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    return date.getFullYear() + '-' + month + '-' + day + 'T00:00:00.00Z';
  };

  static insertText = (text: string, selection: any) => {
    let range = selection.getRangeAt(0);
    range.deleteContents();
    let node = document.createTextNode(text);
    range.insertNode(node);

    for (let position = 0; position != text.length; position++) {
      selection.modify('move', 'right', 'character');
    }
  };

  static insertTextAtCursor = (text: string) => {
    let selection: any = window.getSelection();
    let range = selection.getRangeAt(0);
    range.deleteContents();
    let node = document.createTextNode(text);
    range.insertNode(node);

    for (let position = 0; position != text.length; position++) {
      selection.modify('move', 'right', 'character');
    }
  };

  static timeSince = (date: string) => {
    var seconds = Math.floor((<any>new Date() - <any>new Date(date)) / 1000);
    return this.timeDisplay(seconds) + ' ago';
  };

  static timeBetween = (startDate: string, endDate: string) => {
    var seconds = Math.floor((<any>new Date(startDate) - <any>new Date(endDate)) / 1000);
    return this.timeDisplay(seconds);
  };

  static timeDisplay = (seconds: number) => {
    var retVal = '';

    var interval = Math.floor(seconds / 31536000);
    if (interval > 0) {
      retVal += interval + 'y ';
      seconds = seconds - interval * 31536000;
    }

    interval = Math.floor(seconds / 86400);
    if (interval > 0) {
      retVal += interval + 'd ';
      seconds = seconds - interval * 86400;
    }

    interval = Math.floor(seconds / 3600);
    if (interval > 0) {
      retVal += interval + 'h ';
      seconds = seconds - interval * 3600;
    }

    interval = Math.floor(seconds / 60);
    if (interval > 0) {
      retVal += interval + 'm';
      seconds = seconds - interval * 60;
    }

    if (retVal == '') {
      retVal += Math.floor(seconds) + ' seconds';
    }
    return retVal;
  };

  static getFileNameFromContentDisposition = (headers: HttpHeaders): string => {
    let filename = '';
    var disposition = headers.get('content-disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  };

  static timeSinceDate = (date: Date) => {
    if (!date) return '';
    var seconds = Math.floor((<any>new Date() - <any>new Date(date)) / 1000);
    var retVal = '';

    var interval = Math.floor(seconds / 31536000);
    if (interval > 0) {
      retVal += interval + 'y ';
      seconds = seconds - interval * 31536000;
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 0) {
      retVal += interval + 'd ';
      seconds = seconds - interval * 86400;
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 0) {
      retVal += interval + 'h ';
      seconds = seconds - interval * 3600;
    }
    interval = Math.floor(seconds / 60);
    if (interval > 0) {
      retVal += interval + 'm';
      seconds = seconds - interval * 60;
    }
    if (retVal == '') {
      retVal += Math.floor(seconds) + ' seconds';
    }
    return retVal + ' ago';
  };

  static viewFileFromBase64 = (base64: string) => {
    var fileURL = this.generateFileUrlFromBase64(base64);
    window.open(fileURL);
  };

  static generateFileUrlFromBase64 = (base64: string, mimeType: string = null) => {
    var byteCharacters = atob(base64);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var mimeTypeToUse = mimeType || 'application/pdf';
    var file = new Blob([byteArray], {type: `${mimeTypeToUse};base64`});
    var fileURL = URL.createObjectURL(file);
    return fileURL;
  };

  static generateFilterSmartRegexWords = (filter: string): RegExp[] => {
    let regexWords: RegExp[] = [];
    const escapeRegex = new RegExp(
      '(\\' +
        ['/', '.', '*', '+', '?', '|', '(', ')', '[', ']', '{', '}', '\\', '$', '^', '-'].join(
          '|\\'
        ) +
        ')',
      'g'
    );
    let search = filter.replace(escapeRegex, '\\$1');

    const searchWords = search.match(/"[^"]+"|[^ ]+/g) || [''];

    searchWords.map(word => {
      if (word.charAt(0) === '"') {
        var m = word.match(/^"(.*)"$/);
        word = m ? m[1] : word;
      }

      word = word.replace('"', '');
      search = '^(?=.*?' + word + ')(?=.*?' + ').*$';

      const filterRegex = new RegExp(search, true ? 'i' : '');
      regexWords.push(filterRegex);
    });

    return regexWords;
  };

  static filter = (filterFields: string[], searchString: string, itemsToFilter: any[]) => {
    let filteredItems: any[] = [...itemsToFilter];
    if (!searchString) {
      return filteredItems;
    }
    const smartFilterRegexWords = Utils.generateFilterSmartRegexWords(searchString.trim());
    smartFilterRegexWords.forEach(smartFilter => {
      filteredItems = Utils.doFilter(filterFields, smartFilter, filteredItems);
    });
    return filteredItems;
  };

  static timeAgo = (date: string) => {
    let dateTime = DateTime.fromJSDate(new Date(date));
    const diff = dateTime.diffNow().shiftTo(...this._units);
    const unit = this._units.find(unit => diff.get(unit) !== 0) || 'second';

    const relativeFormatter = new Intl.RelativeTimeFormat('en', {
      numeric: 'auto',
    });
    return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
  };

  static parseEmailImages = (body: string): BodyWithAttachments => {
    const bodyWithAttachments: BodyWithAttachments = new BodyWithAttachments();
    bodyWithAttachments.body = body;
    if (
      !body.includes('image/gif') &&
      !body.includes('image/jpeg') &&
      !body.includes('image/jpg') &&
      !body.includes('image/png') &&
      !body.includes('image/bmp')
    )
      return bodyWithAttachments;

    const doc = new DOMParser().parseFromString(body, 'text/html');
    const imgs = doc.querySelectorAll('img');

    if (imgs.length > 0) {
      bodyWithAttachments.body = body.replace(/<img .*?>/g, '{img}');

      for (let i = 0; i < imgs.length; i++) {
        const extension = imgs[i].src.split(';')[0].split('/')[1];

        let fileName = imgs[i].dataset.filename
          ? `${imgs[i].dataset.filename.split('.')[0]}_${i}.${
              imgs[i].dataset.filename.split('.')[1]
            }`
          : `file_${i}.${extension}`; // handshake_0.gif || file_0.gif
        fileName = fileName.replace(' ', '');
        const id = `id-${i}`;
        const cid = `cid:${fileName}`;
        const fileContent = imgs[i].src;
        bodyWithAttachments.body = body.replace(
          '{img}',
          `<img width=150 id='${id}' src='${cid}' />`
        );
        bodyWithAttachments.attachments.push(new Attachment(cid, fileName, fileContent));
      }
    }

    return bodyWithAttachments;
  };

  static formatPhoneNumber = (number: string): string => {
    let cleaned = ('' + number).replace(/\D/g, ''),
      match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{3}|\d{4})$/);
    if (match) {
      let intlCode = match[1] ? '+1 ' : '';
      return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return number;
  };

  static cleanFormatedPhoneNumber = (phoneNum: string): string => {
    if (!phoneNum) {
      return null;
    }

    return phoneNum
      .replace('+1', '')
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '');
  };

  static padLeft = (number: string, padChar: string, minLength: number): string => {
    while (number.length < minLength) number = padChar + number;
    return number;
  };

  static parseJwt = (token: string): any => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  };

  static isValidDate = (value: string): boolean => {
    if (value.length !== 10) {
      return false;
    }
    if (isNaN(Date.parse(value))) {
      return false;
    }
    const maxDays = getDaysForMonth(Number(value.split('/')[0]), Number(value.split('/')[2]));
    const areDaysValid = Number(value.split('/')[1]) <= maxDays;
    return Constants.regexPatterns.date.test(value) && areDaysValid;
  };

  static getTokenExpireDate = (exp: number) => {
    let expiresDate = new Date(0);
    expiresDate.setUTCMilliseconds(exp * 1000);

    return expiresDate;
  };

  /**
   *
   * @param person
   * @returns Last name and first name separated with comma
   */
  static getPersonsDisplayName = (person: IHaveName): string => {
    if (!person) return '';

    let displayName = '';
    if (person.lastName) {
      displayName = person.lastName;
    }
    if (person.firstName) {
      if (displayName) {
        displayName = displayName + ', ' + person.firstName;
      } else {
        displayName = person.firstName;
      }
    }
    return displayName;
  };

  static pad = (val: number): string => {
    let valString = val + '';
    if (valString.length < 2) {
      return '0' + valString;
    } else {
      return valString;
    }
  };

  /**
   * Retrieves the full name of a borrower.
   *
   * @param {NamedLikeBorrower|undefined} borrower - The borrower object.
   * @returns {string} - The full name of the borrower.
   *
   * @example
   *  const borrower = {
   *    firstName: 'John',
   *    middleName: 'Doe',
   *    lastName: 'Smith',
   *    nameSuffix: 'Jr.',
   *  };
   *
   *  console.log(getBorrowerFullName(borrower)); // Output: 'John Doe Smith Jr.'
   *
   * @example
   * const noSuffixBorrower = {
   *  firstName: 'John',
   *  middleName: 'Doe',
   *  lastName: 'Smith',
   * };
   *
   * console.log(getBorrowerFullName(noSuffixBorrower)); // Output: 'Jane Doe Smith'
   *
   * @example
   *  // In case of undefined borrower it will return an empty string
   *  console.log(getBorrowerFullName(undefined));  // Output: ''
   */
  static getBorrowerFullName = (borrower: NamedLikeBorrower | undefined): string => {
    if (!borrower) return '';

    const {
      firstName,
      middleName,
      lastName,
      nameSuffix,
    } = borrower;

    return [
      firstName,
      middleName,
      lastName,
      nameSuffix,
    ].filter(Boolean).join(' ').trim();
  };

  /**
   * Retrieves the display name of a borrower.
   *
   * @param {NamedLikeBorrower|undefined} borrower - The borrower object.
   * @return {string} - The display name of the borrower.
   *
   * @example
   * const borrower: MortgageBorrower = {
   *   firstName: 'John',
   *   lastName: 'Doe',
   *   nameSuffix: 'III',
   * };
   * console.log(getBorrowerDisplayName(borrower)); // Output: "Doe, John III"
   *
   * @example
   * const noSuffixBorrower: MortgageBorrower = {
   *   firstName: 'Jane',
   *   lastName: 'Smith',
   * };
   * console.log(getBorrowerDisplayName(noSuffixBorrower)); // Output: "Smith, Jane"
   *
   * @example
   * // In case of undefined borrower it will return an empty string
   * console.log(getBorrowerDisplayName(undefined)); // Output: ""
   */
  static getBorrowerDisplayName(borrower: NamedLikeBorrower | undefined): string {
    if (!borrower) return '';

    const {
      firstName,
      lastName,
      nameSuffix,
    } = borrower;

    const firstAndLastName = [
      lastName,
      firstName,
    ].filter(Boolean).join(', ');

    return [
      firstAndLastName,
      nameSuffix,
    ].filter(Boolean).join(' ').trim();
  }

  /**
   *
   * @param person
   * @returns First name and last name separated with space
   */
  static getPersonsFullName = (person: IHaveName): string => {
    if (!person) return '';
    let fullName = this.getFullName(person.firstName, person.lastName);
    return fullName;
  };

  /**
   *
   * @param person
   * @returns First name and last name separated with space
   */
  static getFullName = (
    firstName: string | null | undefined,
    lastName: string | null | undefined
  ): string => {
    let fullName = '';
    if (firstName) {
      fullName = firstName;
    }
    if (lastName) {
      if (fullName) {
        fullName = fullName + ' ' + lastName;
      } else {
        fullName = lastName;
      }
    }
    return fullName;
  };

  static getRecordIdFieldName = (recordType: RecordType): string => {
    let idFieldName;

    switch (recordType) {
      case RecordType.Lead:
        idFieldName = 'leadId';
        break;

      case RecordType.Borrower:
        idFieldName = 'borrowerId';
        break;

      case RecordType.Application:
        idFieldName = 'applicationId';
        break;

      case RecordType.Agent:
        idFieldName = 'agentId';
        break;
    }

    return idFieldName;
  };

  static viewImage = base64Image => {
    const parts = base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    const blob = new Blob([uInt8Array], {type: imageType});
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  };

  static splitCamelCaseString = (str: string): string => {
    return str
      ? str
          .trim()
          .split(/(?=[A-Z])/)
          .join(' ')
      : null;
  };

  static splitCamelCaseStringCorrectly = (str: string): string => {
    return str ? str.trim().replace('((?<=[a-z])[A-Z]|[A-Z](?=[a-z]))', ' $1') : null;
  };

  static spinalTapCaseString = (str: string): string => {
    return str
      ? str
          .replace(/([a-z])([A-Z])/g, '$1 $2')
          .trim()
          .replace(/_/g, ' ')
      : null;
  };

  static toTitleCase = (str: string): string => {
    return str
      ? str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase())
      : null;
  };

  static lowerCaseFirstLetter = (str: string): string => {
    return str.charAt(0).toLocaleLowerCase() + str.slice(1);
  };

  static upperCaseFirstLetter = (str: string): string => {
    if (!str) return '';
    return str.charAt(0).toLocaleUpperCase() + str.slice(1);
  };

  static getChartColors = (): string[] => {
    const colors = [
      '#3366cc',
      '#dc3912',
      '#ff9900',
      '#109618',
      '#990099',
      '#0099c6',
      '#dd4477',
      '#66aa00',
      '#b82e2e',
      '#316395',
      '#3366cc',
      '#994499',
      '#22aa99',
      '#aaaa11',
      '#6633cc',
      '#e67300',
      '#8b0707',
      '#651067',
      '#329262',
      '#5574a6',
      '#3b3eac',
      '#b77322',
      '#16d620',
      '#b91383',
      '#f4359e',
      '#9c5935',
      '#a9c413',
      '#2a778d',
      '#668d1c',
      '#bea413',
      '#0c5922',
      '#743411',
    ];
    return colors;
  };

  static primeTableCustomSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      if (value1 && typeof value1 === 'object' && value1?.formattedName) {
        value1 = value1.formattedName;
      }
      if (value2 && typeof value2 === 'object' && value2?.formattedName) {
        value2 = value2.formattedName;
      }
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (event.field === 'dateCreated') {
        const momentDate1 = moment(value1);
        const momentDate2 = moment(value2);
        if (momentDate2.isBefore(momentDate1)) {
          result = 1;
        } else if (momentDate2.isAfter(momentDate1)) {
          result = -1;
        } else {
          result = 0;
        }
      } else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  static cleanPhone = (phoneNum): string => {
    if (!phoneNum) return '';

    if (phoneNum.length > 10 && phoneNum[0] === '1') {
      return phoneNum.substring(1);
    } else {
      return phoneNum
        .replace('+1', '')
        .replace('(', '')
        .replace(')', '')
        .replace('-', '')
        .replace(' ', '');
    }
  };

  private static doFilter = (
    filterFields: string[],
    smartFilter: RegExp,
    itemsToFilter: any[]
  ): any[] => {
    return itemsToFilter.filter(a => {
      let isPicked = false;
      filterFields.forEach(f => {
        let searchFieldValue = a[f];
        if (searchFieldValue) {
          let searchFiedvalueAsString = searchFieldValue.toString();
          searchFiedvalueAsString = searchFiedvalueAsString.replace(/\r?\n|\r/g, ''); // remove all new line chars
          isPicked = isPicked || smartFilter.test(searchFiedvalueAsString);
        }
      });
      return isPicked;
    });
  };

  static getDateRangeById = (selectedRange: IDateRange, id: number): IDateRange => {
    const nowDate = DateTime.now();

    switch (id) {
      case 1: // Today
        selectedRange.startDate = nowDate.startOf('day').toJSDate();
        selectedRange.endDate = nowDate.endOf('day').toJSDate();
        break;

      case 2: // Yesterday
        selectedRange.startDate = nowDate.minus({days: 1}).startOf('day').toJSDate();
        selectedRange.endDate = nowDate.minus({days: 1}).endOf('day').toJSDate();
        break;

      case 3: // This Week
        // notice: the first day of the week always returns Monday in luxon
        const thisWeek = new ThisWeek();
        selectedRange = {
          id: thisWeek.id,
          name: thisWeek.name,
          displayText: thisWeek.displayText,
          startDate: thisWeek.startDate,
          endDate: thisWeek.endDate,
        };
        break;

      case 4: // Last Week
        selectedRange.startDate = nowDate.minus({weeks: 1}).startOf('week').toJSDate();
        selectedRange.endDate = nowDate.minus({weeks: 1}).endOf('week').toJSDate();
        break;

      case 5: // This month
        const thisMonth = new ThisMonth();
        selectedRange = {
          id: thisMonth.id,
          name: thisMonth.name,
          displayText: thisMonth.displayText,
          startDate: thisMonth.startDate,
          endDate: thisMonth.endDate,
        };
        break;

      case 6: // Last month
        selectedRange.startDate = nowDate.minus({months: 1}).startOf('month').toJSDate();
        selectedRange.endDate = nowDate.minus({months: 1}).endOf('month').toJSDate();
        break;

      case 7: // This Quarter
        selectedRange.startDate = nowDate.startOf('quarter').toJSDate();
        selectedRange.endDate = nowDate.endOf('quarter').toJSDate();
        break;

      case 8: // Last Quarter
        selectedRange.startDate = nowDate.minus({quarters: 1}).startOf('quarter').toJSDate();
        selectedRange.endDate = nowDate.minus({quarters: 1}).endOf('quarter').toJSDate();
        break;

      case 9: // Year To Date
        selectedRange.startDate = nowDate.startOf('year').toJSDate();
        selectedRange.endDate = nowDate.endOf('year').toJSDate();
        break;

      case 10: // Last Year
        selectedRange.startDate = nowDate.minus({years: 1}).startOf('year').toJSDate();
        selectedRange.endDate = nowDate.minus({years: 1}).endOf('year').toJSDate();
        break;

      case 11: // All Time
        selectedRange.startDate = DateTime.fromObject({month: 1, day: 1, year: 2015}).toJSDate();
        selectedRange.endDate = nowDate.toJSDate();
        break;

      case 12: // 1. Quarter
        selectedRange.startDate = nowDate.startOf('year').toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({quarters: 1, days: -1}).toJSDate();
        break;

      case 13: // 2. Quarter
        selectedRange.startDate = nowDate.startOf('year').plus({quarters: 1}).toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({quarters: 2, days: -1}).toJSDate();
        break;

      case 14: // 3. Quarter
        selectedRange.startDate = nowDate.startOf('year').plus({quarters: 2}).toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({quarters: 3, days: -1}).toJSDate();
        break;

      case 15: // 4. Quarter
        selectedRange.startDate = nowDate.startOf('year').plus({quarters: 3}).toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({quarters: 4, days: -1}).toJSDate();
        break;

      case 16: // January
        selectedRange.startDate = nowDate.startOf('year').toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({months: 1, days: -1}).toJSDate();
        break;

      case 17: // Fabruary
        selectedRange.startDate = nowDate.startOf('year').plus({months: 1}).toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({months: 2, days: -1}).toJSDate();
        break;

      case 18: // March
        selectedRange.startDate = nowDate.startOf('year').plus({months: 2}).toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({months: 3, days: -1}).toJSDate();
        break;

      case 19: // April
        selectedRange.startDate = nowDate.startOf('year').plus({months: 3}).toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({months: 4, days: -1}).toJSDate();
        break;

      case 20: // May
        selectedRange.startDate = nowDate.startOf('year').plus({months: 4}).toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({months: 5, days: -1}).toJSDate();
        break;

      case 21: // June
        selectedRange.startDate = nowDate.startOf('year').plus({months: 5}).toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({months: 6, days: -1}).toJSDate();
        break;

      case 22: // July
        selectedRange.startDate = nowDate.startOf('year').plus({months: 6}).toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({months: 7, days: -1}).toJSDate();
        break;

      case 23: // August
        selectedRange.startDate = nowDate.startOf('year').plus({months: 7}).toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({months: 8, days: -1}).toJSDate();
        break;

      case 24: // September
        selectedRange.startDate = nowDate.startOf('year').plus({months: 8}).toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({months: 9, days: -1}).toJSDate();
        break;

      case 25: // October
        selectedRange.startDate = nowDate.startOf('year').plus({months: 9}).toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({months: 10, days: -1}).toJSDate();
        break;

      case 26: // November
        selectedRange.startDate = nowDate.startOf('year').plus({months: 10}).toJSDate();
        selectedRange.endDate = nowDate.startOf('year').plus({months: 11, days: -1}).toJSDate();
        break;

      case 27: // December
        selectedRange.startDate = nowDate.startOf('year').plus({months: 11}).toJSDate();
        selectedRange.endDate = nowDate.endOf('year').toJSDate();
        break;

      default:
        selectedRange.startDate = null;
        selectedRange.endDate = null;
        break;
    }
    return selectedRange;
  };

  static secondsToHms = (seconds: number): string => {
    seconds = Number(seconds);
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);
    const hDisplay = h > 0 ? h + (h == 1 ? ' hr' : ' hrs') + (m > 0 || s > 0 ? ', ' : '') : '';
    const mDisplay = m > 0 ? m + (m == 1 ? ' min' : ' mins') + (s > 0 ? ', ' : '') : '';
    const sDisplay = s > 0 ? s + (s == 1 ? ' sec' : ' secs') : '';
    return hDisplay + mDisplay + sDisplay;
  };

  static getFileNameAndExtension = (fullFileName): {fileName: string; extension: string} => {
    if (!fullFileName) return {fileName: '', extension: ''};

    const index = fullFileName.lastIndexOf('.');
    if (index === -1) return {fileName: fullFileName, extension: ''};

    const fileName = fullFileName.slice(0, index);
    const extension = fullFileName.slice(index + 1);

    return {fileName, extension};
  };

  static setStartDateToStartOfDay = (startDate: Date) => {
    if (!startDate) {
      return null;
    }
    if (
      startDate.getHours() === 0 &&
      startDate.getMinutes() === 0 &&
      startDate.getSeconds() === 0
    ) {
      return startDate;
    }
    const startDateTime = DateTime.fromJSDate(startDate);
    return startDateTime.startOf('day').toJSDate();
  };

  static setEndDateToEndOfDay = (endDate: Date) => {
    if (!endDate) {
      return null;
    }
    if (endDate.getHours() === 23 && endDate.getMinutes() === 59 && endDate.getSeconds() === 59) {
      return endDate;
    }

    const endDateTime = DateTime.fromJSDate(endDate);
    return endDateTime.endOf('day').toJSDate();
  };

  static toBase64 = file =>
    new Observable<string>(subscriber => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        subscriber.next(reader.result as string);
        subscriber.complete();
      };
      reader.onerror = () => subscriber.error(reader.error);
      return () => reader.abort(); // cancel function in case you unsubscribe from the obs
    });

  static addBusinessDays = (d: Date, n: number): Date => {
    d = new Date(d.getTime());
    let day = d.getDay();
    d.setDate(
      d.getDate() + n + (day === 6 ? 2 : +!day) + Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2
    );
    return d;
  };

  static validateAndScrollToFirstInvalidControl(form: NgForm): boolean {
    let firstInvalidOneId: string = null;
    let isValid: boolean = false;
    if (form) {
      form.form.markAllAsTouched();
      isValid = form.form.valid;
      if (!isValid) {
        for (var key in form.form.controls) {
          if (form.form.controls.hasOwnProperty(key)) {
            if (form.form.controls[key].status === 'INVALID') {
              firstInvalidOneId = key;
              break;
            }
          }
        }
      }
      if (firstInvalidOneId) {
        Utils.scrollToElement(firstInvalidOneId).then(() => {});
      }
    }
    return isValid;
  }

  static scrollUp(scrollUpPixels: number) {
    // Check if the document is in quirks mode or standard mode
    var isQuirksMode = document.compatMode !== 'CSS1Compat';
    // Determine the element to scroll based on the mode
    var scrollElement = isQuirksMode ? document.body : document.documentElement;
    // Scroll up by the specified number of pixels
    scrollElement.scrollTop -= scrollUpPixels;
  }

  static scrollByAmount(amount) {
    // Scroll by the specified amount with smooth scrolling
    setTimeout(() => {
      window.scrollBy({
        top: amount,
        behavior: 'smooth',
      });
    }, 200);
  }

  static scrollToElement = (id: string) => {
    return new Promise(resolve => {
      const element = document.getElementById(id);
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
        resolve(id);
      }, 200);
    });
  };

  static decodeHTMLEntities = (input: string) => {
    if (!input) return '';

    // Define a map of common HTML character entities and their corresponding characters
    const map = {
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&#039;': "'",
      '&#x2F;': '/',
      '&#92;': '\\',
      '&nbsp;': ' ',
      // Add more entities if needed
    };

    // Replace each entity with its corresponding character
    return input.replace(/&\w+;/g, function (match) {
      return map[match] || match;
    });
  };

  static encodeHTMLEntities = (input: string) => {
    if (!input) return '';

    // Define a map of common HTML character entities and their corresponding characters
    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;',
      '/': '&#x2F;',
      '\\': '&#92;',
      ' ': '&nbsp;',
      // Add more entities if needed
    };

    // Replace each entity with its corresponding character
    return input.replace(/[^\w+]/g, function (match) {
      return map[match] || match;
    });
  };
}

/**
 * Converts a string enum value to display name.
 * @param {string} value The enum value to convert.
 * @returns {string} The display name of the enum value.
 */
export function enumLikeValueToDisplayName(value: string): string {
  return toProperCase(splitCamelCase(value));
}

/**
 * Creates a map of enumeration items by their value.
 * @template T The type of the enumeration item value.
 * @param {EnumerationItem<T>[]} enumerationItems The enumeration items to
 * create the map from.
 * @returns {Map<T, EnumerationItem<T>>} The map of enumeration items by their
 * value.
 */
export function createEnumerationItemByValueMap<T>(
  enumerationItems: EnumerationItem<T>[],
): Map<T, EnumerationItem<T>> {
  return new Map<T, EnumerationItem<T>>(
    enumerationItems.map(item => [item.value, item]),
  );
}

/**
 * Converts an enumeration to enumeration items by converting the enumeration
 * keys to display names (using {@link enumLikeValueToDisplayName}).
 * @template T The type of the enumeration item value.
 * @param {Record<string, T>} enumeration The enumeration to convert.
 * @returns {EnumerationItem<T>[]} The enumeration items.
 */
export function enumToEnumerationItems<T>(
  enumeration: Record<string, T>,
): EnumerationItem<T>[] {
  return Object.entries(enumeration).map(([key, value]) =>
    new EnumerationItem<T>(enumLikeValueToDisplayName(key), value));
}

/**
 * Filters out all null and undefined values from the given object.
 * @template T The type of the object.
 * @param {T} obj The object to filter.
 * @returns {T} A new object with all null and undefined values filtered out.
 */
export function filterOutNil<T extends Record<any, any>>(obj: T): T {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => (value != null ? { ...acc, [key]: value } : acc),
    {} as T,
  );
}

/**
 * Generates an array of random bytes. This is used as a workaround for the
 * lack of a `crypto` module in the browser.
 *
 * @param {number} n - The number of random bytes to generate.
 * @returns {Uint8Array} - An array of random bytes.
 */
function randomBytes(n: number): Uint8Array {
  let arr = new Uint8Array(n);
  for (let i = 0; i < n; i++) {
    arr[i] = Math.floor(Math.random() * 256);
  }
  return arr;
}


/**
 * Generate a unique client-side identifier.
 *
 * Used for the creation of new documents.
 *
 * @private
 * @see https://github.com/googleapis/nodejs-firestore/blob/main/dev/src/util.ts
 * @returns {string} A unique 20-character wide identifier.
 */
export function autoId(): string {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let autoId = '';
  while (autoId.length < 20) {
    const bytes = randomBytes(40);
    bytes.forEach(b => {
      // Length of `chars` is 62. We only take bytes between 0 and 62*4-1
      // (both inclusive). The value is then evenly mapped to indices of `char`
      // via a modulo operation.
      const maxValue = 62 * 4 - 1;
      if (autoId.length < 20 && b <= maxValue) {
        autoId += chars.charAt(b % 62);
      }
    });
  }
  return autoId;
}

/**
 * Adds or removes the specified errors to the control while preserving the rest of the errors.
 * @param {FormControl<any>} control The control to add or remove the errors from.
 * @param {{ [key: string]: boolean }} errors The errors to add or remove.
 * If the value is `true`, the error is added; if the value is `false`, the error is removed.
 * The existing errors are preserved.
 * @param {Parameters<FormControl<any>['setErrors']>[1]} [opts] The options that are forwarded to
 * the `setErrors` method.
 */
export function setControlErrors(
  control: AbstractControl,
  errors: {[key: string]: boolean},
  opts?: Parameters<AbstractControl['setErrors']>[1]
): void {
  const currentErrors = control.errors || {};

  Object.keys(errors).forEach(key => {
    if (errors[key]) {
      currentErrors[key] = true;
    } else {
      delete currentErrors[key];
    }
  });

  control.setErrors(Object.keys(currentErrors).length ? currentErrors : null, opts);
}

/**
 * Removes the specified errors from the control while preserving the rest of the errors.
 * @param {FormControl<any>} control The control to remove the errors from.
 * @param {string[]} errorKeys The keys of the errors to remove.
 * @param {Parameters<FormControl<any>['setErrors']>[1]} [opts] The options that are forwarded to
 * the `setErrors` method.
 */
export function removeControlErrors(
  control: AbstractControl,
  errorKeys: string[],
  opts?: Parameters<AbstractControl['setErrors']>[1]
): void {
  const currentErrors = control.errors || {};

  errorKeys.forEach(key => {
    delete currentErrors[key];
  });

  control.setErrors(Object.keys(currentErrors).length ? currentErrors : null, opts);
}

/**
 * Ignores modal errors that are not considered errors and rethrows the rest.
 * @param {unknown} error The error to handle.
 */
export function handleNonErrorDismissals(error: unknown): void {
  if (error == null || error === 1) {
    // The modal service rejects the promise with `undefined` when the modal is dismissed without a reason.
    // It also rejects the promise with `1` when the ESC key is used to dismiss the modal.
    // We aim to handle both cases gracefully.
    return;
  }

  // Rethrow the error if it's a real error.
  throw error;
}

export function getCreditProviderMappings(): CreditProvidersMapping[] {
  return [
    { fannieId: '292', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'AccurateFinancialServices' },
    { fannieId: '31', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'ACRAnet' },

    // TODO: Sean verify: Mapped to SharperLending, cause name is "Advantage Credit Bureau - SharperLending"?
    { fannieId: '313', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'SharperLending' },

    // TODO: Sean verify: The name says "Advantage Credit Bureau (powered by MeridianLink)", but not in affiliate list, also maps to DCICreditServices on the Excel file
    { fannieId: '310', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'DCICreditServices' },

    // TODO: Sean verify: The name says "Advantage Credit, Inc. by Credit Interlink", but not in affiliate list, also maps to DCICreditServices on the Excel file,
    // Do we map the subvendor to AdvantageCredit?
    { fannieId: '308', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'AdvantageCredit' },

    { fannieId: '226', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 1, technicalAffiliateCode: '226', subLodaVendorName: null },
    { fannieId: '138', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 2, technicalAffiliateCode: '138', subLodaVendorName: null },
    { fannieId: '139', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 4, technicalAffiliateCode: '016', subLodaVendorName: null },
    { fannieId: '76', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 5, technicalAffiliateCode: '076', subLodaVendorName: null },
    { fannieId: '911', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'CBCInnovis' },

    // TODO: Sean verify: The name says "Advantage Credit Bureau (powered by MeridianLink)", but not in affiliate list, also maps to DCICreditServices on the Excel file
    // Fannie name is "Certified Credit Reporting, Inc.", but the affiliate name is "Certified Credit Reporting, Inc. via MeridianLink".
    // DO these really map?
    { fannieId: '911', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 7, technicalAffiliateCode: '071', subLodaVendorName: null },
    { fannieId: '128', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 8, technicalAffiliateCode: '128', subLodaVendorName: null },
    { fannieId: '86', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 10, technicalAffiliateCode: '086', subLodaVendorName: null },
    { fannieId: '1', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'CoreLogic' },
    { fannieId: '904', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'CoreLogic' },
    { fannieId: '298', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 9, technicalAffiliateCode: '298', subLodaVendorName: null },

    // TODO: Sean verify: The Fannie name says ""Credit Information Systems ML", but affiliate list has a name "Credit Information Systems - CIS"
    // Do these map??
    { fannieId: '311', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'CreditInformationSystems' },

    // TODO: Sean verify: The name says "Credit Interlink Test", but no tech affiliates and no Encompass provider
    { fannieId: '916', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },

    { fannieId: '24', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 12, technicalAffiliateCode: '024', subLodaVendorName: null },
    { fannieId: '17', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 101, technicalAffiliateCode: '017', subLodaVendorName: null },

    // TODO: Sean verify: The name says "Credit Quick Services", but no tech affiliates and no Encompass provider
    { fannieId: '116', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },


    { fannieId: '287', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 15, technicalAffiliateCode: '181', subLodaVendorName: null },
    { fannieId: '161', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 16, technicalAffiliateCode: '161', subLodaVendorName: null },
    { fannieId: '301', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 29, technicalAffiliateCode: '304', subLodaVendorName: null },
    { fannieId: '4', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'Equifax' },
    { fannieId: '912', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'Equifax' },
    { fannieId: '3', lodaVendorName: 'FactualData', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },
    { fannieId: '303', lodaVendorName: 'FactualData', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },
    { fannieId: '918', lodaVendorName: 'FactualData', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },
    { fannieId: '130', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'Covius' },
    { fannieId: '17', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 58, technicalAffiliateCode: '017', subLodaVendorName: null },
    { fannieId: '2', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'InformativeResearch' },
    { fannieId: '913', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'InformativeResearch' },
    { fannieId: '305', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 18, technicalAffiliateCode: '309', subLodaVendorName: null },
    { fannieId: '309', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 100, technicalAffiliateCode: '328', subLodaVendorName: null },
    { fannieId: '78', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 20, technicalAffiliateCode: '078', subLodaVendorName: null },

    // TODO: Sean verify: The name says "MeridianLink Test", what technical affiliate should this map to?
    { fannieId: '909', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },

    // TODO: Sean verify: The name says "MeridianLink, Inc", what technical affiliate should this map to?
    { fannieId: '281', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },

    { fannieId: '277', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'MFICreditSolutions' },
    { fannieId: '6', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'NCOCS' },

    // TODO: Sean verify: The name says NCRA Test, but no tech affiliates and no Encompass provider
    { fannieId: '915', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },

    // TODO: Sean verify: The name says ""Online Information Services", but no tech affiliates, is it the same as Online Mortgage Reports from Encompass?
    { fannieId: '204', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'OnlineMortgageReports' },

    // TODO: Sean verify: The name says "Partners Credit and Verification Solutions", but I took the encompass mapping from the initial excel file
    { fannieId: '251', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'OldRepublic' },

    { fannieId: '299', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 22, technicalAffiliateCode: '294', subLodaVendorName: null },
    { fannieId: '295', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 23, technicalAffiliateCode: '232', subLodaVendorName: null },
    { fannieId: '302', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 25, technicalAffiliateCode: '302', subLodaVendorName: null },
    { fannieId: '229', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 27, technicalAffiliateCode: '229', subLodaVendorName: null },

    // TODO: Sean verify: These 2 below are dupes with name "SettlementOne Data, LLC"
    { fannieId: '290', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 28, technicalAffiliateCode: '290', subLodaVendorName: null },
    { fannieId: '307', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 28, technicalAffiliateCode: '290', subLodaVendorName: null },

    { fannieId: '282', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'SharperLending' },
    { fannieId: '908', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'SharperLending' },

    // TODO: Sean verify: The name says "SL Solutions", but no tech affiliates and no Encompass provider, can this be SharpLending??
    { fannieId: '5', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },

    // TODO: Sean verify: The name says "Test Credit Agency", what exactly is this??
    { fannieId: '200', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },

    { fannieId: '201', lodaVendorName: 'CredCoHardPull', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },
    { fannieId: '270', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'TransUnion' },

    // TODO: Sean verify: The name says "Unisource Credit, LLC", but no tech affiliates and no Encompass provider
    { fannieId: '314', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'TransUnion' },

    { fannieId: '63', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 31, technicalAffiliateCode: '063', subLodaVendorName: null },
    { fannieId: '51', lodaVendorName: 'Xactus', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },

  ];
}

export class CreditProvidersMapping {
  fannieId: string;
  lodaVendorName: string;
  technicalAffiliateId: number;
  subLodaVendorName: string;
  technicalAffiliateCode: string;
}
