export enum DialerEventType {
  findOnDialList = "findOnDialList",
  dialerv2_recordCalled = "dialerv2::recordCalled",
  dialerv2_acceptAndDialFreeForAllLead = "dialerv2::acceptAndDialFreeForAllLead",
  loadCallPanel = "loadCallPanel",
  quickSmsSent = "quickSmsSent",
  dialerv2_toggleList = "dialerv2::toggleList",
  dialerv2_expandCollapseRecord = "dialerv2::expandCollapseRecord",
  endCurrentCall = "endCurrentCall",
  pushDialListRecord = "pushDialListRecord",
  changeRecordCallStatus = "changeRecordCallStatus",
  changeSelectedDialList = "changeSelectedDialList",
  changeParticipants = "changeParticipants",
  changedSelectedRecordForCall = "changedSelectedRecordForCall",
  DialListRecordRemoved = "DialListRecordRemoved",
  ExternalCallTransferred = "ExternalCallTransferred",
  WarmTransferReceived = "WarmTransferReceived",
  WarmTransferCanceled = "WarmTransferCanceled",
  WarmTransferConnected = "WarmTransferConnected",
  WarmTransferCompleted = "WarmTransferCompleted",
  WarmTransferAccepted = "WarmTransferAccepted",
  CallCompleted = "CallCompleted",
  CallStatusChanged = "CallStatusChanged",
  ConferenceEnded = "ConferenceEnded",
  LeadQuickNoteAdded = "LeadQuickNoteAdded",
  LeadQuickSMSSended = "LeadQuickSMSSended",
  DialUsingChanged = "DialUsingChanged",
  ScreenPopRecord = "ScreenPopRecord"
}
