import { AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize, Observable, Subscription } from "rxjs";
import { saveAs } from 'file-saver';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { Table } from "primeng/table";
import { Workbook } from "exceljs";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { Utils } from "src/app/core/services/utils";
import { ContactListType, LeadStatus } from "src/app/models";
import { LeadCampaign } from "src/app/models/config/global-config.model";
import { LoanPurpose } from "src/app/models/config/loan-purpose.model";
import { LoanType } from "src/app/models/config/loan-type.model";
import { ReferralSource } from "src/app/models/referral-source.model";
import { User } from "src/app/models/user/user.model";
import { AgentService } from "src/app/services/agent.service";
import { Constants } from "src/app/services/constants";
import { NotificationService } from "src/app/services/notification.service";
import { ApplicationContextBoundComponent } from "src/app/shared/components";
import { ReassignLeadUserConfirmationDialogComponent } from "../leads/components/dialogs/reassign-lead-user-confirmation-dialog/reassign-lead-user-confirmation-dialog.component";
import { ViewLeadDrawerComponent } from "../leads/components/dialogs/view-lead-drawer/view-lead-drawer.component";
import { LeadFilters } from "../leads/models/lead-filters.model";
import { LeadList } from "../leads/models/lead-list.model";
import { Lead } from "../leads/models/lead.model";
import { LeadsService } from "../leads/services/leads.service";
import { LeadTableConfig } from "./models/lead-table-config.model";
import { Column } from "src/app/shared/models/table-config.model";
import * as _ from "lodash";
import { IDateRange } from "src/app/shared/components/date-range-filter/date-range-filter.component";
import { DateTime } from "luxon";
import { MultiSelect } from "primeng/multiselect";
import { WebPreferences } from "src/app/models/web-preferences.model";
import { CommonService } from "src/app/services/common.service";
import { ConfigurationService } from "src/app/services/configuration.service";

@Component({
  selector: 'lead-table',
  templateUrl: './lead-table.component.html',
  styleUrls: ['./lead-table.component.scss']
})
export class LeadTableComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit {

  @Input()
  set filters(filters: LeadFilters) {
    this._filters = filters;
    this.getLeads();
  }

  @Input()
  set leads(leads: Lead[]) {
    if (!leads) {
      return;
    }
    this._leads = leads;
    this.populateOtherLeadPropertiesToDisplayOnTable(this._leads);
    this.filteredLeads = [...this._leads];
    this.gotoPageOne();
  }

  @Input()
  set selectedColumns(selectedColumns: any) {
    this._selectedColumns = selectedColumns;
    if (selectedColumns) {
      this._selectedColumns.sort((a, b) => a.order - b.order);
      this.webPreferences.leadsPreferences.leadsTableSelectedColumns = this._selectedColumns;
    }
  }

  @Input() leadTableConfig: LeadTableConfig;

  @Input() showUnassignedLeads: boolean = false;

  @Input() exportOnlyColumnsMapping: Map<string, Column[]> = new Map<string, Column[]>();

  @Input()
  webPreferences: WebPreferences;

  @Output()
  editRequestedForLead: EventEmitter<Lead> = new EventEmitter<Lead>();

  @ViewChild('dt1')
  leadTable: Table;

  @ViewChild('mobileColumnSelector')
  mobileColumnSelector: MultiSelect;

  get filters(): LeadFilters { return this._filters; }
  get selectedColumns(): any { return this._selectedColumns; }

  filteredLeads: Lead[] = [];

  selectedRows: any[] = [];
  globalFilterFields: string[] = [];
  contactListType: ContactListType = ContactListType.Lead;
  users: User[] = [];
  leadLists: LeadList[] = [];
  leadCampaigns: LeadCampaign[] = [];

  isLoading: boolean = false;

  error: any;
  dialerEnabled: boolean = false;
  isTpoUser: boolean;

  globalSearchString: string = null;

  tableState: any;

  private _selectedColumns: any;
  private _filters: LeadFilters;
  private _leadStatuses: LeadStatus[] = [];
  private _loanPurposes: LoanPurpose[] = [];
  private _loanTypes: LoanType[] = [];
  private _referralSources: ReferralSource[] = [];
  private _isSubjectPropertyAddressHidden: boolean = false;
  private _isAdmin: boolean = false;
  private _selectedColumnsLocalStorageKeyName: string = "leads-selectedColumns";

  private _leads: Lead[] = [];

  private _lastNote: string = "";

  private _applicationContextSubscription: Subscription;
  private _systemLevelSubscription: Subscription;
  private _leadUpdatesSubscription: Subscription;

  constructor(
    private readonly _spinner: NgxSpinnerService,
    private readonly _leadService: LeadsService,
    private readonly _agentService: AgentService,
    private readonly _configurationService: ConfigurationService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly injector: Injector,
    private readonly _localStorageService: LocalStorageService,
    private readonly _commonService: CommonService
  ) {
    super(injector);
    this.getAllLeadLists();

    this._agentService.getAllReferralSources().subscribe(referralSources => {
      this._referralSources = referralSources;
    });

    this._configurationService.getCompanyConfiguration('LoanHiddenFields').subscribe(loanHiddenFields => {
      let hiddenFields = loanHiddenFields ? loanHiddenFields['valueStr'] : '';
      this._isSubjectPropertyAddressHidden = hiddenFields.indexOf('Subject Property') > -1;
    });

    this._leadUpdatesSubscription = this._leadService.updatedLead.subscribe((res: { leadId: number, note: string }) => {
      this._lastNote = res.note;
      this.updateRow(res.leadId);
    })

  }

  ngOnInit(): void {
    if (this.leadTableConfig?.localStorageKey) {
      this._selectedColumnsLocalStorageKeyName = this.leadTableConfig.localStorageKey;
    }
    super.scrollOffset = this.leadTableConfig.scrollOffset;
    this.getScreenSize();
    this.applicationContextService.context.subscribe(context => {
      this.dialerEnabled = context.userPermissions.dialerEnabled;
      this._isAdmin = context.userPermissions.admin;
      this._leadStatuses = context.globalConfig.leadStatus || [];
      this._loanPurposes = context.globalConfig.loanPurpose || [];
      this._loanTypes = context.globalConfig.loanType || [];
      this.users = context.globalConfig.users || [];
      this.leadCampaigns = context.globalConfig.leadCampaigns || [];
      this.isTpoUser = context.userPermissions.userType === 'TPO';
      this.populateOtherLeadPropertiesToDisplayOnTable(this._leads);
    });

    this.loadColumnsToDisplayOnTable();

    this.globalFilterFields = ["displayName", "leadStatusName", "leadCampaignName", "loanPurposeName", "leadContactName", "referralSourceName"];

    this.leadTableConfig.extraGlobalFilterFields.forEach(field => {
      this.globalFilterFields.push(field);
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const localTableState = this._localStorageService.getItem('leads-table-state');
      this._localStorageService.removeItem('leads-table-state')
      this.tableState = this.webPreferences.leadsPreferences?.leadsTableState;

      if (localTableState && localTableState != this.tableState) {
        this.tableState = localTableState;
        this.webPreferences.leadsPreferences.leadsTableState = localTableState;
        this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
          this._localStorageService.setItem('leads-table-state', this.tableState);
        });
      }

      if (!this.tableState) {
        this.tableState = {
          multiSortMeta: [{
            field: 'dateInserted',
            order: 1
          }]
        }
        this.webPreferences.leadsPreferences.leadsTableState = this.tableState;
      }
      this.selectedRows = [];
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._applicationContextSubscription) {
      this._applicationContextSubscription.unsubscribe();
    }
    if (this._systemLevelSubscription) {
      this._systemLevelSubscription.unsubscribe();
    }
    if (this._leadUpdatesSubscription) {
      this._leadUpdatesSubscription.unsubscribe();
    }
  }

  updateAfterAddOrEdit = (leadId: number) => {
    const existingLead = this._leads.find(l => l.leadId == leadId);
    if (!existingLead) { // Added a new one
      this.getLeads();
    }
    else {
      this.updateRow(leadId);
    }
  }

  viewLead = (leadId: number): void => {
    let modalRef = this._modalService.open(ViewLeadDrawerComponent, Constants.modalOptions.fullScreen);
    modalRef.componentInstance.leadId = leadId;

    modalRef.result.then(() => {
      this.getAllLeadLists();
    }, () => {
      this.updateRow(leadId);
      this.getAllLeadLists();
    });
  }

  updateRow = (leadId: number) => {
    this._leadService.getLead(leadId)
      .pipe(
        finalize(() => {
          this._lastNote = "";
        })
      )
      .subscribe(lead => {
        lead.mostRecentNote = lead.mostRecentNote ? lead.mostRecentNote : this._lastNote;
        this._leads = this._leads.map(l => l.leadId == leadId ? lead : l);
        this.filteredLeads = this.filteredLeads.map(l => l.leadId == leadId ? lead : l);
        this.populateOtherLeadPropertiesToDisplayOnTable(this._leads);
        this.populateOtherLeadPropertiesToDisplayOnTable(this.filteredLeads);
      });
  }

  openReassignModal = () => {
    let modalRef = this._modalService.open(ReassignLeadUserConfirmationDialogComponent, Constants.modalOptions.medium);

    modalRef.componentInstance.leadIds = this.selectedRows.map(r => r.leadId);
    modalRef.componentInstance.users = this.users;

    modalRef.result.then((selectedReassignUserGUID: string) => {
      this._leads.forEach(lead => {
        const index = this.selectedRows.findIndex(l => l.leadId === lead.leadId);
        if (index > -1) {
          this.populateLeadContactName(lead, selectedReassignUserGUID);
        }
      })

      this._leads = [...this._leads];
      this.selectedRows = [];
    }, () => {
    });

  }

  getLeads = (): void => {
    if (this.filters.dateRange && this.isFilterDateRangeInValid(this.filters.dateRange)) {
      return;
    }
    this.isLoading = true;

    let observable: Observable<Lead[]>;

    if (this.showUnassignedLeads) {
      observable = this._leadService.getUnassignedLeads(this.filters);
    }
    else {
      observable = this._leadService.getLeads(this.filters);
    }

    this._spinner.show();
    observable.pipe(finalize(() => {
      this.isLoading = false;
      this._spinner.hide();
    })).subscribe({
      next: (result: Lead[]) => {
        this._leads = [...result];
        this.populateOtherLeadPropertiesToDisplayOnTable(result);
        let filteredLeads = [...result];
        this.filteredLeads = Utils.filter(this.globalFilterFields, this.globalSearchString, filteredLeads);
        this.gotoPageOne();
      },
      error: (error) => {
        this.error = error?.message || "Error encountered while loading leads";
        this._notifyService.showError(this.error, "Error!");
      },
    })
  }

  getTodayLeads = (): void => {
    if (this.isFilterDateRangeInValid(this.filters.dateRange)) {
      return;
    }

    const nowDate = DateTime.now();
    let _filters = {
      dateRange: {
        displayText: "Today",
        name: "Today",
        id: 1,
        startDate: nowDate.startOf('day').toJSDate(),
        endDate: nowDate.endOf('day').toJSDate()
      },
      branchId: this.filters?.branchId,
      campaignId: this.filters?.campaignId,
      leadListId: this.filters?.leadListId,
      showArchived: this.filters?.showArchived,
      showUserId: this.filters?.showUserId,
      leadStatusIds: this.filters?.leadStatusIds,
      state: this.filters?.state,
      firstName: this.filters?.firstName,
      lastName: this.filters?.lastName
    };

    if (!_filters.dateRange || _filters.dateRange.displayText !== "Today") {
      _filters.dateRange.displayText = "Today";
      _filters.dateRange.name = "Today";
      _filters.dateRange.id = 1;
      _filters.dateRange.startDate = nowDate.startOf('day').toJSDate();
      _filters.dateRange.endDate = nowDate.endOf('day').toJSDate();
    }

    this.isLoading = true;

    let observable: Observable<Lead[]>;

    if (this.showUnassignedLeads) {
      observable = this._leadService.getUnassignedLeads(_filters);
    }
    else {
      observable = this._leadService.getLeads(_filters);
    }

    this._spinner.show();
    observable.pipe(finalize(() => {
      this.isLoading = false;
      this._spinner.hide();
    })).subscribe({
      next: (result: Lead[]) => {
        this.populateOtherLeadPropertiesToDisplayOnTable(result);
        this._leads.forEach(l => {
          const existingLead = result.find(rl => rl.leadId === l.leadId)
          if (existingLead) l = existingLead; // udapte lead
        })
        const _result = result.filter(rl => !this._leads.some(ll => ll.leadId === rl.leadId)); // filter not to have
        let filteredLeads = [...this._leads, ..._result];
        this.filteredLeads = Utils.filter(this.globalFilterFields, this.globalSearchString, filteredLeads);
      },
      error: (error) => {
        this.error = error?.message || "Error encountered while loading leads";
        this._notifyService.showError(this.error, "Error!");
      },
    })
  }

  private isFilterDateRangeInValid = (dateRange: IDateRange) => {
    if (!dateRange.startDate || !dateRange.endDate) return false;
    const startDate = Date.parse(dateRange.startDate.toString());
    const endDate = Date.parse(dateRange.endDate.toString());
    return isNaN(startDate) || isNaN(endDate);
  }

  onViewLeadDetailsClicked = (leadId: number) => {
    this.viewLead(leadId);
  }

  onEditLeadDetailsClicked = (lead: Lead) => {
    this.editRequestedForLead.emit(lead);
  }

  onDeleteLeadClicked = (leadId: number) => {
    if (!this._isAdmin) {
      this._notifyService.showError("You do not have permission to do that!", "No permission");
      return;
    }
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this Lead?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then(function (result: SweetAlertResult) {
      if (!result.value) {
        return;
      }
      self._leadService.deleteLead(leadId)
        .subscribe(() => {
          self._notifyService.showSuccess("Lead deleted succesfully.", "Success!");
          self.getLeads();
        }, (err) => {
          self._notifyService.showError(err?.message || "Error encountered while deleting lead.", "Error!");
        });
    });
  }

  getSubjectPropertyText = (rowData: Lead): string => {

    let addr = '';
    if (
      typeof rowData.subjectPropertyAddress1 === 'string'
      &&
      !this._isSubjectPropertyAddressHidden
    ) {
      addr = rowData.subjectPropertyAddress1;
    }
    let city = '';
    if (typeof rowData.subjectPropertyCity === 'string') {
      city = rowData.subjectPropertyCity;
    }
    let state = '';
    if (typeof rowData.subjectPropertyState === 'string') {
      state = rowData.subjectPropertyState.toUpperCase();
    }

    let zip = '';
    if (typeof rowData.subjectPropertyZip === 'string') {
      zip = rowData.subjectPropertyZip.toUpperCase();
    }
    if (addr.length > 0)
      addr += "<br>";

    if (city.length > 0)
      city += ", ";

    return addr + city + state + " " + zip;
  }

  getInterestRateText = (rate: number): string => {
    if (rate <= 1) {
      rate = rate * 100;
    }

    return (rate).toFixed(3) + "%";
  }

  getLeadListNameById = (leadListId: number): string => {
    if (!this.leadLists) {
      return "";
    }
    let matched = this.leadLists.filter(leadList => leadList.leadListId == leadListId)[0];
    return matched ? matched.name : "";
  }

  onGlobalSearchStringChanged = () => {
    this.filteredLeads = Utils.filter(this.globalFilterFields, this.globalSearchString, this._leads);
    this.tableState.first = 0;
  }

  addedToDialList = () => {
    this.selectedRows = [];
  }

  exportToCSV = () => {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Leads');

    const columnsToExport = [];
    [...this.exportOnlyColumnsMapping.keys()].forEach(key => {
      if (this.leadTableConfig.columns.every(col => col.field !== key)) { // hidden fields that we want to export
        columnsToExport.push(...this.exportOnlyColumnsMapping.get(key));
      } else {
        if (this.selectedColumns.some(col => col.field === key)) {
          columnsToExport.push(...this.exportOnlyColumnsMapping.get(key));
        }
      }
    })

    const orderedColumnsToExport = columnsToExport.map(col => ({ ...col, key: col.field }));
    worksheet.columns = orderedColumnsToExport;

    this.filteredLeads.forEach(row => {
      const excelRow = {};
      orderedColumnsToExport.forEach(c => {
        excelRow[c.key] = row[c.key] || '';
      });
      excelRow["leadStatusId"] = row["leadStatusName"];
      excelRow["leadContactUserId"] = row["leadContactName"];
      excelRow["referralSource"] = row["referralSourceName"]
      worksheet.addRow(excelRow);
    })

    workbook.csv.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Leads.csv");
    });
  }

  openColumnSelectorForMobile = () => {
    setTimeout(() => {
      this.mobileColumnSelector?.containerViewChild?.nativeElement?.click();
    })
  }

  onStateSave = (event) => {
    const colOrder: string[] = event.columnOrder || [];
    if (colOrder.length > 0) {
      const columnsWithOrder = this.leadTableConfig.columns.filter(col => colOrder.indexOf(col.field) > -1).sort((a, b) => colOrder.indexOf(a.field) - colOrder.indexOf(b.field));
      const columnsWithoutOrder = this.leadTableConfig.columns.filter(col => colOrder.indexOf(col.field) === -1);
      this.leadTableConfig.columns = [...columnsWithOrder, ...columnsWithoutOrder].map((col, index) => ({
        ...col,
        order: col.order = index + 1
      }));
    }

    this.selectedColumns.forEach((col, index) => col.order = index + 1);

    this.persistTableState();
  }

  onColumnSelectionChanged = () => {
    this.persistTableState();
  }

  onSearchReset = () => {
    this.globalSearchString = "";
    this.filteredLeads = [...this.filteredLeads];
  }

  private persistTableState = () => {
    let localTableState = this._localStorageService.getItem('leads-table-state') as any;
    this._localStorageService.removeItem('leads-table-state');
    if (this.webPreferences) {
      this.webPreferences.leadsPreferences.leadsTableSelectedColumns = this._selectedColumns;
      this.webPreferences.leadsPreferences.leadsTableState = localTableState;
      this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
        this._localStorageService.setItem('leads-table-state', localTableState);
        this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this.selectedColumns);
      });
    }
  }

  private loadColumnsToDisplayOnTable = () => {

    const localSelectedColumns: Column[] = this._localStorageService.getItem(this._selectedColumnsLocalStorageKeyName);

    if (localSelectedColumns) {
      this.webPreferences.leadsPreferences.leadsTableSelectedColumns = localSelectedColumns;
      this._localStorageService.removeItem(this._selectedColumnsLocalStorageKeyName);
    }

    let selectedColumns: Column[] = this.webPreferences.leadsPreferences.leadsTableSelectedColumns;

    if (!selectedColumns?.length) {
      this.selectedColumns = [];
      this.leadTableConfig.columns.forEach(column => {
        if (column.visible) {
          this.selectedColumns.push(column);
        }
      });

      // backwards compatibility: removing 'Lead Status' column that is no longer in use
      const colIndex = this.selectedColumns.findIndex(c => c.header === "Lead Status");
      if (colIndex > -1) {
        this.selectedColumns.splice(colIndex, 1);
      }

      this.webPreferences.leadsPreferences.leadsTableSelectedColumns = this.selectedColumns;
      this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
        this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this._selectedColumns);
      });
    } else {
      // protect from having two the same columns
      selectedColumns = selectedColumns.filter(selectedCol =>
        this.leadTableConfig.columns.findIndex(col => col.header === selectedCol.header && col.field === selectedCol.field) > -1);

      this.selectedColumns = [];
      selectedColumns.forEach(column => {
        this.leadTableConfig.columns.forEach(c => {
          if (column['field'] === c.field) {
            this.selectedColumns.push(column);
          }
        })
      });

      // backwards compatibility: removing 'Lead Status' column that is no longer in use
      const colIndex = this.selectedColumns.findIndex(c => c.header === "Lead Status");
      if (colIndex > -1) {
        this.selectedColumns.splice(colIndex, 1);
      }

      this.webPreferences.leadsPreferences.leadsTableSelectedColumns = this.selectedColumns;
      this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
        this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this._selectedColumns);
      });
    }
  }

  private getAllLeadLists = () => {
    this._leadService.getLeadLists().subscribe(lists => {
      this.leadLists = lists.filter(l => l.name != null && l.name.length > 0).sort((a, b) => a.name.localeCompare(b.name)); // name asc
    });
  }

  private populateOtherLeadPropertiesToDisplayOnTable = (leads: Lead[]): void => {
    leads.map(lead => {
      lead['displayName'] = Utils.getPersonsDisplayName(lead);
      // populate matched lead status
      const matchedLeadStatus = this._leadStatuses.find(status => status.loanStatusId == lead.leadStatusId);
      if (matchedLeadStatus) {
        lead["leadStatusName"] = matchedLeadStatus.loanStatusName;
      }

      // populate latest note of lead
      //this.getLatestNote(lead); DO NOT DO THIS HERE, IT WILL MAKE TOO MANY REQUESTS

      // populate matched lead source
      const matchedLeadCampaign = this.leadCampaigns.find(campaign => campaign.leadCampaignId == lead.leadCampaignId);
      if (matchedLeadCampaign) {
        lead["leadCampaignName"] = matchedLeadCampaign.name;
      }

      // populate matched loan purpose
      const matchedLoanPurpose = this._loanPurposes.find(loanPurpose => loanPurpose.loanPurposeId == lead.loanPurposeId);
      if (matchedLoanPurpose) {
        lead["loanPurposeName"] = matchedLoanPurpose.loanPurposeName;
      }

      // populate matched loan type
      const matchedLoanType = this._loanTypes.find(loanType => loanType.loanTypeId == lead.loanTypeId);
      if (matchedLoanType) {
        lead["loanTypeName"] = matchedLoanType.loanTypeName;
      }

      // populate matched lead contact
      this.populateLeadContactName(lead, lead.leadContactUserId);

      // populate matched referral source
      var referralSourceItem = this._referralSources?.find(agent => agent.agentId == lead.referralSource);
      if (referralSourceItem) {
        lead["referralSourceName"] = Utils.getPersonsDisplayName(referralSourceItem);
      }

      return lead;
    });

    let distinctLeadListIds = [...new Set(leads.map(l => l.leadListIds).flat())];

    let haveNewLeadLists = distinctLeadListIds.filter(id => !this.leadLists.map(l => l.leadListId).includes(id));

    if (haveNewLeadLists.length) {
      this.getAllLeadLists();
    }
  }

  private populateLeadContactName = (lead: Lead, leadContactUserId: string) => {
    const matchedLeadContact = this.users.find(user => user.userCompanyGuid == leadContactUserId);
    if (matchedLeadContact) {
      lead["leadContactName"] = Utils.getPersonsDisplayName(matchedLeadContact);
    }
  }

  private gotoPageOne = () => {
    setTimeout(() => {
      this.leadTable.first = 0;
      this.leadTable.firstChange.emit(this.leadTable.first);
    }, 0);
  }
}
