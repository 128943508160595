<ng-container *ngIf="!showLoading; else loading">
  <div class="card-body chat-body" data-simplebar>
    <div class="chat-detail"
      *ngFor="let messageGroup of conversation.conversationMessages | groupBy: 'groupDate' | sort: 'asc':'groupDate'">
      <span class="chat-group">{{messageGroup.key ? formatGroupDate(messageGroup.key) : ""}}</span>
      <div class="media mt-3" *ngFor="let message of messageGroup.value | sort: 'asc':'dateInserted'">
        <div class="media-body" [ngClass]="{'reverse': message.direction == 'Outbound'}">
          <div class="message-data">
            <span *ngIf="message['fromFullName'] || message.senderName" class="message-data-name">
              - {{message['fromFullName'] || message.senderName}}
            </span>
            <span class="message-data-time">
              {{message.dateInserted | date:'MMM d, y h:mm:ss a'}}
            </span>
          </div>
          <div class="chat-msg custom-chat-msg">
            <div class="p message">
              <span style="display: inline-block" [hidden]="!message.body" [innerHtml]="message.body"></span>
              <img [src]="message['mentionTrackingUrl']" *ngIf="message.mentionTrackingGuid" class="tracker-image"
                width="1" height="1" border="0">
              <div class="d-flex flex-wrap" *ngIf="message.hasMediaFiles" [ngClass]="{'mt-2': message.body}">
                <div class="img-container" *ngFor="let mediaFile of message.mediaFiles">
                  <i *ngIf="!mediaFile" class="d-block task-value fas fa-sync-alt fa-spin mx-1 mt-1"
                    style="font-size: 25px; margin-top: 4px; margin-bottom: 4px;"></i>
                  <ng-container *ngIf="mediaFile">
                    <img *ngIf="mediaFile.changingThisBreaksApplicationSecurity.startsWith('data:image')"
                      [src]="mediaFile" height="140" alt="" class="mx-1 mt-1">
                    <video *ngIf="mediaFile.changingThisBreaksApplicationSecurity.startsWith('data:video')"
                      [src]="mediaFile" height="140" alt="" class="mx-1 mt-1">
                    </video>
                    <div class="overlay">
                      <a class="icon" title="View" (click)="viewImage(mediaFile)">
                        <i class="fa fa-eye"></i>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="conversation-end" style="height: 10px;"></div>
  </div>
</ng-container>

<ng-template #loading>
  <div class="card-body chat-body">
    <div class="widget-center">
      <div class="spinner-border thumb-md text-primary" role="status"></div>
    </div>
  </div>
</ng-template>
<div class="chat-footer card-footer">
  <div class="row footer-features">
    <div class="col-md-12">
      <textarea [hidden]="conversation.chatMedium != 'Sms'" id="messageEditor" name="messageEditor" rows="3" cols="30"
        style="resize: none; border: none" class="form-control" placeholder="Type something here..."
        (keydown)="onKeyDownOnMessageEditor($event)" [(ngModel)]="messageToSend">
      </textarea>

      <message-editor-with-mentions [hidden]="conversation.chatMedium == 'Sms'" #messageEditorWithMentions
        [mentionables]="usersThatCanBeMentioned" (messageChanged)="onMessageChanged($event)">
      </message-editor-with-mentions>

    </div>
    <div class="col-md-12 row pb-2 ">
      <div class="d-flex flex-wrap col-sm-11" name="attachments" id="attachments">
        <div class="d-flex ms-2 mb-2" *ngFor="let attachment of attachments; let index = index">
          <img *ngIf="attachment.file.type.startsWith('image')" [src]="attachment.base64 | safeUrl" [height]="30"
            (error)="onImageError(attachment)" class=" rounded" />
          <video *ngIf="attachment.file.type.startsWith('video')" [src]="attachment.base64 | safeUrl" [height]="30"
            (error)="onImageError(attachment)" class=" rounded">
          </video>
          <i class="fa fa-trash-alt cursor text-danger mt-2 ms-1" (click)="deleteAttachment(attachment)"></i>
        </div>
      </div>
      <div class="bottom-features col-sm-1 text-end">
        <input type="file" [accept]="accept" style="display:none" #addAttachment [(ngModel)]="fakeData"
          (change)="handleFileUpload($event)" multiple>
        <a href="javascript:void(0)" class="me-2" (click)="addAttachment.click()"
          *ngIf="conversation.chatMedium == 'Sms' "><i class="fas fa-paperclip"></i></a>
        <a href="javascript:void(0)" *ngIf="!isSendingMessage" (click)="sendMessage()"><i
            class="far fa-paper-plane"></i></a>
        <a href="javascript:void(0)" *ngIf="isSendingMessage"><i class="fas fa-sync-alt fa-spin"></i></a>
      </div>
    </div>
  </div>
  <em *ngIf="conversation.chatMedium === 'Sms'" class="text-end d-block fw-bold">Press Ctrl+Enter to add a new line</em>
</div>