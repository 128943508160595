import { Component, HostListener, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Utils } from 'src/app/core/services/utils';
import { IHaveMortgageLtvCalculationDetails, MortgageCalculationDetails, MortgageDtiCalculationDetails, MortgageFundsToCloseCalculationDetails } from 'src/app/models/mortgage-calculation-details.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Constants } from 'src/app/services/constants';
import { LoanService } from 'src/app/services/loan';
import { LoanPurpose, TransactionType } from 'src/app/models/config/loan-purpose.model';
import { ApplicationContext, Borrower, DataExchangeSyncDir, LoanApplication, LoanStatus, LosLdeConnectionStatus, MortgageLanguage, UserPermissions } from 'src/app/models';
import { BorrowerCharacteristic, LoanCharacteristic } from 'src/app/models/characteristics';
import { UrlaMortgage } from '../../urla/models/urla-mortgage.model';
import { LoanType } from 'src/app/models/config/loan-type.model';
import { NotificationService } from 'src/app/services/notification.service';
import { MortgageCalculationService } from '../../urla/services/mortgage-calculation.service';
import { LoanStatusHistoryDialogComponent } from '../../app-details/components/loan-status-history-dialog/loan-status-history-dialog.component';
import { LoanStatusHistoryItem } from 'src/app/models/loan/loan-status-history.model';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'loan-summary-bar',
  templateUrl: './loan-summary-bar.component.html',
  styleUrls: ['./loan-summary-bar.component.scss'],
})
export class LoanSummaryBarComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @Input()
  options: LoanSummaryBarOptions;

  application: LoanApplication;
  mortgage: UrlaMortgage;

  borrowers: Borrower[] = [];
  permissions: UserPermissions;

  @Input()
  hasRateLockExpirationKeyDate: boolean;

  protected isLoanHeaderExpanded: boolean;

  losEnabled: boolean;
  ldeEnabled: boolean;

  losConnectionStatus: LosLdeConnectionStatus;
  ldeConnectionStatus: LosLdeConnectionStatus;

  protected get isSolarTransactionType(): boolean {
    return this.loanPurposeTransactionType === 'Solar';
  }

  protected get showLosLdeButton(): boolean {
    const {
      losEnabled,
      ldeEnabled,
    } = this;

    let turnedOffByOptions = false;
    turnedOffByOptions = this.options && !this.options?.showLosLdeSummary;

    return (losEnabled || ldeEnabled) && !this.isSolarTransactionType && !turnedOffByOptions;
  };

  private _loanPurposes: LoanPurpose[];
  private _loanTypes: LoanType[];
  private _loanStatuses: LoanStatus[];

  ficoOnFile: number | undefined;

  interestRate: number | undefined;

  dtiDetails: MortgageDtiCalculationDetails = null;
  ftcDetails: MortgageFundsToCloseCalculationDetails = null;
  ltvDetails: IHaveMortgageLtvCalculationDetails = null;

  ftcSectionAmounts: any = {};

  currentLoanStatusName: string;

  isRefinance: boolean = false;

  borrowerCharacteristics: BorrowerCharacteristic[] = [];

  loanCharacteristics: LoanCharacteristic[] = [];

  loanPurposeTransactionType: TransactionType;

  systemGenerateDocumentMenuActive: boolean = false;

  primaryBorrower: Borrower = null;

  borrowerName: string = null;

  applicationIdWithPadding: string = null;

  loanPurposeName: string = null;

  loanTypeName: string = null;

  dialerEnabled: boolean = false;

  protected isTpo: boolean = false;

  protected isDisclosureValid: boolean;
  protected doesDisclosureIncludeCD: boolean;
  protected isTitleOrderValid: boolean;
  protected disableBorrowerPortalInviteLink: boolean = false;

  protected defaultLanguagePreference: MortgageLanguage = null;

  private _loanInfoChangesSubscription: Subscription;
  private _contextSubscription: Subscription;
  private _taskSubscription: Subscription;
  private _routerEventsSubscription: Subscription;
  private _keyDatesSubscription: Subscription;

  private _shownPopover: PopoverDirective | undefined;

  constructor(
    private readonly injector: Injector,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _loanService: LoanService,
    private readonly _modalService: NgbModal,
    private readonly _notificationService: NotificationService,
    private readonly _mortgageCalculationsService: MortgageCalculationService,
    private readonly _configurationService: ConfigurationService
  ) {
    super(injector);
  }

  ngOnInit() {
    this._contextSubscription = this.applicationContextService.context.subscribe((context) => {
      this._loanTypes = context.globalConfig.loanType;
      this._loanPurposes = context.globalConfig.loanPurpose;
      this._loanStatuses = context.globalConfig.loanStatus;
      this.permissions = this.applicationContext.userPermissions;
      this.losEnabled = this.permissions.losEnabled;
      this.ldeEnabled = this.permissions.ldeEnabled;
      this.isTpo = context.isTpo;
    });

    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.isRefinance = this._mortgageCalculationsService.isPurposeOfLoanRefinance(context.currentMortgage);
        this.populateLoanInfo(context);
        var currentLoanStatus = this._loanStatuses.find(x => x.loanStatusId === context.application.loanStatusId);
        if (currentLoanStatus) {
          this.currentLoanStatusName = currentLoanStatus.loanStatusName;
        }
      }
    });

    this._taskSubscription = this.applicationContextService.loanTasksChanges.subscribe((context) => {
      if (!context.application) {
        return;
      }
      const newLoanStatus = this._loanStatuses.find(s => s.loanStatusId == this.application.loanStatusId);
      this.currentLoanStatusName = newLoanStatus?.loanStatusName;
    });

    this.onWindowResize();
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
    this._loanInfoChangesSubscription?.unsubscribe();
    this._contextSubscription?.unsubscribe();
    this._routerEventsSubscription?.unsubscribe();
    this._taskSubscription?.unsubscribe();
    this._keyDatesSubscription?.unsubscribe();

    super.ngOnDestroy();
  }

  @HostListener('window:resize')
  onWindowResize = () => {
    this.isLoanHeaderExpanded = window.innerWidth > 1429;
  };

  @HostListener('document:click', ['$event'])
  onClickBody = (event: Event) => {
    const { _shownPopover } = this;
    if (_shownPopover == null) {
      return;
    }

    const path = event.composedPath() as HTMLElement[];

    const pathContainsClass = (className: string) => path.some((e) => e.classList?.contains(className));

    const preventHideCheckClasses = [
      // Clicked on shown popover.
      'popover-body',
      // Clicked on the popover button of the currently shown popover to open it in the first place.
      'loan-btn',
      // Clicked on a modal that is opened from the shown popover.
      'modal',
    ];
    if (preventHideCheckClasses.some(pathContainsClass)) {
      return;
    }

    _shownPopover.hide();
  };

  onRepriceLoanClicked = () => {
    this._shownPopover?.hide();
  }

  onShownPopover(ref: PopoverDirective) {
    const setRef = () => this._shownPopover = ref;

    if (this._shownPopover) {
      this._shownPopover.hide();
      setTimeout(setRef);
    } else {
      setRef();
    }
  }

  onHiddenPopover() {
    this._shownPopover = undefined;
  }

  onCharacteristicsSaved = () => {
    this.applicationContextService.updateLoanTasks();
  };

  onCharacteristicsPopoverClose = () => {
    this._shownPopover?.hide();
  };

  onLoanStatusHistoryClicked = () => {
    this._spinnerService.show();
    this._loanService.getLoanStatusHistory(this.application.applicationId).subscribe((history: LoanStatusHistoryItem[]) => {
      const modalRef = this._modalService.open(LoanStatusHistoryDialogComponent, Constants.modalOptions.large);
      modalRef.componentInstance.history = history;
      this._spinnerService.hide();
    }, error => {
      this._spinnerService.hide();
      this._notificationService.showError(
        error ? error.message : 'Unable to get loan status history.',
        'Error!',
      );
    });
  };

  onLdeSyncInfoUpdated = () => {
    this.populateLoanInfo(this.applicationContext);
  };

  onCloseLoanRatios = () => {
    this._shownPopover?.hide();
  };

  onCloseDtiDetailsClicked = () => {
    this._shownPopover?.hide();
  }

  onLosSyncDirectionUpdated = () => {
    this.populateLoanInfo(this.applicationContext);
  };

  onDtiRecalculationStarted = () => {
    this.dtiDetails = null;
  }

  onDtiRecalculationCompleted = (dtiDetails: MortgageDtiCalculationDetails) => {
    this.dtiDetails = dtiDetails;
  }

  onFtcRecalculationStarted = () => {
    this.ftcDetails = null;
  }

  onFtcRecalculationCompleted = (ftcDetails: MortgageFundsToCloseCalculationDetails) => {
    this.ftcDetails = ftcDetails;
  }

  protected getTitle = (): string => {
    if (this.currentLoanStatusName) {
      const dynamicText = document.getElementById('currentLoanStatusName');
      return dynamicText.textContent.length > 15 ? dynamicText.textContent : '';
    }
  }

  private populateLoanInfo = (context: ApplicationContext) => {
    if (context.application) {
      this.application = context.application;
      this.mortgage = context.currentMortgage;
      this.isDisclosureValid = context.currentMortgage.transactionDetail?.isDisclosureValid;
      this.doesDisclosureIncludeCD = context.currentMortgage.transactionDetail?.doesDisclosureIncludeCD;
      this.isTitleOrderValid = context.currentMortgage.transactionDetail?.isTitleOrderValid;

      this.dialerEnabled = context.userPermissions.dialerEnabled;

      this.defaultLanguagePreference = context.currentMortgage.borrowers[0]?.languagePreference ?? context.application.languagePreference ?? "English";

      this.losConnectionStatus = this.getLosConnectionStatus(this.application);
      this.ldeConnectionStatus = this.getLdeConnectionStatus(this.application);

      if (this.mortgage) {
        this.loadInitialDti(context.currentMortgageCalculationDetails);
        this.loadInitialFtc(context.currentMortgageCalculationDetails);
        this.loadInitialLtv(context.currentMortgageCalculationDetails);
        const creditScores = this.mortgage.borrowers.filter(b => b.creditScore).map(b => b.creditScore);
        if (creditScores && creditScores.length) {
          this.ficoOnFile = this.application.losCreditScore ?? Math.min(...creditScores);
        } else {
          this.ficoOnFile = this.application.losCreditScore ?? undefined;
        }
      }

      this.loanPurposeTransactionType = context.globalConfig.loanPurpose.find(x => x.loanPurposeId == context.application.loanPurposeId)?.transactionType;

      this.applicationIdWithPadding = 1 + Utils.padLeft(this.application.applicationId + '', '0', 9);

      this.borrowers = context.borrowers;

      this.borrowers.forEach(b => {
        b.fullName = Utils.getBorrowerFullName(b);
      });

      this.primaryBorrower = this.borrowers.find(b => b.borrowerId == this.application.primaryBorrowerId);
      if (this.primaryBorrower) {
        this.borrowerName = Utils.getPersonsDisplayName(this.primaryBorrower);
      } else {
        if (context.borrowers.length > 0) {
          this.borrowerName = Utils.getPersonsDisplayName(context.borrowers[0]);
        }
      }

      if (context.application.productPricing) {
        this.interestRate = context.application.productPricing.rate / 100;
      }

      const loanPurpose = this._loanPurposes.find(
        (lp) =>
          lp.loanPurposeId ===
          context.application.loanPurposeId,
      );
      if (loanPurpose) {
        this.loanPurposeName = loanPurpose.loanPurposeName;
      }

      const loanType = this._loanTypes.find(
        (lt) =>
          lt.loanTypeId === context.application.loanTypeId,
      );
      if (loanType) {
        this.loanTypeName = loanType.loanTypeName;
      }

      const loanStatus = this._loanStatuses.find(ls => ls.loanStatusId === context.application.loanStatusId);
      if (loanStatus) {
        //this.loanStatus = loanStatus.loanStatusName;
      }

      this.populateDisabledBorrowerInviteChannels();
    }
  };

  private loadInitialLtv = (calculationDetails: MortgageCalculationDetails) => {
    // initially load it from the mortgage object, they are already calculated and are in transaction detail
    if (calculationDetails) {
      this.ltvDetails = calculationDetails.ltv;
    }
  };

  private loadInitialDti = (calculationDetails: MortgageCalculationDetails) => {
    // initially load it from the mortgage object, they are already calculated and are in transaction detail
    if (calculationDetails) {
      this.dtiDetails = calculationDetails.dti;
    }
  };

  private loadInitialFtc = (calculationDetails: MortgageCalculationDetails) => {
    // initially load it from the mortgage object, they are already calculated and are in transaction detail
    if (calculationDetails) {
      this.ftcDetails = calculationDetails.fundsToClose;
    }
  };

  private getLdeConnectionStatus = (application: LoanApplication): LosLdeConnectionStatus => {
    if (!!(application.ldeIdentifier && application.ldeVendor && application.ldeSyncDir)) {
      switch (application.ldeSyncDir) {
        case DataExchangeSyncDir.None:
          return LosLdeConnectionStatus.Referenced;
        case DataExchangeSyncDir.LosToLoda:
          return LosLdeConnectionStatus.LosToLoda;
        case DataExchangeSyncDir.Both:
          return LosLdeConnectionStatus.FullSync;
      }
    } else if (!!application.ldeRefNumber) {
      return LosLdeConnectionStatus.Referenced;
    } else {
      return LosLdeConnectionStatus.None;
    }
  };

  private getLosConnectionStatus = (application: LoanApplication): LosLdeConnectionStatus => {
    if (!!(application.losIdentifier && application.losVendor && application.losSyncDir)) {
      switch (this.application.losSyncDir) {
        case DataExchangeSyncDir.None:
          return LosLdeConnectionStatus.Referenced;
        case DataExchangeSyncDir.LosToLoda:
          return LosLdeConnectionStatus.LosToLoda;
        case DataExchangeSyncDir.Both:
          return LosLdeConnectionStatus.FullSync;
      }
    } else if (!!this.application.refNumber) {
      return LosLdeConnectionStatus.Referenced;
    } else {
      return LosLdeConnectionStatus.None;
    }
  }

  private populateDisabledBorrowerInviteChannels = () => {
    this._spinnerService.show();
    this._configurationService.getCompanyConfiguration('BorrowerInviteDisabledChannels').subscribe({
      next: (borrowerInviteDisabledChannels) => {
        this._spinnerService.hide();

        if (borrowerInviteDisabledChannels?.valueStr?.trim()) {
          const channels = borrowerInviteDisabledChannels.valueStr?.split(',');
          if (!channels) {
            return;
          }

          let disabledBorrowerInviteChannels = [];
          channels.forEach(disabledChannel => {
            const companyChannels = this.applicationContext.globalConfig.enabledChannels;

            const channel = companyChannels.find(c => c.name == disabledChannel);
            if (channel) {
              disabledBorrowerInviteChannels.push(channel.name);
            }
          });

          if (disabledBorrowerInviteChannels.length && disabledBorrowerInviteChannels.includes(this.application.channel)) {
            this.disableBorrowerPortalInviteLink = true;
          }
        }
      },
      error: (err) => {
        this._spinnerService.hide();
        this._notificationService.showError(err.message || 'Unable to get system level.','Error!');
      }
    })

  }
}

export class LoanSummaryBarOptions {
  showLosLdeSummary: boolean = true;
  showCharacteristicsSummary: boolean = true;
  showStatusSummary: boolean = true;
}
