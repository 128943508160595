import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Borrower, LoanDoc } from 'src/app/models';
import { LoanActivityService } from 'src/app/modules/loan-activity/services/loan-activity.service';
import { ConditionsTask } from 'src/app/models/task/conditons-task.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/services/constants';
import { FileService } from 'src/app/services/file.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskService, TpoTaskFilterEnum } from 'src/app/services/task.service';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { partition } from 'lodash';
import { EditTaskDialogComponent } from 'src/app/modules/tasks/components/edit-task-dialog/edit-task-dialog.component';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { DocFilesEditDialogComponent } from 'src/app/modules/loan-docs/components/doc-files-edit-dialog/doc-files-edit-dialog.component';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { FileDto } from 'src/app/models/borrower/file-dto.model';
import { handleNonErrorDismissals, Utils } from 'src/app/core/services/utils';
import { User } from 'src/app/models/user/user.model';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { Table } from 'primeng/table';
import { MergeDocFilesRequest } from 'src/app/modules/loan-docs/models/merge-files/merge-doc-files-request.model';
import { TaskEmailSenderModalComponent } from '../task-email-sender-modal/task-email-sender-modal.component';
import { catchError, EMPTY, from, Subscription } from 'rxjs';

@Component({
  selector: 'conditions-table',
  templateUrl: 'conditions-table.component.html',
  styleUrls: ['./conditions-table.component.scss']
})

export class ConditionsTableComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @ViewChild(ContextMenuComponent)
  basicMenu: ContextMenuComponent;

  @ViewChild('eSignConditionTable') eSignConditionTable: Table;

  @Input()
  otherConditions: ConditionsTask[] = [];

  @Input()
  otherOptionalConditions: ConditionsTask[] = [];

  @Input()
  borrowers: Borrower[];

  @Input()
  applicationState: TpoTaskFilterEnum;

  @Input()
  hideFilter: boolean;

  @Input()
  forSubmission: boolean = false;

  @Output()
  onTaskUpdated: EventEmitter<void> = new EventEmitter<void>();

  loading: boolean = true;

  columns: any[];
  filteredTableColumns: any[];

  deleteFileIndex: string = "";
  userId: string = "";

  element;

  protected esignConditions: ConditionsTask[] = [];
  protected exportOnlyColumnsMapping: Map<string, string[]> = new Map<string, string[]>();

  private _modalOptions: NgbModalOptions;
  private usersAll: User[] = [];
  private _loanDocs: LoanDoc[] = [];
  private _taskEmailSenderModalSubscription?: Subscription;

  constructor(
    injector: Injector,
    private readonly _loanActivityService: LoanActivityService,
    private readonly _modalService: NgbModal,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _fileService: FileService,
    private readonly _taskService: TaskService,
    private readonly _loanDocService: LoanDocService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService
  ) {
    super(injector);
    this._modalOptions = {
      windowClass: 'modal-full-width',
      backdrop: 'static',
      centered: true,
    };
    this.userId = this.applicationContext.userPermissions.userId;
    this.usersAll = this.applicationContext.globalConfig.usersAll;
  }

  ngOnInit() {
    this.columns = [
      { field: 'detail', header: 'Actions', sortable: false, style: 'width:170px;' },
      { field: 'description', header: 'Description', sortable: true },
      { field: 'dueDate', header: 'Due Date', sortable: true, style: 'width:165px;' },
      // { field: 'viewFile', header: 'View File', sortable: false },
      { field: 'taskStatus', header: 'Status', sortable: true, style: 'width:150px;' },
    ];

    this.filteredTableColumns = [
      { field: 'detail', header: 'Actions', sortable: false, style: 'width:170px;' },
      { field: 'description', header: 'Description', sortable: true },
      { field: 'dueDate', header: 'Due Date', sortable: true, style: 'width:165px;' },
      // { field: 'viewFile', header: 'View File', sortable: false },
      { field: 'taskStatus', header: 'Status', sortable: true, style: 'width:150px;' },
    ];

    this.exportOnlyColumnsMapping.set("dueDate", ["requestDate", "dueDate"]);
    this.exportOnlyColumnsMapping.set("description", ["borrowerName", "description"]);

    if (this.applicationState != 'All' && this.applicationState != 'Outstanding' && this.applicationState != 'PendingApproval' && this.applicationState != 'Completed') {
      this.applicationState = TpoTaskFilterEnum.Outstanding;
    }

    if (this.otherConditions && this.otherConditions.length > 0) {
      const [esignConditions, otherConditions] = partition(this.otherConditions, (r: any) => r.taskType === 'EsignDocument' || r.taskType === 'LosEsign');
      this.esignConditions = this.esignConditions || esignConditions;
      this.otherConditions = otherConditions;
      this._getAllLoanDocs();
    } else if (this.applicationContext.application.applicationId) {
      this._taskService.getAllTpoTask(this.applicationContext.application.applicationId, this.applicationState).subscribe((response) => {
        const [esignConditions, otherConditions] = partition(response, (r: any) => r.taskType === 'EsignDocument' || r.taskType === 'LosEsign');
        this.esignConditions = esignConditions;
        this.otherConditions = otherConditions;
        this._getAllLoanDocs();
      }, (err) => {
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this._taskEmailSenderModalSubscription?.unsubscribe();

    super.ngOnDestroy();
  }

  onDragEnter = (event) => {
    event.preventDefault();
    this.element = event.currentTarget;
    this.element.style.height = this.element.offsetHeight.toString() + 'px';
    this.element.classList.add('visibleDragDrop');
  }

  onDragLeave = (event) => {
    event.preventDefault();
    if (event.currentTarget != this.element) {
      event.currentTarget.classList.remove('visibleDragDrop');
    }

    if (!event.relatedTarget || event.relatedTarget.classList.contains('p-paginator')) {
      this.element.classList.remove('visibleDragDrop');
    }
  }

  onDragOver = (event) => {
    event.preventDefault();
  }

  onDrop = (event, task) => {
    event.preventDefault();
    this.element.classList.remove('visibleDragDrop');
    let files = event.dataTransfer.files;
    if (files.length > 0) {
      this.editTaskModal(task, files);
    }
  }

  onDragEnterHeader = (event) => {
    event.preventDefault();
    this.element?.classList.remove('visibleDragDrop');
  }

  markTaskComplete = (conditionsTask: ConditionsTask) => {
    this._taskService.setTaskStatus(conditionsTask.loanDocTaskId, conditionsTask.requiresReview ? "ReviewReady" : "TpoSubmitted").subscribe((response) => {
      if (!this.hideFilter) this.filterApplications(this.applicationState);
      this.onTaskUpdated.emit();
    });
  }

  editTaskModal = (conditionsTask: ConditionsTask, files?) => {
    this._taskService.getTaskDashboardViewById(conditionsTask.loanDocTaskId).subscribe((response) => {
      if (!response.docFiles) {
        response.docFiles = [];
      }

      if (files) {
        files.forEach((targetFile) => {
          const file = targetFile;
          let docFile = new FileDto();
          docFile.fileName = file.name;
          docFile.loanDocId = response.loanDocId;
          docFile.note = '';
          docFile.borrowerNote = '';
          docFile.guid = null;
          docFile.active = true;
          docFile.file = file;
          response.docFiles.push(docFile);
        });
      }

      let modalRef = this._modalService.open(EditTaskDialogComponent, Constants.modalOptions.xlarge);
      modalRef.componentInstance.task = response;

      modalRef.result.then((result) => {
        if (result) {
          if (!this.hideFilter) this.filterApplications(this.applicationState);
          this.onTaskUpdated.emit();
        }
      }, err => { });
    })
  }

  esignInProgress: boolean = false;

  esignTaskModal = (conditionsTask: ConditionsTask) => {
    this.esignInProgress = true;
    this._taskService.getTaskDashboardViewById(conditionsTask.loanDocTaskId).subscribe((response) => {
      if (!response.docFiles) {
        response.docFiles = [];
      }

      let modalRef = this._modalService.open(EditTaskDialogComponent, Constants.modalOptions.xlarge);
      modalRef.componentInstance.task = response;
      modalRef.componentInstance.startESign = true;
      modalRef.result.then((result) => {
        this.esignInProgress = false;
        if (result) {
          if (!this.hideFilter) this.initialFilterApplication(this.applicationState);
          this.onTaskUpdated.emit();
        }
      }, err => { this.esignInProgress = false; });
    }, err => { this.esignInProgress = false; })
  }

  docGenTaskModal = (conditionsTask: ConditionsTask) => {
    this._taskService.getTaskDashboardViewById(conditionsTask.loanDocTaskId).subscribe((response) => {
      if (!response.docFiles) {
        response.docFiles = [];
      }

      let modalRef = this._modalService.open(EditTaskDialogComponent, Constants.modalOptions.xlarge);
      modalRef.componentInstance.task = response;
      modalRef.componentInstance.startDocumentGeneration = true;
      modalRef.result.then((result) => {
        if (result) {
          if (!this.hideFilter) this.filterApplications(this.applicationState);
          this.onTaskUpdated.emit();
        }
      }, err => { });
    })
  }

  downloadDocument = (loanDocId: number) => {
    this._spinner.show();
    this._loanDocService.getLoanDoc(loanDocId).subscribe(result => {
      if (result && result.docFiles.length) {
        let fileGuid = result.docFiles[0].guid;
        let mimeType = result.docFiles[0].mimeType;
        this._loanDocService.downloadFile(fileGuid, mimeType).subscribe(data => {
          this._spinner.hide();
          const blob = new Blob([data], { type: 'application/pdf', });
          let downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          let fileName = result.docFiles[0].fileName;
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        });
      } else {
        this._spinner.hide();
      }
    }, error => {
      this._notifyService.showError(error.message, 'Error');
      this._spinner.hide();
    })
  }

  onShowMergeFilesClicked = (file: DocFile) => {
    const splitFileName = file.fileName.split(".");
    const fileExtension = splitFileName[1];
    this._fileService.getDocFile(file.guid).subscribe((docFile) => {
      this._loanDocsService.getLoanDocs(this.applicationContext.application.applicationId).subscribe((loanDocs) => {
        const modalRef = this._modalService.open(DocFilesEditDialogComponent, this._modalOptions);
        modalRef.componentInstance.title = 'Document File Editor';
        modalRef.componentInstance.file = docFile;
        modalRef.componentInstance.fileExtension = fileExtension;
        modalRef.componentInstance.appId = this.applicationContext.application.applicationId;
        modalRef.componentInstance.loanDocs = loanDocs;
        modalRef.componentInstance.globalConfig = this.applicationContext.globalConfig;
        modalRef.result.then((result) => {

        }, (res) => {
        });
      }, (err) => {
        this._notifyService.showError("Error retrieving loan docs for app " + this.applicationContext.application.applicationId + '. ' + err ? err.message || err : '', 'Error');
      });
    }, (err) => {
      this._notifyService.showError(err ? (err.data ? err.data.message : "Error retrieving doc file") : "Error retrieving doc file", 'Error');
    });
  }

  comfirmDelete = (guid: string) => {
    this._spinner.show();
    this._fileService.removeFile(guid).subscribe((response) => {
      this._notifyService.showSuccess("Moved file to trash", "Success");
      this._getAllLoanDocs();
      this._spinner.hide();
    }, (err) => {
      this._notifyService.showError(err ? err.message : 'Unable to move file to trash', "Error");
      this._spinner.hide();
    })
  }

  cancelDelete = (guid: string) => {
    this.deleteFileIndex = "";
  }

  deleteFile = (guid: string) => {
    this.deleteFileIndex = guid;
  }

  filterApplications = (applicationState: TpoTaskFilterEnum) => {
    this._spinner.show();
    this._taskService.getAllTpoTask(this.applicationContext.application.applicationId, applicationState).subscribe((response) => {
      this.otherConditions = applicationState === TpoTaskFilterEnum.Outstanding ?
        response.filter((r: any) => r.taskType !== 'EsignDocument' && r.taskType !== 'LosEsign') : response;
      this.esignConditions = applicationState === TpoTaskFilterEnum.Outstanding ?
        response.filter((r: any) => r.taskType === 'EsignDocument' || r.taskType === 'LosEsign') : this.esignConditions;
      this._getAllLoanDocs();
      this._spinner.hide();
    }, (err) => {
      this._spinner.hide();
    });
  }

  private initialFilterApplication = (state: TpoTaskFilterEnum) => {
    this._spinner.show();
    this._taskService.getAllTpoTask(this.applicationContext.application.applicationId, state).subscribe({
      next: (response: any) => {
        const [esignConditions, otherConditions] = partition(response, (r: any) => r.taskType === 'EsignDocument' || r.taskType === 'LosEsign');
        this.esignConditions = [...esignConditions];
        this.otherConditions = otherConditions;
        this._getAllLoanDocs();
      },
      error: (err) => {
        this._notifyService.showError(err ? err.message : 'Tasks could not be loaded.', "Error");
      }
    }).add(() => this._spinner.hide())
  }

  resendEsignNotification = (condition: ConditionsTask) => {
    const borrower = this.borrowers.find(borrower => borrower.borrowerId == condition.borrowerId);
    if (!borrower) {
      console.error('Borrower not found for condition', condition);
      this._notifyService.showError('Borrower not found for condition', 'Error');
      return;
    }

    const modalRef = this._modalService.open(TaskEmailSenderModalComponent, Constants.modalOptions.medium);
    const instance = modalRef.componentInstance as TaskEmailSenderModalComponent;
    instance.borrower = borrower;
    instance.task = condition;
    instance.forceEmailUpdate = true;

    this._taskEmailSenderModalSubscription?.unsubscribe();
    this._taskEmailSenderModalSubscription = from(modalRef.result).pipe(
      catchError((error) => {
        handleNonErrorDismissals(error);
        return EMPTY;
      }),
    ).subscribe(_ => {
        this.filterApplications(this.applicationState);
        this.onTaskUpdated.emit();
    });
  }

  onFileRenameClicked = (file: DocFile) => {
    file['shouldEdit'] = true;
  }

  onFileRenameCancelled = (file: DocFile) => {
    const { fileName, extension } = Utils.getFileNameAndExtension(file.fileName);
    file['fileNameWithoutExtension'] = fileName;
    file['shouldEdit'] = false;
  }

  onViewFileClicked = (docFile: DocFile) => {
    if (!docFile.guid) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(docFile.file);
      reader.onload = (event) => {
        const blob = new Blob([reader.result], { type: docFile.file.type });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      };
      return;
    }

    this._spinner.show();
    this._loanDocsService.viewLoanDocContent(docFile.guid).subscribe({
      next: data => {
        const blob = new Blob([data], { type: data['type'] });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      error: (error) => { }
    }).add(() => this._spinner.hide());
  }

  onFileRenameConfirmed = (file: DocFile) => {
    this._spinner.show();
    const { fileName, extension } = Utils.getFileNameAndExtension(file['originalFileName']);
    file.fileName = file['fileNameWithoutExtension'] + "." + extension;
    file['shouldEdit'] = false;

    this.cleanupUiSpecificFieldsOnFileBeforeSave(file);
    let request = new MergeDocFilesRequest(null, null, file.guid, file.fileName, null, false);
    this._loanDocsService.mergeDocFiles(request, file.loanDocId).subscribe({
      next: () => {
        this._getAllLoanDocs();
        this._notifyService.showSuccess('Successfully saved document!', 'Success');
      },
      error: (err) => {
        this._notifyService.showError(err.message || err.error || 'Error while saving document!', 'Failure');
      }
    }).add(() => this._spinner.hide());
  }

  private _getAllLoanDocs = () => {
    this._loanActivityService.getAllLoanDocs(this.applicationContext.application.applicationId).subscribe((response) => {
      this._loanDocs = response;
      this.setInitialFileNameForEdit();
      this._editConditionsTableData();
    }, (err) => {
      this.loading = false;
    });
  }

  private _editConditionsTableData = () => {
    this.otherConditions.forEach(condition => {
      (condition as any).lastRequest = Utils.timeSince(condition.requestDate);
      let borrower: Borrower = null;
      if (Array.isArray(this.borrowers)) {
        borrower = this.borrowers.find(borrower => borrower.borrowerId == condition.borrowerId);
      }
      if (borrower) {
        condition.borrowerName = Utils.getPersonsDisplayName(borrower);
      }
      if (condition.loanDocModel && condition.loanDocModel.docFiles) {
        condition.docFiles = condition.loanDocModel.docFiles;
      } else {
        condition.docFiles = this._loanDocs.find(loanDoc => loanDoc.loanDocId == condition.loanDocId)?.docFiles;
      }
      if (condition.docFiles?.length > 0) {
        condition.docFiles.forEach(docFile => {
          (docFile as any).createdBy = this.getInsertedBy(docFile.insertedBy);
        })
      }
    });

    this.esignConditions.forEach(condition => {
      (condition as any).lastRequest = Utils.timeSince(condition.requestDate);
      let borrower: Borrower = null;
      if (Array.isArray(this.borrowers)) {
        borrower = this.borrowers.find(borrower => borrower.borrowerId == condition.borrowerId);
      }
      if (borrower) {
        condition.borrowerName = Utils.getPersonsDisplayName(borrower);
      }
      if (condition.loanDocModel && condition.loanDocModel.docFiles) {
        condition.docFiles = condition.loanDocModel.docFiles;
        condition.docFiles?.forEach((docFile) => {
          (docFile as any).linked = this.getLoanDocLinked(condition);
        });
      } else {
        condition.docFiles = this._loanDocs.find(loanDoc => loanDoc.loanDocId == condition.loanDocId)?.docFiles;
        condition.docFiles?.forEach((docFile) => {
          (docFile as any).linked = this.getLoanDocLinked(condition);
        });
      }
      if (condition.docFiles?.length > 0) {
        condition.docFiles.forEach(docFile => {
          (docFile as any).createdBy = this.getInsertedBy(docFile.insertedBy);
        })
      }
    });
    this.loading = false;
  }

  private getLoanDocLinked = (condition: ConditionsTask) => {
    if (condition.loanDocId && condition.loanDocModel?.losLoanDocId) {
      return 'Loda/LOS';
    } else if (condition.loanDocId) {
      return 'Loda';
    } else if (condition.loanDocModel.losLoanDocId) {
      return 'LOS';
    } else {
      return '';
    }
  }

  private setInitialFileNameForEdit = () => {
    const docFiles = this._loanDocs?.map(loanDoc => loanDoc.docFiles).flat();
    docFiles.forEach(file => {
      if (file.fileName) {
        file['shouldEdit'] = false;
        const { fileName, extension } = Utils.getFileNameAndExtension(file.fileName);
        file['originalFileName'] = file.fileName;
        file['fileNameWithoutExtension'] = fileName;
      } else {
        file['originalFileName'] = '';
      }
    })
  }

  private getInsertedBy = (id: string) => {
    const findInserter = this.usersAll.find(u => u.userCompanyGuid == id);
    return Utils.getBorrowerFullName(findInserter);
  }

  private cleanupUiSpecificFieldsOnFileBeforeSave = (file: DocFile) => {
    delete file['shouldEdit'];
    delete file['originalFileName'];
    delete file['fileNameWithoutExtension'];
    delete file['createdBy']
  }
}
