import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, finalize, forkJoin, of } from 'rxjs';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { DashboardTaskCounts } from 'src/app/models/task/dashboard-task-counts.model';
import { BorrowersService } from '../../../../borrower/services/borrowers.service';
import { SelectAgentTypeDialogComponent } from 'src/app/modules/remain-contact/components/remain-contact/select-agent-type-dialog/select-agent-type-dialog.component';
import { EditTaskDialogComponent } from 'src/app/modules/tasks/components/edit-task-dialog/edit-task-dialog.component';
import { Constants } from 'src/app/services/constants';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { Lead } from '../../../models/lead.model';
import { LeadsService } from '../../../services/leads.service';
import { DuplicateBorrowerEmailDialogComponent } from './dialogs/duplicate-borrower-email-dialog/duplicate-borrower-email-dialog.component';
import { LeadActionsComponent } from './lead-actions/lead-actions.component';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { LeadEditorComponent } from '../../lead-editor/lead-editor.component';

@Component({
  selector: 'app-view-lead-drawer',
  templateUrl: './view-lead-drawer.component.html',
  styleUrls: ['./view-lead-drawer.component.scss']
})
export class ViewLeadDrawerComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @ViewChild("leadActions")
  leadActions: LeadActionsComponent;

  @Input() leadId: number;
  @Input("leadToEdit") lead: Lead;
  @Input() isFreeForAllAccept: boolean = false;
  @Input() isDrawer: boolean = false;
  @Input() isManualDial: boolean = true;
  @Input() impersonateUserId: string = null;

  task: LoanDocDashboardTask;
  leadTasksCounts: DashboardTaskCounts;

  submitting: boolean = false;
  convertingToLoan: boolean = false;
  isLoading: boolean = false;
  taskCountLoaded: boolean = false;
  alreadyConverted: boolean = false;
  openLeadSubscription: Subscription;

  enabledChannels: EnumerationItem[] = [];

  private _userId: string;
  private _userCompanyGuid: string;

  @Output()
  closeDrawer: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  leadUpdated: EventEmitter<Lead> = new EventEmitter<Lead>();

  @Output()
  phoneClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,
    private readonly _leadsService: LeadsService,
    private readonly _notifyService: NotificationService,
    private readonly _borrowersService: BorrowersService,
    private readonly _router: Router,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _navigationService: NavigationService,
    private readonly injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.applicationContextService.context.subscribe(context => {
      this.enabledChannels = context.globalConfig.enabledChannels;
      this._userCompanyGuid = context.currentlyLoggedInUserProfile.userProfile.userCompanyGuid;
      if (!this.lead && this.leadId) {
        this.getLeadInfo(this.leadId);
      } else if (this.lead) {
        this.lead = { ...new Lead, ...this.lead };
        if (this.isFreeForAllAccept) {
          this.lead.leadContactUserId = this._userId;
        }
        this.reloadLeadTaskCounts();
        this.openLeadSubscription = this._leadsService.openLead(this.lead.leadId).subscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this.leadActions?.leadEditor?.leadAssignmentComponent?.loanChannelComponent?.nativeElement.removeEventListener("change", this.onLoanChannelComponentChange);
    this.leadActions?.leadEditor?.leadAssignmentComponent?.loanPurposeComponent?.nativeElement.removeEventListener("change", this.onLoanPurposeComponentChange);
    this.leadActions?.leadEditor?.leadAssignmentComponent?.loanTypeComponent?.nativeElement.removeEventListener("change", this.onLoanTypeComponentChange);
    if (this.openLeadSubscription)
      this.openLeadSubscription.unsubscribe();
  }

  getLeadInfo = (leadId: number, callback = null): void => {
    this.isLoading = true;

    this._leadsService.getLead(leadId)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (lead) => {
          this.lead = { ...new Lead, ...lead };
          if (leadId > 0 && lead.leadId == 0 && lead.applicationId > 0) {
            this.alreadyConverted = true;
            this.isLoading = false
            this._spinnerService.hide();
            return;
          }
          if (this.isFreeForAllAccept) {
            this.lead.leadContactUserId = this._userId;
          }
          this.reloadLeadTaskCounts();
          if (callback) {
            callback();
          }
        },
        error: (err) => {
          this._notifyService.showError("Error occurred while loading lead details", "Error");
          console.error(err);
        }
      });
  }

  addTask = () => {

    let modalRef = this._modalService.open(EditTaskDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.task = this.newTask(); // LoanDocDashboardTask

    modalRef.result.then(() => {
      this.reloadLeadTaskCounts();
      if (this.leadActions && this.leadActions.leadTasks) {
        this.leadActions.leadTasks.getTasks();
      }
    }, () => {
    });

  }

  convertToLoan = () => {

    if (!this.leadActions.validate()) {
      return;
    }
    let msg = '';
    if (!this.lead.loanPurposeId)
      msg += "Loan Purpose";
    if (!this.lead.loanTypeId)
      msg += (msg === '' ? '' : ', ') + "Loan Type";
    if (this.enabledChannels.length > 0 && !this.lead.channel)
      msg += (msg === '' ? '' : (msg.indexOf(',') > 0 ? ',' : '') + ' and ') + "Channel";

    if (msg !== '') {
      this.showConfirmModal(msg);
      return;
    }

    if (this.lead.referralSource || this.lead.secondaryReferralSource) {

      if (!this.lead.referralSource) {
        this.lead.referralSource = this.lead.secondaryReferralSource;
        this.lead.secondaryReferralSource = undefined;
      }

      this.showSelectReferralSourceTypeModal(this.lead.referralSource, this.lead.secondaryReferralSource);
      return;
    }
    this.postConvertLead(null, null);

  }

  showConfirmModal = (message: string) => {

    let modalRef = this._modalService.open(ConfirmModalComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.title = "Action Needed"
    modalRef.componentInstance.text = `You need to set the ${message} before converting.`;
    modalRef.componentInstance.submitButtonText = "Take Me There";
    modalRef.componentInstance.cancelButtonText = "Cancel";

    modalRef.result.then(() => {
      if (!this.leadActions) return;

      this.leadActions.selectedTab = 1;

      setTimeout(() => {
        if (message.indexOf("Channel") > -1) {
          this.leadActions.leadEditor?.leadAssignmentComponent?.loanChannelComponent?.nativeElement.classList?.add("is-invalid");
          this.leadActions.leadEditor?.leadAssignmentComponent?.loanChannelComponent?.nativeElement.addEventListener(
            "change",
            this.onLoanChannelComponentChange
          )
        }

        if (message.indexOf("Loan Purpose") > -1) {
          this.leadActions.leadEditor?.leadAssignmentComponent?.loanPurposeComponent?.nativeElement.classList?.add("is-invalid");
          this.leadActions.leadEditor?.leadAssignmentComponent?.loanPurposeComponent?.nativeElement.addEventListener(
            "change",
            this.onLoanPurposeComponentChange
          )
        }

        if (message.indexOf("Loan Type") > -1) {
          this.leadActions.leadEditor?.leadAssignmentComponent?.loanTypeComponent?.nativeElement.classList?.add("is-invalid");
          this.leadActions.leadEditor?.leadAssignmentComponent?.loanTypeComponent?.nativeElement.addEventListener(
            "change",
            this.onLoanTypeComponentChange
          )
        }

        const leadEditorContainer = document.getElementById('lead-editor-main');
        if (leadEditorContainer) {
          leadEditorContainer.scrollTop = 0;
        }
      }, 10)
    }, () => {
    });

  }

  showSelectReferralSourceTypeModal = (agentId: number, secondaryAgentId: number) => {

    let modalRef = this._modalService.open(SelectAgentTypeDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.agentId = agentId;
    modalRef.componentInstance.selectingForConversion = true;
    modalRef.componentInstance.showRedirectToLoanButton = false;

    modalRef.result.then((result) => {

      if (result == "cancel") {
        this._notifyService.showInfo("User canceled convert action.", "Info!");
        return;
      }

      if (!result || !result.associateAgentAs) {
        this._notifyService.showWarning("Referral source will not be added to external contacts on loan.", "Warning!");
      }
      if (!secondaryAgentId)
        this.postConvertLead(result ? result.associateAgentAs : undefined, undefined);
      else {
        let modalRef2 = this._modalService.open(SelectAgentTypeDialogComponent, Constants.modalOptions.medium);
        modalRef2.componentInstance.agentId = secondaryAgentId;
        modalRef2.componentInstance.selectingForConversion = true;
        modalRef2.componentInstance.showRedirectToLoanButton = false;

        modalRef2.result.then((secondaryResult) => {

          if (secondaryResult == "cancel") {
            this._notifyService.showInfo("User canceled convert action.", "Info!");
            return;
          }

          if (!secondaryResult || !secondaryResult.associateAgentAs) {
            this._notifyService.showWarning("Secondary referral source will not be added to external contacts on loan.", "Warning!");
          }
          this.postConvertLead(result ? result.associateAgentAs : undefined, secondaryResult ? secondaryResult.associateAgentAs : undefined);
        }, () => {

        });
      }
    }, () => {

    });

  }

  postConvertLead = (referralSourceType: number, secondaryReferralSourceType: number) => {

    if (this.lead.email || this.lead.coEmail) {

      forkJoin({
        dupBorrowers: this.lead.email ? this._borrowersService.duplicateEmail(this.lead.email) : of(null),
        dupCoBorrowers: this.lead.coEmail ? this._borrowersService.duplicateEmail(this.lead.coEmail) : of(null)
      })
        .subscribe(response => {
          if ((response.dupBorrowers && response.dupBorrowers.length > 0) || (response.dupCoBorrowers && response.dupCoBorrowers.length > 0)) {

            let modalRef = this._modalService.open(DuplicateBorrowerEmailDialogComponent, Constants.modalOptions.medium);

            const dupBorrowers = response.dupBorrowers || [];

            modalRef.componentInstance.dupBorrowers = dupBorrowers?.map(borrower => ({
              id: borrower.borrowerId,
              firstName: borrower.firstName,
              lastName: borrower.lastName,
              email: borrower.email,
              dateInserted: borrower.dateInserted
            }));

            this.dateSort(modalRef.componentInstance.dupBorrowers, "dateInserted", false);

            const dupCoBorrowers = response.dupCoBorrowers || [];

            modalRef.componentInstance.dupCoBorrowers = dupCoBorrowers.map(coBorrower => ({
              id: coBorrower.borrowerId,
              firstName: coBorrower.firstName,
              lastName: coBorrower.lastName,
              email: coBorrower.email,
              dateInserted: coBorrower.dateInserted
            }));

            this.dateSort(modalRef.componentInstance.dupCoBorrowers, "dateInserted", false);

            modalRef.result.then((res) => {
              this.convertLeadToLoan(referralSourceType, secondaryReferralSourceType, res.selectedBorrowerId, res.selectedCoBorrowerId);
            }, () => {
              this._notifyService.showInfo("User canceled convert action.", "Info!");
            });
          }
          else {
            this.convertLeadToLoan(referralSourceType, secondaryReferralSourceType);
          }
        })
    }
    else {
      this.convertLeadToLoan(referralSourceType, secondaryReferralSourceType);
    }
  }

  dateSort = (array: any[], fieldName: string, isDesc: boolean = false): void => {
    array.sort((a, b) => {

      let dateA = new Date(a[fieldName]).getTime();
      let dateB = new Date(b[fieldName]).getTime();

      return isDesc ? (dateB - dateA) : (dateA - dateB);
    })
  }

  convertLeadToLoan = (referralSourceType: number, secondaryReferralSourceType: number, borrowerId?: number, coBorrowerId?: number): void => {
    if (this.leadActions?.leadEditor) {
      if (this.leadActions?.leadEditor.isDirty()) {
        this.showNotSavedDialog((isConvert: boolean) => {
          if(isConvert){
            this.convertLeadToLoanCall(referralSourceType, secondaryReferralSourceType, borrowerId, coBorrowerId);
          }
        });
      }
      else {
        this.convertLeadToLoanCall(referralSourceType, secondaryReferralSourceType, borrowerId, coBorrowerId);
      }
    }
  }

  newTask = (): any => {
    return {
      uploadView: '',
      documentTypeId: null,
      applicationId: 0,
      description: '',
      note: '',
      followUpDate: '',
      loanDocTaskId: 0,
      status: false,
      approveReject: false,
      dueDays: 0,
      userId: this._userCompanyGuid,
      taskPriority: 'Normal',
      multipleBorrower: null,
      borrowerId: null,
      roleId: null,
      borrowerFacingNote: '',
      docFiles: [],
      leadId: this.leadId || this.lead?.leadId || null
    }
  }

  reloadLeadTaskCounts = () => {

    this.taskCountLoaded = false;

    this._leadsService.getTaskCountByLeadId(this.lead.leadId, this.impersonateUserId)
      .subscribe(resp => {
        this.leadTasksCounts = resp;
        this.taskCountLoaded = true;
      });
  }

  onLeadUpdated = (lead: Lead) => {
    this.lead = lead;
    if (this.leadUpdated.observed) {
      this.leadUpdated.emit(lead);
    }
    this._leadsService.updateLeadsTable(lead);
  }

  onLeadEditorDrawerClosed = () => {
    this.closeDrawer.emit();
  }

  onDialClicked = (e) => {
    this.phoneClicked.emit(e);
  }

  private onLoanChannelComponentChange = (e: any) => {
    if (e.target.value?.split(":")[1]?.trim() !== "null") {
      e.target.classList.remove("is-invalid");
      this.leadActions.leadEditor?.save(); // save to persist changes
    } else {
      e.target.classList.add("is-invalid");
    }
  }

  private onLoanPurposeComponentChange = (e: any) => {
    if (e.target.value?.split(":")[1]?.trim() !== "null") {
      e.target.classList.remove("is-invalid");
      this.leadActions.leadEditor?.save(); // save to persist changes
    } else {
      e.target.classList.add("is-invalid");
    }
  }

  private onLoanTypeComponentChange = (e: any) => {
    if (e.target.value?.split(":")[1]?.trim() !== "null") {
      e.target.classList.remove("is-invalid");
      this.leadActions.leadEditor?.save(); // save to persist changes
    } else {
      e.target.classList.add("is-invalid");
    }
  }

  private convertLeadToLoanCall = (referralSourceType: number, secondaryReferralSourceType: number, borrowerId?: number, coBorrowerId?: number) => {
    this.convertingToLoan = true;

    this._leadsService.convertLeadToLoan(this.lead.leadId, referralSourceType, secondaryReferralSourceType, borrowerId, coBorrowerId)
      .subscribe((response: any) => {

        this.convertingToLoan = false;

        this.activeModal.close();

        if (this._navigationService.applicationMode == ApplicationMode.Classic) {
          this._router.navigate(["/admin/app-details/" + response.applicationId]);
        }
        if (this._navigationService.applicationMode == ApplicationMode.NextGen) {
          this._router.navigate(["/loda-nextgen/app-details/" + response.applicationId]);
        }
      }, (err) => {
        this.convertingToLoan = false;
        let errorMessage = 'Error converting lead to a loan.';
        if (err && err.message) {
          errorMessage = err.message;
        }
        this._notifyService.showError(errorMessage, "Error!");
      });
  }

  private showNotSavedDialog = async (cb: Function) => {
    const result = await Swal.fire({
      showDenyButton: true,
      title: 'Are you sure?',
      text: `You have changes that are not saved. Would you like to save your changes? If you choose Discard below, your changes will be lost.`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Save & Continue!',
      cancelButtonText: 'Cancel',
      cancelButtonColor: "#DD6B55",
      denyButtonText: `Discard & Continue!`,
      reverseButtons: true
    });

    if (result.isConfirmed) {
      if (this.leadActions?.leadEditor.validate()) {
        this.leadActions?.leadEditor.save().then(() => {
          cb(true);
        });
      }
    }
    if (result.isDenied) {
      this.leadActions?.leadEditor.cancelUpdates();
      cb(false);
    }
  }
}
