import { HousingExpense } from "./mortgage.model";

export class MortgageCalculationDetails {
  ltv: MortgageLtvCalculationDetails;
  dti: MortgageDtiCalculationDetails;
  fundsToClose: MortgageFundsToCloseCalculationDetails;
  proposedExpenses: HousingExpense;
}

export class MortgageLtvCalculationDetails implements IHaveMortgageLtvCalculationDetails {
  baseLoanAmount: number;
  totalLoanAmount: number;
  subordinateLienAmount: number;
  subordinateLienCreditLimitTotal: number;
  collateralValue: number;
  ltv: number;
  cltv: number;
  hcltv: number;
}

export class MortgageDtiCalculationDetails {
  frontEndDti: number;
  backEndDti: number;
  frontEndDebt: number;
  frontEndDebtInfo: string | null;
  incomeMonthlyTotal: number;
  debtMonthlyTotal: number;
  incomeDetail: DtiIncome[] = [];
  debtDetail: DtiDebt[] = [];
}

export interface IHaveMortgageLtvCalculationDetails {
  ltv: number;
  cltv: number;
  hcltv: number;
  collateralValue: number;
}

export class MortgageFundsToCloseCalculationDetails {
  salesContractPrice_A?: number;
  improvementsRenovationsRepairs_B?: number;
  landValue_C?: number;
  totalPaidOffForRefinance_D?: number;
  debtsPaidOff_E?: number;
  borrowerClosingCostsTotal_F?: number;
  estimatedPrepaidItems?: number;
  estimatedEscrowItems?: number;
  estimatedClosingCosts?: number;
  pmiMipFundingFee?: number;
  discountPoints_G?: number;
  totalDueFromBorrowerAThroughG_H?: number;
  loanAmount_I?: number;
  loanAmountExcludingFinancedMip?: number;
  financedMip?: number;
  otherNewMortgageLoansOnSubjectProperty_J?: number;
  totalMortgageLoansIThroughJ_K?: number;
  sellerCredits_L?: number;
  otherCredits_M?: number;
  lenderCredits?: number;
  emd?: number;
  totalCreditsLThroughM_N?: number;
  totalMortgageLoansAndTotalCreditsNAndK?: number;
  cashFromToBorrower?: number;
  priceOfPersonalPropertyIncludedInSale?: number; // E2
  adjustmentsDueFromBorrowerAtClosing?: number; // E3
  sumOfToleranceCures?:number;
}

export class DtiIncome {
  name: string;
  amount: number;
  info: string;
  childDetail: DtiIncome[] = [];
}

export class DtiDebt {
  name: string;
  amount: number;
  info: string;
  childDetail: DtiDebt[] = [];
}
