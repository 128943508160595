<div class="card">
    <div class="card-header">
        <h4 class="card-title">SMS Settings</h4>
    </div>
    <div class="card-body">
        <div *ngIf="smsAlertsEnabled; else elseBlock">
            <loading-indicator *ngIf="isLoading"  [loadingMessage]="'Sending SMS...'"></loading-indicator>
            <form class="form-horizontal auth-form" #smsSettingsForm="ngForm" novalidate id="smsSettingsForm" name="smsSettingsForm">
              <div class="mb-4"  *ngIf="!isLoading">
                  <div class="row">

                      <div class="col-md-12 mb-3">
                          <button type="button" class="btn btn-primary pull-right" (click)="setupPhoneNumber()">
                              Setup Phone Number
                          </button>
                      </div>
                      <div class="col-md-6">
                          <div class="form-group mb-3 row">
                              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Service Type</label>
                              <div class="col-sm-10">

                                  <select name="serviceType" class="form-select" [(ngModel)]="userProfile.telephonyService.serviceType">
                                      <option value="">-- Select One --</option>
                                      <option value="Twilio">Lodasoft</option>
                                  </select>

                              </div>
                          </div>

                          <div class="form-group mb-3 row">
                              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Account SID</label>
                              <div class="col-sm-10">
                                  <input name="accountSid" class="form-control" placeholder="Account SID" type="text"
                                        [(ngModel)]="userProfile.telephonyService.accountIdentifier" required>
                              </div>
                          </div>
                          <div class="form-group mb-3 row">
                              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Test SMS #</label>
                              <div class="col-sm-10">
                                  <input #toPhoneNumber="ngModel"
                                    name="phoneNumberToTestSmsWith"
                                    required
                                    [ngClass]="{'is-invalid' : toPhoneNumber && toPhoneNumber.touched && toPhoneNumber.invalid}"
                                    class="form-control" placeholder="Send test sms to this number" type="text"  [mask]="'(000) 000-0000'"
                                    [showMaskTyped]="true"
                                        [(ngModel)]="phoneNumberToTestSmsWith">
                                  <div class="invalid-feedback">Please enter a phone number.</div>
                              </div>
                        </div>
                          <div class="row mt-3">
                            <div class="col-md-6 offset-md-2">
                              <div class="form-check form-switch form-switch-success">
                                <input name="disableVoiceRecording" class="form-check-input" type="checkbox" id="disableVoiceRecording"
                                  [(ngModel)]="userProfile.telephonyService.disableVoiceRecording">
                                <label class="form-check-label" for="disableVoiceRecording">Disable Call Recording</label>
                              </div>
                            </div>
                          </div>

                      </div>
                      <div class="col-md-6">

                          <div class="form-group mb-3 row">
                              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Phone #</label>
                              <div class="col-sm-10">
                                  <input name="fromPhoneNumber" type="text" class="form-control" placeholder="From"  [mask]="'(000) 000-0000'" [showMaskTyped]="true"
                                        [(ngModel)]="userProfile.telephonyService.fromPhoneNumber">
                              </div>
                          </div>

                          <div class="form-group mb-3 row">
                              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Auth Token</label>
                              <div class="col-sm-10">
                                  <input name="authToken" type="text" class="form-control" placeholder="Auth token" [(ngModel)]="userProfile.telephonyService.authToken">
                              </div>
                          </div>

                          <div class="form-group mb-3 row" *ngIf="userProfile.telephonyService.userId">
                            <div class="col-sm-10 offset-2">
                              <div class="form-check form-switch form-switch-success">
                                <input class="form-check-input" type="checkbox" id="setAsPrimary" name="setAsPrimary"
                                       [(ngModel)]="userProfile.telephonyService.isUserPrimaryNumber">
                                <label class="form-check-label" for="setAsPrimary">Set As Primary For User</label>
                              </div>
                            </div>
                          </div>

                      </div>

                  </div>

                  <div class="my-2">
                      <button type="button" class="btn btn-outline-warning" (click)="onSendTestSMS()" style="float: right;">
                          Test Credentials
                      </button>
                  </div>
              </div>
          </form>
        </div>
        <ng-template #elseBlock>
            <h4 class="fw-light">Call to enable this feature!</h4>
        </ng-template>
    </div>
</div>

<drawer #setupPhoneNumberDrawer [name]="'setupPhoneNumberDrawer'" [templateRef]="setupPhoneNumberRef"
        [title]="'Phone Number Wizard'"
        [options]="setupPhoneNumberDrawerOptions">
</drawer>
<ng-template #setupPhoneNumberRef>
    <purchase-phone-number-wizard [userProfile]="userProfile" [requiresCodeVerification]="true"></purchase-phone-number-wizard>
</ng-template>
