import {
  Component,
  Injector,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Utils } from 'src/app/core/services/utils';
import { Address } from 'src/app/models/address.models';
import { CityCountyState } from 'src/app/models/city-county-state.model';
import { MiQuoteSearchBorrowerInfo, MiQuoteSearchRequest } from 'src/app/models/mi-quote-search-request.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { CityCountyPickerDialogComponent } from 'src/app/modules/urla/components/city-county-picker-dialog/city-county-picker-dialog.component';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { ZipCodeService } from 'src/app/services/zipcode.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import Swal from 'sweetalert2';

@Component({
  selector: 'mi-quote-search-parameters',
  templateUrl: './mi-quote-search-parameters.component.html',
  styleUrls: ['./mi-quote-search-parameters.component.scss']
})
export class MiQuoteSearchParametersComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('quoteSearchForm')
  quoteSearchForm: NgForm;

  @Input()
  set quoteSearchRequest(request: MiQuoteSearchRequest) {
    this._quoteSearchRequest = request;

    this.lastZipCodeSearch = request.propertyInformation.zipCode;
    if (this.quoteSearchRequest.loanInformation.loanPurpose !== 'Refinance') {
      this.removeRefinancePurpose();
    }

    if (this.quoteSearchRequest.loanInformation && this.quoteSearchRequest.loanInformation.baseLoanAmount) {
      this.quoteSearchRequest.loanInformation.baseLoanAmount = Math.floor(this.quoteSearchRequest.loanInformation.baseLoanAmount);
    }
    if (
      request.propertyInformation.zipCode &&
      !(request.propertyInformation.state && request.propertyInformation.county)
    ) {
      this.lookupZipCodeRelatedInfo(request.propertyInformation.zipCode);
    }

    this.fixDefaultValues();

    this._quoteSearchRequest.borrowerInformation.borrowerDetails.forEach(borr => {
      borr["id"] = _.random(0, 9999999999999);
    });

    this.isQuickPricer = this._quoteSearchRequest.loanInformation.loanNumber == "QUICKPRICE";
  }

  @Input()
  showMultipleColumns: boolean = true;

  @Input()
  channelOptions: EnumerationItem[] = [];

  private _quoteSearchRequest: MiQuoteSearchRequest;

  get quoteSearchRequest(): MiQuoteSearchRequest {
    return this._quoteSearchRequest;
  }

  loanPurposeOptions: EnumerationItem[] = [];
  amortizationTypeOptions: EnumerationItem[] = [];
  occupancyOptions: EnumerationItem[] = [];
  attachmentTypeOptions: EnumerationItem[] = [];
  numberOfUnitsOptions: EnumerationItem[] = [];
  refinancePurposeOptions: EnumerationItem[] = [];
  refinancePrimaryPurposes: EnumerationItem[] = [];
  armFixedTermOptions: EnumerationItem[] = [];
  buyDownOptions: EnumerationItem[] = [];
  constructionMethodOptions: EnumerationItem[] = [];
  manufacturedHomeWidthTypeOptions: EnumerationItem[] = [];
  projectTypeOptions: EnumerationItem[] = [];
  projectDesignTypeOptions: EnumerationItem[] = [];
  duAusRecommendationOptions: EnumerationItem[] = [];
  lpaAusRecommendationOptions: EnumerationItem[] = [];
  specialLoanProgramOptions: EnumerationItem[] = [];

  countyList: EnumerationItem[] = [];
  stateOptions: EnumerationItem[] = [];
  lastZipCodeSearch: string;

  selectedRows: { [key: number]: boolean } = {};
  expandedItems: { [key: number]: boolean } = {};
  editItems: { [key: number]: boolean } = {};
  hoveredItems: { [key: number]: boolean } = {};

  isLoanInfoSectionInvalid: boolean = false;
  isBorrowerInfoSectionInvalid: boolean = false;
  isPropertyInfoSectionInvalid: boolean = false;
  isProductSearchCriteriaSectionInvalid: boolean = false;
  isAdditionalInfoSectionInvalid: boolean = false;

  isQuickPricer: boolean = false;

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _zipCodeService: ZipCodeService,
    private readonly _notifsService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.stateOptions = this._enumsService.states;

    this._enumsService.getMortgageEnumerations().subscribe((enums) => {
      this.loanPurposeOptions = enums[Constants.mortgageEnumerations.loanPurpose];
      this.amortizationTypeOptions = enums[Constants.mortgageEnumerations.amortizationType];
      this.attachmentTypeOptions = enums[Constants.mortgageEnumerations.attachmentType];
      this.refinancePurposeOptions = enums[Constants.mortgageEnumerations.refinancePurpose];
      this.refinancePrimaryPurposes = enums[Constants.enumerations.refinancePrimaryPurposes];
      this.constructionMethodOptions = enums[Constants.mortgageEnumerations.constructionMethodType];
      this.projectTypeOptions = enums[Constants.mortgageEnumerations.projectType];
      this.manufacturedHomeWidthTypeOptions = enums[Constants.mortgageEnumerations.manufacturedHomeWidthType];
      this.projectDesignTypeOptions = enums[Constants.mortgageEnumerations.projectDesignType];
      this.duAusRecommendationOptions = enums[Constants.mortgageEnumerations.duAusRecommendation];
      this.lpaAusRecommendationOptions = enums[Constants.mortgageEnumerations.lpaAusRecommendation];
    });

    this._enumsService.getPricingEnumerations().subscribe((enums) => {
      this.occupancyOptions = enums[Constants.pricingEnumerations.occupancy];
      this.numberOfUnitsOptions = enums[Constants.pricingEnumerations.numberOfUnits];
      this.armFixedTermOptions = enums[Constants.pricingEnumerations.armFixedTerm];
      this.buyDownOptions = enums[Constants.pricingEnumerations.buyDown];
    });

    this._enumsService.getMortgageInsuranceEnumerations().subscribe((enums) => {
      this.specialLoanProgramOptions = enums[Constants.mortgageInsuranceEnumerations.specialLoanProgram];
    });

    this.getCountyListBySelectedState();
  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    this.lastZipCodeSearch = zipCode.zipcode;
    if (zipCode) {
      this.quoteSearchRequest.propertyInformation.state =
        zipCode.state.toLowerCase();
      this.quoteSearchRequest.propertyInformation.county = _.toUpper(
        zipCode.county
      ); // titleCase string
      this.quoteSearchRequest.propertyInformation.countyFips = zipCode.countyFips;
      this.quoteSearchRequest.propertyInformation.zipCode = zipCode.zipcode;
    } else {
      this.quoteSearchRequest.propertyInformation.state = '';
      this.quoteSearchRequest.propertyInformation.county = '';
      this.quoteSearchRequest.propertyInformation.countyFips = '';
    }
    this.getCountyListBySelectedState();
  }

  onLoanProgramChanged = () => {
    if(this.quoteSearchRequest.loanInformation.specialLoanProgram != "Other"){
      this.quoteSearchRequest.loanInformation.loanProgramOtherDescription = null;
    }
  }

  onLoanPurposeChanged = () => {
    if(this.quoteSearchRequest.loanInformation.loanPurpose != "Refinance"){
      this.quoteSearchRequest.loanInformation.refinancePurpose = null;
      this.quoteSearchRequest.loanInformation.refinancePrimaryPurpose = null;
    }
  }

  onAmortizationTypeChanged = () => {
    if(this.quoteSearchRequest.loanInformation.amortizationType != "ARM"){
      this.quoteSearchRequest.loanInformation.armFixedTermMonths = null;
      this.quoteSearchRequest.loanInformation.armSubsequentChangePeriodMonths = null;
    }
  }

  onZipCodeBlur = (e) => {
    if (this.lastZipCodeSearch !== this.quoteSearchRequest.propertyInformation.zipCode) {
      this.quoteSearchRequest.propertyInformation.state = '';
      this.quoteSearchRequest.propertyInformation.county = '';
      this.quoteSearchRequest.propertyInformation.countyFips = '';
      this.countyList = [];
    }
  }

  getCountyListBySelectedState = () => {
    if (!this.quoteSearchRequest.propertyInformation.state) {
      this.countyList = [];
      return;
    }
    this._zipCodeService.getCountiesByState(this.quoteSearchRequest.propertyInformation.state)
      .subscribe({
        next: (list) => {
          this.countyList = list;
        },
        error: (err) => {
          this._notifsService.showError(err ? err.message : 'Unable to get counties for selected state.', 'Error!');
        }
      });
  }

  onCountySelect = ({ item }): void => {
    this.quoteSearchRequest.propertyInformation.countyFips = item.value;
  }

  handleAddressChange(e: Partial<Address>): void {
    let addressModel = this.quoteSearchRequest.propertyInformation;
    addressModel.address = ''; // to reset the last populated address.

    setTimeout(() => {
      addressModel.address = e.address1;
      addressModel.city = e.city;
      addressModel.state = e.state;
      addressModel.zipCode = e.zipCode;
    }, 200);
  }

  isConstructionTypeManufactured = (): boolean => {
    const isManufactured = this.quoteSearchRequest.propertyInformation.constructionMethod === 'Manufactured';
    if (!isManufactured) {
      this.quoteSearchRequest.propertyInformation.manufacturedHomeWidthType = null;
    }
    return isManufactured;
  }

  isProjectTypeCondominium = (): boolean => {
    const isCondominium = this.quoteSearchRequest.propertyInformation.projectType === 'Condominium';
    if (!isCondominium) {
      this.quoteSearchRequest.propertyInformation.projectDesignType = null;
    }
    return isCondominium;
  }

  haveMultipleRowSelected = (): boolean => {
    return (
      Object.keys(this.selectedRows).filter(r => this.selectedRows[r]).length
    ) > 0;
  }

  editTaxTranscriptClicked = (id: number, newBorrower: MiQuoteSearchBorrowerInfo) => {
    this.expandedItems[id] = false;

    this.quoteSearchRequest.borrowerInformation.borrowerDetails[this.quoteSearchRequest.borrowerInformation.borrowerDetails.findIndex(a => a["id"] == id)] = newBorrower;
  }

  closeTaxTranscriptClicked = (id: number, isEdit: boolean) => {
    this.expandedItems[id] = false;

    if (!isEdit) {
      this.quoteSearchRequest.borrowerInformation.borrowerDetails = this.quoteSearchRequest.borrowerInformation.borrowerDetails.filter(a => a["id"] != id);
    }
  }

  addBorrowerClicked = () => {
    let newBor = new MiQuoteSearchBorrowerInfo();
    newBor["id"] = Utils.getUniqueId();

    this.expandedItems[newBor["id"]] = true;
    this.editItems[newBor["id"]] = false;

    this.quoteSearchRequest.borrowerInformation.borrowerDetails = [...this.quoteSearchRequest.borrowerInformation.borrowerDetails, newBor];
  }

  validate = (): boolean => {
    this.isLoanInfoSectionInvalid = false;
    this.isBorrowerInfoSectionInvalid = false;
    this.isPropertyInfoSectionInvalid = false;
    this.isProductSearchCriteriaSectionInvalid = false;
    let firstInvalidOneId: string = null;
    if (this.quoteSearchForm) {
      this.quoteSearchForm.form.markAllAsTouched();
      const isValid = this.quoteSearchForm.form.valid;
      if (!isValid) {
        for (var key in this.quoteSearchForm.form.controls) {
          if (this.quoteSearchForm.form.controls.hasOwnProperty(key)) {
            if (this.quoteSearchForm.form.controls[key].status === 'INVALID') {
              firstInvalidOneId = key;
              break;
            }
          }
        }
      }
      if (firstInvalidOneId) {
        this.scrollToElement(firstInvalidOneId);
      }
      return isValid;
    }
    return false;
  }

  scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const section = element.getAttribute('section');
      setTimeout(() => {
        this.isLoanInfoSectionInvalid = section === 'loan-information';
        this.isBorrowerInfoSectionInvalid = section === 'borrower-information';
        this.isPropertyInfoSectionInvalid = section === 'property-information';
        this.isProductSearchCriteriaSectionInvalid = section === 'mi-information';
        setTimeout(() => {
          const parentElement = element.parentElement;
          let el = parentElement ? parentElement : element;
          if (el.innerText === "This field cannot be zero") {
            el = el.parentElement.parentElement ? el.parentElement.parentElement : el;
          }
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
        }, 150);
      }, 250);
    }
  }

  protected async bulkDeleteClicked() {
    let selectedIds = Object.keys(this.selectedRows).filter(r => this.selectedRows[r]);

    const result = await Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this ' + selectedIds.length + ' selected record(s)?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    });

    if (!result?.value) return;

    selectedIds.forEach(id => {
      delete this.expandedItems[id];
      delete this.selectedRows[id];
      delete this.hoveredItems[id];
      this.quoteSearchRequest.borrowerInformation.borrowerDetails.splice(this.quoteSearchRequest.borrowerInformation.borrowerDetails.findIndex(a => a["id"] == Number(id)), 1);
    });

    // close all expanded rows
    this.quoteSearchRequest.borrowerInformation.borrowerDetails.forEach(a => {
      this.expandedItems[a["id"]] = false;
    });
  }

  private removeRefinancePurpose = () => {
    this.quoteSearchRequest.loanInformation.refinancePurpose = null;
  }

  private lookupZipCodeRelatedInfo = (zipCode: string) => {
    this._zipCodeService.lookupZipCode(zipCode, false).subscribe((result) => {
      if (result.length > 1) {
        const modalRef = this._modalService.open(
          CityCountyPickerDialogComponent,
          Constants.modalOptions.medium
        );
        modalRef.componentInstance.optionsToPickFrom = result;
        modalRef.result.then((selectedStateAndCounty: CityCountyState) => {
          if (selectedStateAndCounty) {
            const selectedState = this.stateOptions.find(
              (state) => state.name === selectedStateAndCounty.state
            );
            if (selectedState) {
              this.quoteSearchRequest.propertyInformation.state =
                selectedState.value;
              this.quoteSearchRequest.propertyInformation.county =
                selectedStateAndCounty.county;
              this.quoteSearchRequest.propertyInformation.countyFips =
                selectedStateAndCounty.countyFips;
            }
          }
        });
      } else if (result.length === 1) {
        const selectedState = this.stateOptions.find(
          (state) => state.name === result[0].state
        );
        if (selectedState) {
          this.quoteSearchRequest.propertyInformation.state =
            selectedState.value;
          this.quoteSearchRequest.propertyInformation.county =
            result[0].county;
          this.quoteSearchRequest.propertyInformation.countyFips = result[0].countyFips;
        }
      }
    });
  };

  private fixDefaultValues = () => {
    if (this.quoteSearchRequest.propertyInformation.county && this.quoteSearchRequest.propertyInformation.county.includes("County")) {
      this.quoteSearchRequest.propertyInformation.county = this.quoteSearchRequest.propertyInformation.county.replace("County", "").trim().toUpperCase();
    }

    if (this.quoteSearchRequest.loanInformation.interestRate) {
      this.quoteSearchRequest.loanInformation.interestRate /= 100;
    }

    if (!this._quoteSearchRequest.loanInformation.baseLoanAmount) {
      this._quoteSearchRequest.loanInformation.baseLoanAmount = 0;
    }
    if (!this._quoteSearchRequest.loanInformation.interestRate) {
      this._quoteSearchRequest.loanInformation.interestRate = 0;
    }
    if (!this._quoteSearchRequest.propertyInformation.appraisedValue) {
      this._quoteSearchRequest.propertyInformation.appraisedValue = 0;
    }

    if (
      !this._quoteSearchRequest.borrowerInformation.debtToIncomeRatio ||
      this._quoteSearchRequest.borrowerInformation.debtToIncomeRatio === 999.99
    ) {
      this._quoteSearchRequest.borrowerInformation.debtToIncomeRatio = null;
    } else if (this._quoteSearchRequest.borrowerInformation.debtToIncomeRatio >= 1) {
      this._quoteSearchRequest.borrowerInformation.debtToIncomeRatio = 1;
    }

    this._quoteSearchRequest.borrowerInformation.borrowerDetails?.forEach(borr => {
      if (!borr.creditScore) {
        borr.creditScore = null;
      }
    })

    this._quoteSearchRequest.borrowerInformation.borrowerDetails = this._quoteSearchRequest.borrowerInformation.borrowerDetails || [];

    this.onAmortizationTypeChanged();
  };
}
