import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isArray } from 'lodash';
import { NewLeadReceivedDialogComponent } from 'src/app/layout/admin/lead-route-leads/dialogs/new-lead-received-dialog/new-lead-received-dialog.component';
import {
  ApplicationContext,
  ContactListType,
  NewGroupMmsHistoryPosted,
  NewSmsHistoryPosted,
  RecentAgent,
  RecentApp,
  RecentLead
} from 'src/app/models';
import { AlertType } from 'src/app/models/alert.model';
import { Conversation } from 'src/app/modules/conversations/models/conversation.model';
import { ConversationService } from 'src/app/modules/conversations/services/conversation.service';
import { DialerEventType } from 'src/app/modules/dialer/models/dialer-event.model';
import { DialerService } from 'src/app/modules/dialer/services/dialer.service';
import { EventEditorDialogComponent } from 'src/app/modules/events/components/event-editor-dialog/event-editor-dialog.component';
import { LeadRouteEventType } from 'src/app/modules/leads/models/lead-event.model';
import { NewUnassignedLeadPostedModel } from 'src/app/modules/leads/models/unassigned-lead-posted.model';
import { EditTaskDialogComponent } from 'src/app/modules/tasks/components/edit-task-dialog/edit-task-dialog.component';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import Swal from 'sweetalert2';
import { AdminEventType, AdminService } from '../admin.service';
import { AlertsCreatedEvent, AlertsService } from '../alerts.service';
import { ApplicationContextService } from '../application-context.service';
import { LeadRouteService } from '../lead-route.service';
import { LogService } from '../log.service';
import { MentionsService } from '../mentions.service';
import { NotificationService } from '../notification.service';
import { TaskService } from '../task.service';
import { FirebaseServicesService } from './firebase-services.service';

@Injectable()
export class FirebaseMessageService {
  context: ApplicationContext;

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _mentionsService: MentionsService,
    private readonly _conversationService: ConversationService,
    private readonly _firebaseServicesService: FirebaseServicesService,
    private readonly _logService: LogService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _notificationService: NotificationService,
    private readonly _adminService: AdminService,
    private readonly _dialerService: DialerService,
    private readonly _taskService: TaskService,
    private readonly _leadRouteService: LeadRouteService,
    private readonly _alertsService: AlertsService,
    private readonly _drawerService: DrawerService,
    private readonly _router: Router
  ) { }

  get adminNotifications(): {
    [key: number]: (payload: any) => void;
  } {
    return {
      7: this._appointmentComingUp,
      9: this._newUnassignedLeadPosted,
      10: this._newUnassignedLeadTaken,
      14: this._showReleaseNotes,
      16: this._newInternalMessagePosted,
      17: this._newBorrowerMessagePosted,
      18: this._newDocumentReadyForReview,
      19: this._newSmsHistoryPosted,
      34: this._openConversationDeleted,
      35: this._externalCallTransferred,
      39: this._newConversationMessageReceived,
      40: this._newGroupMmsHistoryPosted,
    };
  }

  get ocrNotifications(): {
    [key: number]: (payload: any) => void;
  } {
    return {
      32: this._docFileSplittingComplete,
    };
  }

  get sharedNotifications(): {
    [key: number]: (payload: any) => void;
  } {
    return {
      3: this._sendToUser,
      4: this._initializeAlertData,
      5: this._addAlertData,
      6: this._notifyUser,
      11: this._updateRecentAppList,
      12: this._creditReportResponseReceived,
      31: this._loanDocTaskChangedStatus,
      33: this._removeAlertData,
      36: this._updateRecentLeadList,
      37: this._updateRecentAgentList,
    };
  }

  get dialerNotifications(): {
    [key: number]: (payload: any) => void;
  } {
    return {
      8: this._newAssignedLeadPosted,
      20: this._conferenceEnded,
      21: this._callStatusChanged,
      22: this._freeForAllLeadAvailable,
      23: this._warmTransferLeadAvailable,
      24: this._warmTransferConnected,
      25: this._warmTransferCompleted,
      26: this._cancelWarmTransferLeadAvailable,
      27: this._freeForAllLeadTaken,
      28: this._leadsOnToggle,
      29: this._callsOnToggle,
      30: this._dialListRecordsRemoved,
      40: this._applicationUpdated,
      41: this._warmTransferReceived,
      42: this._warmTransferCanceled,
      44: this._screenPopRecord,
    };
  }

  // ---------------------- ADMIN ---------------------- //

  private _showReleaseNotes = (payload) => {
    this._applicationContextService.updateApplicationVersion(payload);
    // if (!this._modalService) return;
    // const modal = this._modalService.open(ReleaseNoteDialogComponent);
    // modal.componentInstance.releaseNotes = payload;
    // modal.result.then(
    //   (version) => {
    //     this._firebaseServicesService.viewReleaseNotes(version).subscribe({
    //       next: () => { },
    //       error: () => { },
    //     });
    //   },
    //   (err) => { }
    // );
  };

  private _newUnassignedLeadPosted = (
    payload: NewUnassignedLeadPostedModel
  ) => {
    const className = `notification-toaster-${Math.random()
      .toString(26)
      .slice(2)}`;
    const message = `<i><a class="${className}" href='javascript: void(0);'>Click Here</a></i> to see the lead`;

    this._notificationService.showInfo(message, 'New Lead Available!', {
      timer: 60000,
      timerProgressBar: true,
      showCloseButton: true,
    });

    setTimeout(() => {
      const clickBtn = document.querySelector(`.${className}`) as HTMLElement;
      if (!clickBtn) return;

      clickBtn.onclick = () => {
        this._drawerService.openDrawer({
          contactListType: ContactListType.Lead,
          recordId: payload.leadId
        })
      };
    }, 100);
  };

  private _newBorrowerMessagePosted = (payload) => {
    if (payload.senderId !== this.context.userPermissions.userId) {
      this._conversationService.addBorrowerChat(
        payload.applicationId,
        payload.borrowerId,
        payload,
        false,
        true
      );
    }
  };

  private _newInternalMessagePosted = (payload) => {
    if (payload.senderId !== this.context.userPermissions.userId) {
      this._conversationService.addInternalChat(
        payload.applicationId,
        payload,
        false,
        true
      );
    }
  };

  private _newSmsHistoryPosted = (payload: NewSmsHistoryPosted) => {
    if (payload.direction == 'Inbound') {
      this._conversationService.openSmsChat({
        userName: payload.senderName,
        userPhone: payload.from,
        smsMessage: payload,
        external: true
      });
    }
  };

  private _newGroupMmsHistoryPosted = (payload: NewGroupMmsHistoryPosted) => {
    if (payload.direction == 'Inbound') {
      this._conversationService.openSmsChat({
        userName: payload.senderName,
        userPhone: payload.senderNumber,
        conversationId: payload.conversationId,
        smsMessage: payload,
        external: true
      });
    }
  };

  private _openConversationDeleted = ({ data, traceId }: { data: string, traceId: string }) => {
    console.log("This is no longer supported. '_openConversationDeleted'")
  };

  private _externalCallTransferred = ({
    contactListType,
    recordId,
    traceId
  }: {
    contactListType: ContactListType,
    recordId: number,
    traceId: string
  }) => {
    const self = this;
    this._notificationService.showInfo('New transfer available, click to review.', 'External Call Transfer!', {
      timer: 5000,
      timerProgressBar: true,
      showCloseButton: false,
      didOpen: (toast) => {
        toast.addEventListener('click', listener);
        function listener() {
          self.processRecord(contactListType, recordId);
          toast.removeEventListener('click', listener);
          Swal.close();
        }
      }
    });
  }

  private _newConversationMessageReceived = (conversation: Conversation) => {
    console.log("_newConversationMessageReceived", conversation);
    this._conversationService.conversationUpdated.emit(conversation);
  }

  private processRecord(contactListType: ContactListType, recordId: number) {
    switch (contactListType) {
      case ContactListType.Application:
        this._router.navigateByUrl(`admin/app-details/${recordId}`);
        break;
      case ContactListType.Lead:
        this._drawerService.openDrawer({
          contactListType,
          recordId
        });
        break;
      case ContactListType.Agent:
      case ContactListType.Borrower:
      case ContactListType.CreditMonitoring:
      case ContactListType.ExternalBrokerage:
      case ContactListType.ExternalBroker:
        alert(contactListType + ' not supported yet');
        break;
    }
  }

  private _newDocumentReadyForReview = (payload) => {
    const { appId } = payload;
    const message = `<i><a href='admin/app-details/${appId}'>Click Here</a></i> to review now`;
    this._notificationService.showInfo(
      message,
      'New Document Ready for Review!',
      { timer: 15000, timerProgressBar: true, showCloseButton: true }
    );
  };

  private _newUnassignedLeadTaken = (payload) => { };

  private _appointmentComingUp = (payload) => {
    const modal = this._modalService.open(EventEditorDialogComponent);
    modal.componentInstance.event = payload;
  };

  // ---------------------- SHARED ---------------------- //

  private _sendToUser = (payload) => {
    switch (payload.msgType) {
      case 'NewLead':
        const modal = this._modalService.open(NewLeadReceivedDialogComponent);
        modal.componentInstance.notifyOnly = false;
        modal.componentInstance.leadModel = payload;
        modal.componentInstance.isFreeForAllAccept = false;
        break;
    }
  };

  private _initializeAlertData = (payload) => {
    // alertFactory.SetAlertData(payload);
  };

  private _addAlertData = (payload) => {
    const alertsCreatedEvent = new AlertsCreatedEvent();
    alertsCreatedEvent.alerts = isArray(payload) ? payload : (payload?.data || []);
    this._alertsService.publish(alertsCreatedEvent);

    // update mentions notification counter
    if (alertsCreatedEvent.alerts[0]?.alertType === AlertType.Mention) {
      this._mentionsService.publish({ type: "reload" });
    }
  };

  private _notifyUser = ({ body, head }) => {
    const message = `<i>${body}</i>`;
    this._notificationService.showInfo(message, head, {
      timer: 10000,
      timerProgressBar: true,
      showCloseButton: true,
    });
  };

  private _updateRecentAppList = ({ traceId, data }: { traceId: string, data: RecentApp[] }) => {
    console.log("recents update")
    this._applicationContextService.updateRecentAppsList(data);
  };

  private _updateRecentLeadList = ({ traceId, data }: { traceId: string, data: RecentLead[] }) => {
    this._applicationContextService.updateRecentLeadsList(data);
  };

  private _updateRecentAgentList = ({ traceId, data }: { traceId: string, data: RecentAgent[] }) => {
    this._applicationContextService.updateRecentAgentsList(data);
  };

  private _creditReportResponseReceived = ({ data, traceId }: { data: string, traceId: string }) => {
    this._firebaseServicesService.getReportContent(data)
      .subscribe((result) => {
        const newWindow = window.open('', '_blank');
        newWindow.document.write(result);
      });
  };

  private _loanDocTaskChangedStatus = (payload) => {
    const className = `notification-toaster-${Math.random()
      .toString(26)
      .slice(2)}`;
    const message = `<i class="${className}">Click Here</i> to view now`;
    this._notificationService.showInfo(message, 'Task Status Changed!', {
      timer: 15000,
      timerProgressBar: true,
      showCloseButton: true,
    });

    setTimeout(() => {
      const clickBtn = document.querySelector(`.${className}}`) as HTMLElement;
      if (!clickBtn) return;

      clickBtn.onclick = () => {
        this._taskService
          .getTaskDashboardViewById(payload.loanDocTaskId)
          .subscribe((task) => {
            const modal = this._modalService.open(EditTaskDialogComponent);
            modal.componentInstance.task = task;
          });
      };
    }, 100);
  };

  private _removeAlertData() {

  }

  // ---------------------- ORC ---------------------- //

  private _docFileSplittingComplete = (payload) => {
    const { applicationId, fileName, applicationName } = payload;

    const url = `app-details/${applicationId}`;
    const index = window.location.href.indexOf(url);

    let message = `File ${fileName} in ${applicationName} loan has been processed.`;
    if (index > -1) {
      message += `Please refresh the page. &nbsp <b><i class="la la-refresh" onclick="window.location.reload()"></i></b>`;
    }

    this._notificationService.showInfo(message, 'Info', {
      timer: 10000,
      timerProgressBar: true,
      showCloseButton: true,
    });
  };

  // ---------------------- DIALER ---------------------- //

  private _conferenceEnded = (payload) => {
    this._logService
      .logSignalRAck(DialerEventType.ConferenceEnded, payload?.traceId)
      .subscribe(() => { });

    this._dialerService.publish({
      eventType: DialerEventType.ConferenceEnded,
      data: {
        conferenceId: payload?.data || payload || null,
      }
    });
  };

  private _callStatusChanged = (payload) => {
    this._logService
      .logSignalRAck(DialerEventType.CallStatusChanged, payload.traceId)
      .subscribe(() => { });

    this._dialerService.publish({
      eventType: DialerEventType.CallStatusChanged,
      data: payload,
    });
  };

  private _freeForAllLeadAvailable = (payload) => {
    this._logService
      .logSignalRAck(
        LeadRouteEventType.FreeForAllLeadAvailable,
        payload.traceId
      )
      .subscribe(() => { });
    this._leadRouteService.publish({
      eventType: LeadRouteEventType.FreeForAllLeadAvailable,
      data: payload,
    });
  };

  private _warmTransferReceived = (payload) => {
    this._logService
      .logSignalRAck(
        LeadRouteEventType.WarmTransferReceived,
        payload.traceId
      )
      .subscribe(() => { });
    this._leadRouteService.publish({
      eventType: LeadRouteEventType.WarmTransferReceived,
      data: payload,
    });
  };

  private _warmTransferLeadAvailable = (payload) => {
    this._logService
      .logSignalRAck(
        LeadRouteEventType.WarmTransferLeadAvailable,
        payload.traceId
      )
      .subscribe(() => { });
    this._leadRouteService.publish({
      eventType: LeadRouteEventType.WarmTransferLeadAvailable,
      data: payload,
    });
  };

  private _cancelWarmTransferLeadAvailable = (payload) => {
    this._logService
      .logSignalRAck(
        LeadRouteEventType.CancelWarmTransferLeadAvailable,
        payload.traceId
      )
      .subscribe(() => { });
    this._leadRouteService.publish({
      eventType: LeadRouteEventType.CancelWarmTransferLeadAvailable,
      data: payload,
    });
  };

  private _freeForAllLeadTaken = (payload) => {
    this._logService
      .logSignalRAck(LeadRouteEventType.FreeForAllLeadTaken, payload.traceId)
      .subscribe(() => { });
    this._leadRouteService.publish({
      eventType: LeadRouteEventType.FreeForAllLeadTaken,
      data: payload,
    });
  };

  private _newAssignedLeadPosted = (payload) => {
    this._leadRouteService.publish({
      eventType: LeadRouteEventType.NewAssignedLeadPosted,
      data: payload,
    });
  };

  private _warmTransferConnected = (payload) => {
    this._logService
      .logSignalRAck(DialerEventType.WarmTransferConnected, payload.traceId)
      .subscribe(() => { });

    this._dialerService.publish({
      eventType: DialerEventType.WarmTransferConnected,
      data: payload,
    });
  };

  private _warmTransferCanceled = (payload) => {
    this._logService
      .logSignalRAck(
        DialerEventType.WarmTransferCanceled,
        payload.traceId
      )
      .subscribe(() => { });
    this._dialerService.publish({
      eventType: DialerEventType.WarmTransferCanceled,
      data: payload,
    });
  };

  private _screenPopRecord = (payload) => {
    this._logService
      .logSignalRAck(
        DialerEventType.ScreenPopRecord,
        payload.traceId
      )
      .subscribe(() => { });
    this._dialerService.publish({
      eventType: DialerEventType.ScreenPopRecord,
      data: payload,
    });
  };

  private _warmTransferCompleted = (payload) => {
    this._logService
      .logSignalRAck('warmTransferCompleted', payload.traceId)
      .subscribe(() => { });
    //this._voiceService.loadConferenceParticipants(data);
    this._dialerService.publish({
      eventType: DialerEventType.WarmTransferCompleted,
      data: payload,
    });
  };

  private _leadsOnToggle = (payload) => {
    this._adminService.publish({
      eventType: AdminEventType.LeadsOnToggle,
      data: payload,
    });
  };

  private _callsOnToggle = (payload) => {
    this._adminService.publish({
      eventType: AdminEventType.CallsOnToggle,
      data: payload,
    });
  };

  private _dialListRecordsRemoved = (payload) => {
    this._dialerService.publish({
      eventType: DialerEventType.DialListRecordRemoved,
      data: payload,
    });
  };

  private _applicationUpdated = (payload) => {
    this._applicationContextService.updateLoanInfo(payload.applicationId).subscribe();
  };
}
